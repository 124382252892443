import { makeStyles } from "@mui/styles";
import { color } from "../../../Config/theme";

const useStyles = makeStyles((theme) => ({
  cardmain1: {
    width: "100%",
    padding: 10,
    borderRadius: 4,
    marginBottom: 20,
    boxSizing: "border-box",
    boxShadow: "0px 3px 15px #00000029",
  },
  btnmain: {
    height: "30px !important",
    width: "30px !important",
    minWidth: "0px !important",
    marginBottom: "10px !important",
    "@media (max-width:768px)": {
      marginRight: "10px !important",
    },
  },
  radiomain: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 6,
  },
}));

export default useStyles;
