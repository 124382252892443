import React, { useEffect, useState } from "react";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Select,
  MenuItem,
  FormControl,
  Modal,
  Button,
  TextField,
  InputAdornment,
  CircularProgress,
  FormHelperText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Images from "../../../../Config/Images";
import useStyles from "../../styles";
import { isEmpty } from "lodash";

const errorObj = {
  jobTypeErr: false,
  yearlyErr: false,
  hourlyErr: false,
  jobTypeMsg: "",
  yearlyMsg: "",
  hourlyMsg: "",
};

function MJobType(props) {
  const {
    visible = false,
    handleModal = () => null,
    data = {},
    loader = false,
    onSave = () => null,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [jobType, setJobType] = useState("p_full_time");
  const [yearlySalary, setYearlySalary] = useState("");
  const [hourlySalary, setHourlySalary] = useState("");
  const [errObj, setErrObj] = useState(errorObj);
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 290 : 330,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    setErrObj(errorObj);
  }, [jobType, visible]);

  useEffect(() => {
    if (visible) {
      setJobType(data?.job_role_type || "");
      setYearlySalary(data?.charge_per_year || "");
      setHourlySalary(data?.charge_per_hour || "");
    }
    if (!visible) {
      resetFormData();
    }
  }, [visible]);

  // this function for reset form data
  function resetFormData(type = "") {
    setJobType("p_full_time");
    setYearlySalary("");
    setHourlySalary("");
    setErrObj(errorObj);
    if (type === "close" && handleModal) {
      handleModal();
    }
  }

  function checkValidation() {
    const error = { ...errObj };
    let valid = true;

    if (
      (jobType === "p_full_time" || jobType === "t_full_time") &&
      isEmpty(yearlySalary.toString())
    ) {
      valid = false;
      error.yearlyErr = true;
      error.yearlyMsg = "Please enter yearly salary";
    } else if (
      (jobType === "p_part_time" || jobType === "t_part_time") &&
      isEmpty(hourlySalary.toString())
    ) {
      valid = false;
      error.hourlyErr = true;
      error.hourlyMsg = "Please enter hourly salary";
    } else if (jobType === "contract" || jobType === "any") {
      if (isEmpty(yearlySalary.toString())) {
        valid = false;
        error.yearlyErr = true;
        error.yearlyMsg = "Please enter yearly salary";
      } else if (isEmpty(hourlySalary.toString())) {
        valid = false;
        error.hourlyErr = true;
        error.hourlyMsg = "Please enter hourly salary";
      }
    }

    setErrObj(error);

    if (valid) {
      const jobData = {
        jobType: jobType,
        yearlySalary: yearlySalary,
        hourlySalary: hourlySalary,
      };
      onSave(jobData);
    }
  }

  return (
    <Modal
      open={visible}
      onClose={() => resetFormData("close")}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <div className={classes.splitViewStyle}>
            <Typography variant="subtitle1" component="h2">
              Add Job Type
            </Typography>
            <img
              src={Images.close}
              className={classes.closeBtnStyle}
              onClick={() => resetFormData("close")}
            />
          </div>
          <div className={classes.formDivStyle}>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.inputFieldStyle}
            >
              <Select
                IconComponent={() => (
                  <img src={Images.angle} className={classes.angleBtnStyle} />
                )}
                error={errObj.jobTypeErr}
                onChange={(e) => {
                  setJobType(e.target.value);
                  setErrObj({ ...errObj, jobTypeErr: false, jobTypeMsg: "" });
                }}
                value={jobType}
              >
                <MenuItem value="p_full_time">Permanent - Full Time</MenuItem>
                <MenuItem value="p_part_time">Permanent - Part Time</MenuItem>
                <MenuItem value="t_full_time">Temporary - Full Time</MenuItem>
                <MenuItem value="t_part_time">Temporary - Part Time</MenuItem>
                <MenuItem value="contract">Contract</MenuItem>
                <MenuItem value="any">Flexible</MenuItem>
              </Select>
              {errObj.jobTypeErr ? (
                <FormHelperText style={{ color: "red" }}>
                  {errObj.jobTypeMsg}
                </FormHelperText>
              ) : null}
            </FormControl>
            {(jobType === "contract" ||
              jobType === "any" ||
              jobType === "p_full_time" ||
              jobType === "t_full_time") && (
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Salary"
                className={classes.inputFieldStyle}
                value={yearlySalary}
                error={errObj.yearlyErr}
                type="number"
                helperText={errObj.yearlyMsg}
                onChange={(e) => {
                  setYearlySalary(e.target.value);
                  setErrObj({ ...errObj, yearlyErr: false, yearlyMsg: "" });
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">£</InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <div className={classes.cViewStyle}>per year</div>
                    </InputAdornment>
                  ),
                }}
              />
            )}
            {(jobType === "contract" ||
              jobType === "any" ||
              jobType === "p_part_time" ||
              jobType === "t_part_time") && (
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Salary"
                className={classes.inputFieldStyle}
                value={hourlySalary}
                type="number"
                error={errObj.hourlyErr}
                helperText={errObj.hourlyMsg}
                onChange={(e) => {
                  setHourlySalary(e.target.value);
                  setErrObj({ ...errObj, hourlyErr: false, hourlyMsg: "" });
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">£</InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <div className={classes.cViewStyle}>per hour</div>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </div>
          <div className={classes.splitViewStyle}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.modalBtnStyle}
              onClick={resetFormData}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.modalBtnStyle}
              onClick={() => {
                checkValidation();
              }}
              disabled={loader}
            >
              {loader ? (
                <CircularProgress style={{ color: "#fff" }} size={26} />
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
export default MJobType;
