import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { isArray, isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Setting } from "../../../Utils/Setting";
import JobDetail from "../../../Components/Jobs/JobDetail";
import OfferDetail from "../../../Components/Jobs/OfferDetail";
import { useLocation } from "react-router-dom";
import Message from "../../../Components/Jobs/Message";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper";
import useStyles from "./styles";

export default function Jobs() {
  const location = useLocation();
  const classes = useStyles();
  const { token, candidateProfile } = useSelector((state) => state.auth);
  const [currentTab, setCurrentTab] = useState(
    location?.state?.from === "skip" ? "6" : "1"
  );
  const [matchingJobs, setMatchingJobs] = useState({
    data: [],
    pageData: {},
  });
  const [newJobs, setNewJobs] = useState({
    data: [],
    pageData: {},
  });
  const [interestedJobs, setInterestedJobs] = useState({
    data: [],
    pageData: {},
  });
  const [offers, setOffers] = useState({
    data: [],
    pageData: {},
  });
  const [messages, setMessages] = useState({
    data: [],
    pageData: {},
  });
  const [shortLists, setShortLists] = useState({
    data: [],
    pageData: {},
  });
  const [interestBtnLoad, setInterestBtnLoad] = useState({});
  const [pageLoad, setPageLoad] = useState(true);

  useEffect(() => {
    document.title = Setting.page_name.CANDIDATE_JOBS;
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getMatchingJobList();
    getInterestedJobList();
    getOffers();
    getMessageList();
    getShortList();
  }, []);

  useEffect(() => {
    getNewJobList();
  }, [candidateProfile]);

  // this function for get matching job list
  async function getMatchingJobList() {
    setPageLoad(true);
    try {
      const response = await getApiData(
        Setting.endpoints.employeeMatchingJobs,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (!isEmpty(response.data) && isArray(response?.data?.rows)) {
          setMatchingJobs({
            data: response?.data?.rows || [],
            pageData: response?.data?.pagination,
          });
        }
      }
      setPageLoad(false);
    } catch (error) {
      console.log("error ===>>", error);
      setPageLoad(false);
    }
  }

  // this function for get shortlisted job list
  async function getShortList() {
    try {
      const response = await getApiData(
        Setting.endpoints.employeeShortListJobs,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (!isEmpty(response.data) && isArray(response?.data?.rows)) {
          setShortLists({
            data: response?.data?.rows || [],
            pageData: response?.data?.pagination,
          });
        }
      }
    } catch (error) {
      console.log("error ===>>", error);
    }
  }

  // this function for get matching job list
  async function getInterestedJobList(bool = false) {
    try {
      const response = await getApiData(
        Setting.endpoints.employeeInterestedJobList,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (!isEmpty(response.data) && isArray(response?.data?.rows)) {
          setInterestedJobs({
            data: response?.data?.rows || [],
            pageData: response?.data?.pagination,
          });
        }
      }

      if (bool) {
        getMatchingJobList();
      }
    } catch (error) {
      console.log("error ===>>", error);
    }
  }

  // this function for get offers list
  async function getOffers() {
    try {
      const response = await getApiData(
        Setting.endpoints.employeeMyOffer,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (!isEmpty(response.data) && isArray(response?.data?.rows)) {
          setOffers({
            data: response?.data?.rows || [],
            pageData: response?.data?.pagination,
          });
        }
      }
    } catch (error) {
      console.log("error ===>>", error);
    }
  }

  // this function for get message list
  async function getMessageList() {
    try {
      const response = await getApiData(
        Setting.endpoints.employeeMessageList,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (!isEmpty(response.data) && isArray(response?.data.rows)) {
          setMessages({
            data: response?.data?.rows || [],
            pageData: response?.data?.pagination,
          });
        }
      }
    } catch (error) {
      console.log("error ===>>", error);
    }
  }

  // this function for get New job list
  async function getNewJobList() {
    const sector_id = !isEmpty(candidateProfile) && candidateProfile?.sector_id;
    try {
      const response = await getApiData(
        `${Setting.endpoints.employeeAllJob}?sector_id=${sector_id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (!isEmpty(response.data) && isArray(response?.data.rows)) {
          setNewJobs({
            data: response?.data?.rows || [],
            pageData: response?.data?.pagination,
          });
        }
      }
    } catch (error) {
      console.log("error ===>>", error);
    }
  }

  // this function for update status
  async function updateStatus(data, key) {
    setInterestBtnLoad(data);
    const obj = {
      "CandidateJobInterest[job_post_id]": data.id,
      "CandidateJobInterest[interest]": key === "1" || key === "6" ? 1 : 0,
    };

    try {
      const response = await getAPIProgressData(
        Setting.endpoints.employeeCandidateJobInterest,
        "POST",
        obj,
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (key === "1") {
          setCurrentTab("2");
        }
        getInterestedJobList(true);
        getNewJobList();
      } else {
        toast.error(response.message);
      }
      setInterestBtnLoad({});
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
      setInterestBtnLoad({});
    }
  }

  const loadMoreData =
    currentTab === "1"
      ? matchingJobs?.pageData?.isMore
      : currentTab === "2"
      ? interestedJobs?.pageData?.isMore
      : currentTab === "3"
      ? offers?.pageData?.isMore
      : currentTab === "4"
      ? messages?.pageData?.isMore
      : false;

  return (
    <Grid container className={classes.container}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
        <Tabs
          value={currentTab}
          onChange={(e, val) => setCurrentTab(val)}
          indicatorColor="secondary"
          className={classes.tabStyle}
        >
          <Tab
            label={`Matching Jobs (${matchingJobs?.data?.length || 0})`}
            value="1"
          />
          <Tab
            label={`view all Jobs (${newJobs?.data?.length || 0})`}
            value="6"
          />
          <Tab
            label={`Interested (${interestedJobs?.data?.length || 0})`}
            value="2"
          />
          <Tab
            label={`Shortlist (${shortLists?.data?.length || 0})`}
            value="5"
          />
          <Tab label={`Offers (${offers?.data?.length || 0})`} value="3" />
          <Tab label="Messages" value="4" />
        </Tabs>
      </Box>
      <div className={classes.listContainerStyle}>
        {currentTab === "1" &&
        isArray(matchingJobs.data) &&
        matchingJobs.data.length > 0 ? (
          matchingJobs.data.map((item) => {
            return (
              <JobDetail
                data={item}
                updateStatus={updateStatus}
                interestBtnLoad={interestBtnLoad}
              />
            );
          })
        ) : pageLoad && currentTab === "1" ? (
          <div className={classes.loaderView}>
            <CircularProgress size={50} />
          </div>
        ) : (
          currentTab === "1" && (
            <div className={classes.noDataView}>
              <Typography className={classes.sectionTitle}>
                No Jobs available
              </Typography>
            </div>
          )
        )}

        {/* New job tab design */}
        {currentTab === "6" &&
        isArray(newJobs?.data) &&
        newJobs?.data.length > 0 ? (
          newJobs?.data.map((item) => {
            return (
              <JobDetail
                data={item}
                activeTab={currentTab}
                updateStatus={updateStatus}
                interestBtnLoad={interestBtnLoad}
              />
            );
          })
        ) : pageLoad && currentTab === "6" ? (
          <div className={classes.loaderView}>
            <CircularProgress size={50} />
          </div>
        ) : (
          currentTab === "6" && (
            <div className={classes.noDataView}>
              <Typography className={classes.sectionTitle}>
                No Jobs available
              </Typography>
            </div>
          )
        )}

        {currentTab === "2" &&
        isArray(interestedJobs?.data) &&
        interestedJobs?.data.length > 0 ? (
          interestedJobs?.data.map((item) => {
            return (
              <JobDetail
                data={item}
                activeTab={currentTab}
                updateStatus={updateStatus}
                interestBtnLoad={interestBtnLoad}
              />
            );
          })
        ) : pageLoad && currentTab === "2" ? (
          <div className={classes.loaderView}>
            <CircularProgress size={50} />
          </div>
        ) : (
          currentTab === "2" && (
            <div className={classes.noDataView}>
              <Typography className={classes.sectionTitle}>
                No Jobs available
              </Typography>
            </div>
          )
        )}
        {currentTab === "5" &&
        isArray(shortLists.data) &&
        shortLists?.data?.length > 0 ? (
          shortLists.data.map((item) => {
            return <OfferDetail data={item} type="shortlist" />;
          })
        ) : pageLoad && currentTab === "5" ? (
          <div className={classes.loaderView}>
            <CircularProgress size={50} />
          </div>
        ) : (
          currentTab === "5" && (
            <div className={classes.noDataView}>
              <Typography className={classes.sectionTitle}>
                No List available
              </Typography>
            </div>
          )
        )}
        {currentTab === "3" &&
        isArray(offers.data) &&
        offers?.data?.length > 0 ? (
          offers.data.map((item) => {
            return <OfferDetail data={item} />;
          })
        ) : pageLoad && currentTab === "3" ? (
          <div className={classes.loaderView}>
            <CircularProgress size={50} />
          </div>
        ) : (
          currentTab === "3" && (
            <div className={classes.noDataView}>
              <Typography className={classes.sectionTitle}>
                No Offers available
              </Typography>
            </div>
          )
        )}
        {currentTab === "4" &&
        isArray(messages.data) &&
        messages?.data?.length > 0 ? (
          messages?.data.map((item) => {
            return <Message data={item} />;
          })
        ) : pageLoad && currentTab === "4" ? (
          <div className={classes.loaderView}>
            <CircularProgress size={50} />
          </div>
        ) : (
          currentTab === "4" && (
            <div className={classes.noDataView}>
              <Typography className={classes.sectionTitle}>
                No Messages
              </Typography>
            </div>
          )
        )}
      </div>
      {loadMoreData && (
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={3}>
            <Button variant="contained" color="primary" fullWidth>
              Load More
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
