import useMediaQuery from "@mui/material/useMediaQuery";
import { NavLink, useLocation } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isArray, isEmpty } from "lodash";
import useStyles from "./styles";
import { CMS_List } from "../../Config/Static_Data";

function Footer(props) {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const { dropdowndata } = useSelector((state) => state.auth);
  const isAddSpacing = useMediaQuery(theme.breakpoints.down(1270));
  const isSmallMobileDevice = useMediaQuery(theme.breakpoints.down(500));

  const [is_Sign_Up_Screen, set_is_Sign_Up_Screen] = useState(false);
  const [addArr, setAddArr] = useState([]);

  const dynamicYear = new Date().getFullYear();

  useEffect(() => {
    const current_url = location?.pathname;
    const isBool =
      current_url.includes("signup") ||
      current_url.includes("quiz") ||
      current_url.includes("candidate-result") ||
      current_url.includes("verification") ||
      current_url.includes("reset-password") ||
      current_url.includes("about-us") ||
      current_url.includes("contact-us") ||
      current_url.includes("terms-and-condition") ||
      current_url.includes("privacy-policy");
    set_is_Sign_Up_Screen(isBool);
  }, [location]);

  useEffect(() => {
    if (!isEmpty(dropdowndata?.company_address)) {
      setAddArr(dropdowndata?.company_address.split(", "));
    }
  }, [dropdowndata]);

  if (is_Sign_Up_Screen) {
    return null;
  }

  if (isSmallMobileDevice) {
    return (
      <div
        className={classes.footer_main_con}
        style={{
          padding: "35px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid container style={{ width: "auto", textAlign: "center" }}>
          <Grid item>
            <Typography className={classes.footer_header_title}>
              Company
            </Typography>
            {CMS_List.map((obj, index) => {
              return (
                <NavLink
                  key={index}
                  to={obj?.link}
                  className={classes.anchor_titles}
                >
                  <Typography className={classes.footer_sub_title}>
                    {obj?.title}
                  </Typography>
                </NavLink>
              );
            })}
          </Grid>
        </Grid>

        {isEmpty(dropdowndata?.company_address) ? null : (
          <Grid
            container
            style={{ width: "auto", textAlign: "center", marginTop: "25px" }}
          >
            <Grid item>
              <Typography className={classes.footer_header_title}>
                Address
              </Typography>
              {isArray(addArr) && !isEmpty(addArr)
                ? addArr.map((item) => {
                    return (
                      <Typography className={classes.footer_sub_title}>
                        {item}
                      </Typography>
                    );
                  })
                : null}
            </Grid>
          </Grid>
        )}

        <Grid
          container
          style={{
            width: "auto",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "25px",
          }}
        >
          <Typography className={classes.footer_header_title}>
            Stay Tuned
          </Typography>
          <Grid
            container
            spacing={1}
            className={classes.MT10}
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <Grid item className={[classes.icons_con, classes.iconDivStyle]}>
              <a
                href={dropdowndata?.facebook_url}
                rel="noreferrer"
                target={"_blank"}
              >
                <i class="bi bi-facebook"></i>
              </a>
            </Grid>
            <Grid
              item
              className={[
                classes.icons_con,
                classes.ML10,
                classes.iconDivStyle,
              ]}
            >
              <a
                href={dropdowndata?.instagram_url}
                rel="noreferrer"
                target={"_blank"}
              >
                <i class="bi bi-instagram"></i>
              </a>
            </Grid>
            <Grid
              item
              className={[
                classes.icons_con,
                classes.ML10,
                classes.iconDivStyle,
              ]}
            >
              <a
                href="https://www.linkedin.com/"
                rel="noreferrer"
                target={"_blank"}
              >
                <i class="bi bi-linkedin"></i>
              </a>
            </Grid>
            <Grid
              item
              className={[
                classes.icons_con,
                classes.ML10,
                classes.iconDivStyle,
              ]}
            >
              <a
                href="https://twitter.com/i/flow/login"
                rel="noreferrer"
                target={"_blank"}
              >
                <i class="bi bi-twitter"></i>
              </a>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          style={{
            width: "auto",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "25px",
          }}
        >
          <Typography
            className={[classes.footer_header_title, classes.footer_copy_right]}
            style={{ textAlign: "center" }}
          >
            {`Copyright ⓒ ${dynamicYear} Direct Expertise.`}
          </Typography>
        </Grid>

        <Grid
          container
          style={{
            width: "auto",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            className={[classes.footer_header_title, classes.footer_copy_right]}
          >
            All Rights Reserved
          </Typography>
        </Grid>
      </div>
    );
  }

  return (
    <div
      className={classes.footer_main_con}
      style={isAddSpacing ? { padding: "25px" } : null}
    >
      <Grid container>
        <Grid item lg={5} md={5} sm={4} xs={4}>
          <Typography className={classes.footer_header_title}>
            Company
          </Typography>
          {CMS_List.map((obj, index) => {
            return (
              <NavLink
                key={index}
                to={obj?.link}
                className={classes.anchor_titles}
              >
                <Typography className={classes.footer_sub_title}>
                  {obj?.title}
                </Typography>
              </NavLink>
            );
          })}
        </Grid>
        {isEmpty(dropdowndata?.company_address) ? null : (
          <Grid item lg={5} md={5} sm={5} xs={5}>
            <Typography className={classes.footer_header_title}>
              Address
            </Typography>
            {isArray(addArr) && !isEmpty(addArr)
              ? addArr.map((item) => {
                  return (
                    <Typography className={classes.footer_sub_title}>
                      {item}
                    </Typography>
                  );
                })
              : null}
          </Grid>
        )}
        {(dropdowndata?.facebook_url ||
          dropdowndata?.instagram_url ||
          dropdowndata?.linkedin_url ||
          dropdowndata?.twitter_url) && (
          <Grid item lg={2} md={2} sm={3} xs={3}>
            <Grid container justifyContent="flex-end">
              <Typography className={classes.footer_header_title}>
                Stay Tuned
              </Typography>
              <Grid
                container
                spacing={1}
                className={classes.MT10}
                justifyContent="flex-end"
              >
                {dropdowndata?.facebook_url && (
                  <Grid item className={[classes.icons_con, classes.ML10]}>
                    <a
                      href={dropdowndata?.facebook_url}
                      rel="noreferrer"
                      target={"_blank"}
                    >
                      <i class="bi bi-facebook"></i>
                    </a>
                  </Grid>
                )}
                {dropdowndata?.instagram_url && (
                  <Grid item className={[classes.icons_con, classes.ML10]}>
                    <a
                      href={dropdowndata?.instagram_url}
                      rel="noreferrer"
                      target={"_blank"}
                    >
                      <i class="bi bi-instagram"></i>
                    </a>
                  </Grid>
                )}
              </Grid>

              <Grid container spacing={1} justifyContent="flex-end">
                {dropdowndata?.linkedin_url && (
                  <Grid
                    item
                    className={[classes.icons_con, classes.ML10, classes.MT10]}
                  >
                    <a
                      href="https://www.linkedin.com/"
                      rel="noreferrer"
                      target={"_blank"}
                    >
                      <i class="bi bi-linkedin"></i>
                    </a>
                  </Grid>
                )}
                {dropdowndata?.twitter_url && (
                  <Grid
                    item
                    className={[classes.icons_con, classes.ML10, classes.MT10]}
                  >
                    <a
                      href="https://twitter.com/i/flow/login"
                      rel="noreferrer"
                      target={"_blank"}
                    >
                      <i class="bi bi-twitter"></i>
                    </a>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid container className={classes.MT20}>
        <Typography
          className={[classes.footer_header_title, classes.footer_copy_right]}
        >
          {`Copyright ⓒ ${dynamicYear} Direct Expertise. All Rights Reserved`}
        </Typography>
      </Grid>
    </div>
  );
}

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
