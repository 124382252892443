import React, { useEffect, useState } from "react";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { flattenDeep, isArray, isEmpty } from "lodash";
import { useSelector } from "react-redux";
import Candidates from "../../../Components/Jobs/Candidates";
import { Setting } from "../../../Utils/Setting";
import { getApiData } from "../../../Utils/APIHelper";
import useStyles from "./styles";

export default function ViewCandidate() {
  const classes = useStyles();
  const { token } = useSelector((state) => state.auth);
  const [candidateList, setCandidateList] = useState({
    data: [],
    pageData: {},
  });
  const [pageLoad, setPageLoad] = useState(true);
  const [loadMoreBtn, setLoadMoreBtn] = useState(false);

  useEffect(() => {
    document.title = Setting.page_name.EMPLOYER_VIEW_CANDIDATE;
  }, []);

  useEffect(() => {
    getCandidatesList();
  }, []);

  // this function for get candidates list
  async function getCandidatesList(key = "") {
    let page = Number(candidateList?.pageData?.currentPage) || 1;
    if (key === "load") {
      page = page + 1;
    }
    try {
      const response = await getApiData(
        Setting.endpoints.employerViewCandidates,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (!isEmpty(response.data) && isArray(response?.data.rows)) {
          const newData =
            key === "load"
              ? flattenDeep([...candidateList?.data, response?.data?.rows])
              : response?.data?.rows;
          setCandidateList({
            data: newData || [],
            pageData: response?.data?.pagination,
          });
        }
      }
      setLoadMoreBtn(false);
      setPageLoad(false);
    } catch (error) {
      console.log("error ===>>>", error);
      setLoadMoreBtn(false);
      setPageLoad(false);
    }
  }

  const loadMoreData = candidateList?.pageData?.isMore || false;

  return (
    <Grid container className={classes.container}>
      <div className={classes.titleSection}>
        <Typography className={classes.sectionTitle}>
          View Candidates
        </Typography>
      </div>
      {pageLoad ? (
        <div className={classes.loaderView}>
          <CircularProgress size={50} />
        </div>
      ) : isArray(candidateList?.data) && candidateList?.data.length > 0 ? (
        candidateList?.data.map((item) => {
          return <Candidates data={item} />;
        })
      ) : (
        <div className={classes.loaderView}>
          <Typography className={classes.sectionTitle}>
            No Candidates
          </Typography>
        </div>
      )}
      {loadMoreData && (
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={3}>
            <Button
              variant={loadMoreBtn ? "outlined" : "contained"}
              color="primary"
              fullWidth
              onClick={() => {
                setLoadMoreBtn(true);
                getCandidatesList("load");
              }}
              disabled={loadMoreBtn}
            >
              {loadMoreBtn ? <CircularProgress size={28} /> : "Load More"}
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
