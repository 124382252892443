const actions = {
  SET_USER_DATA: "auth/SET_USER_DATA",
  SET_DROPDOWN_DATA: "auth/SET_DROPDOWN_DATA",
  CLEAR_ALL_STORAGE_DATA: "auth/CLEAR_ALL_STORAGE_DATA",
  SET_TOKEN: "auth/SET_TOKEN",
  SET_REVIEW: "auth/SET_REVIEW",
  SET_USER_UUID: "auth/SET_USER_UUID",
  SET_NOTI_DATA: "auth/SET_NOTI_DATA",
  SET_CANDIDATEPROFILE: "auth/SET_CANDIDATEPROFILE",
  SET_COMMUNICATION: "auth/SET_COMMUNICATION",
  SET_DISPLAY_NOTIFICATION_POP_UP: "auth/SET_DISPLAY_NOTIFICATION_POP_UP",
  SET_WELCOME_SCREEN: "auth/SET_WELCOME_SCREEN",
  SET_VISIBILITY: "auth/SET_VISIBILITY",
  ONLINE_STATUS: "auth/ONLINE_STATUS",
  BACK_ACTION: "auth/BACK_ACTION",
  SET_RATING: "auth/SET_RATING",

  displayNotificationPopUp: (isNotifiy) => (dispatch) =>
    dispatch({
      type: actions.SET_DISPLAY_NOTIFICATION_POP_UP,
      isNotifiy,
    }),

  setNotiData: (notiData) => (dispatch) =>
    dispatch({
      type: actions.SET_NOTI_DATA,
      notiData,
    }),

  setVisibility: (visibility) => (dispatch) =>
    dispatch({
      type: actions.SET_VISIBILITY,
      visibility,
    }),

  setUserData: (userdata) => (dispatch) =>
    dispatch({
      type: actions.SET_USER_DATA,
      userdata,
    }),

  setRating: (rating) => (dispatch) =>
    dispatch({
      type: actions.SET_RATING,
      rating,
    }),

  setUserUUID: (useruuid) => (dispatch) =>
    dispatch({
      type: actions.SET_USER_UUID,
      useruuid,
    }),

  setDropDownData: (dropdowndata) => (dispatch) =>
    dispatch({
      type: actions.SET_DROPDOWN_DATA,
      dropdowndata,
    }),

  setToken: (token) => (dispatch) =>
    dispatch({
      type: actions.SET_TOKEN,
      token,
    }),

  setReview: (reviewdata) => (dispatch) =>
    dispatch({
      type: actions.SET_REVIEW,
      reviewdata,
    }),

  setCandidateProfile: (candidateProfile) => (dispatch) =>
    dispatch({
      type: actions.SET_CANDIDATEPROFILE,
      candidateProfile,
    }),

  setCommunication: (communicationPreference) => (dispatch) =>
    dispatch({
      type: actions.SET_COMMUNICATION,
      communicationPreference,
    }),

  setWelcomeScreen: (welcomeScreen) => (dispatch) =>
    dispatch({
      type: actions.SET_WELCOME_SCREEN,
      welcomeScreen,
    }),

  clearAllData: () => (dispatch) =>
    dispatch({
      type: actions.CLEAR_ALL_STORAGE_DATA,
    }),

  setOnlineStatus: (isOnline) => (dispatch) =>
    dispatch({
      type: actions.ONLINE_STATUS,
      isOnline,
    }),
  setBackAction: (isBack) => (dispatch) =>
    dispatch({
      type: actions.BACK_ACTION,
      isBack,
    }),
};

export default actions;
