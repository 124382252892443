import React from "react";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Modal,
  Button,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import useStyles from "./styles";

function ConfirmDialog(props) {
  const {
    visible = false,
    handleModal = () => null,
    title = "",
    btnLoad = false,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: md ? 300 : 600,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={visible}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <Typography
            variant="subtitle1"
            component="h2"
            className={classes.modalTitle}
          >
            {title || ""}
          </Typography>
          <div
            className={classes.splitViewStyle}
            style={{ justifyContent: "unset" }}
          >
            <Button
              variant="outlined"
              color="primary"
              className={classes.modalBtnStyle}
              fullWidth
              onClick={() => {
                if (handleModal) {
                  handleModal();
                }
              }}
              disabled={btnLoad}
            >
              No
            </Button>
            <div style={{ width: 50 }} />
            <Button
              variant={btnLoad ? "outlined" : "contained"}
              color="primary"
              className={classes.modalBtnStyle}
              fullWidth
              onClick={() => {
                if (handleModal) {
                  handleModal(true);
                }
              }}
              disabled={btnLoad}
            >
              {btnLoad ? <CircularProgress size={28} /> : "Yes"}
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export default ConfirmDialog;
