import React, { useState, useEffect } from "react";
import { Typography, Grid } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import useStyles from "../styles";
import Images from "../../../Config/Images";
import MVideorole from "../../Profile/Modal/MVideorole";
import { isEmpty } from "lodash";
import { isIOS } from "react-device-detect";

function VideoRole(props) {
  const { setData = () => null, data = {} } = props;
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState({});

  const [videoUrl, setVideoUrl] = useState("");

  const isComplete = !isEmpty(editData);
  const isDataEmpty = !isEmpty(data);

  useEffect(() => {
    if (isDataEmpty) {
      setEditData(
        {
          VideoUrl: data?.job_role_video,
        } || {}
      );
      setVideoUrl(data?.job_role_video);
    }
  }, [data]);

  useEffect(() => {
    setData(editData);
    setVideoUrl(editData.VideoUrl);
  }, [editData]);

  return (
    <Grid
      item
      xs={12}
      className={[
        classes.shadowViewStyle,
        classes.paddingStyle,
        classes.marginDiv,
      ]}
    >
      <div id="role" className={classes.splitViewStyle}>
        <Typography className={classes.sectionTitle}>
          Video for your profile
        </Typography>
        {isComplete ? (
          <div
            className={classes.editBtnStyle}
            onClick={() => {
              setOpenModal(true);
              setVideoUrl("");
            }}
          >
            <img src={Images.edit} style={{ width: 14 }} />
          </div>
        ) : (
          <AddCircle
            className={classes.addIcon}
            onClick={() => {
              setOpenModal(true);
            }}
          />
        )}
      </div>
      {isComplete && videoUrl ? (
        <div style={{ marginTop: 10 }}>
          <video
            width="100%"
            height={300}
            controls
            poster={isIOS ? Images.videoThumbnail : ""}
          >
            <source src={videoUrl} type="video/mp4" />
          </video>
        </div>
      ) : (
        <Typography className={classes.sectionDesc}>Add video</Typography>
      )}
      <MVideorole
        visible={openModal}
        handleModal={(type) => {
          setOpenModal(false);
          if (type === "close") {
            setVideoUrl(editData.VideoUrl);
          }
        }}
        onSave={(data) => {
          setEditData(data);
          setOpenModal(false);
        }}
        editData={editData}
      />
    </Grid>
  );
}

export default VideoRole;
