import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Radio,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import {
  Close,
  Check,
  CheckCircle,
  RadioButtonChecked,
} from "@mui/icons-material";
import { find, findIndex, isArray, isEmpty } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Setting } from "../../../Utils/Setting";
import { getApiData } from "../../../Utils/APIHelper";
import useStyles from "./styles";

export default function CandidateResult() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = useSelector((state) => state.auth);
  const jobId = location?.state?.jobId || null;
  const userId = location?.state?.userId || null;
  const [activeStep, setActiveStep] = useState(0);
  const [finalAnswers, setFinalAnswers] = useState([]);
  const [selectedQue, setSelectedQue] = useState({});
  const [pageLoad, setPageLoad] = useState(false);
  const [quizList, setQuizList] = useState([]);
  const lastIndex = quizList.length - 1;

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    document.title = Setting.page_name.QUIZ;

    if (jobId && userId) {
      getCandidateResult();
    } else {
      setPageLoad(false);
    }
  }, []);

  // this function for get candidate quiz result
  async function getCandidateResult() {
    setPageLoad(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.jobCandidateResult}?job_post_id=${jobId}&candidate_id=${userId}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (isArray(response.data) && response.data.length > 0) {
          setQuizList(response.data);
        }
      } else {
        toast.error(response.message);
      }
      setPageLoad(false);
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
      setPageLoad(false);
    }
  }

  // this function for handle steps
  function handleSteps(data = {}) {
    const nArr = [...finalAnswers];

    if (isArray(nArr) && nArr.length > 0) {
      const nIndex = findIndex(nArr, (item) => item.question === data.question);

      if (nIndex > -1) {
        nArr[nIndex].ans = data.ans;
      } else {
        nArr.push(data);
      }
      setFinalAnswers(nArr);
    } else {
      setFinalAnswers([data]);
    }

    if (lastIndex === activeStep) {
      navigate(-1);
    } else {
      setActiveStep((step) => step + 1);
      setSelectedQue({});
    }
  }

  // this function for handle back button
  function handleBack() {
    const nIndex = activeStep - 1;
    if (nIndex >= 0) {
      const nData = find(
        finalAnswers,
        (item) => item.qId === quizList[nIndex].id
      );
      if (!isEmpty(nData)) {
        setSelectedQue(nData);
      }
    }
    setActiveStep((step) => step - 1);
  }

  function renderStepper() {
    return (
      <Grid container alignItems="center">
        {isArray(quizList) &&
          quizList.length > 0 &&
          quizList.map((ii, index) => {
            const isView = activeStep > index;
            const arrLength = quizList.length;
            const isLast = arrLength === index + 1;
            const isFalse =
              ii?.candidate_selected_option !== ii?.selected_option;
            return (
              <>
                <div
                  className={classes.steppermain}
                  // style={{ backgroundColor: isFalse ? "#FF0404" : "#00B51E" }}
                >
                  <Typography style={{ color: "#FFF", fontSize: 20 }}>
                    {index + 1}
                  </Typography>
                  {/* {isFalse ? (
                    <Close style={{ color: "#fff", fontSize: sm ? 18 : 26 }} />
                  ) : (
                    <Check style={{ color: "#fff", fontSize: sm ? 18 : 26 }} />
                  )} */}
                </div>
                {isLast ? null : (
                  <div
                    className={classes.lineMain}
                    style={{
                      backgroundColor: isView ? "#34567b" : "#D6AC85",
                    }}
                  />
                )}
              </>
            );
          })}
      </Grid>
    );
  }

  // this function renders questions
  function renderQuestionDiv() {
    if (isArray(quizList) && quizList.length > 0) {
      return quizList.map((item, key) => {
        const isTrue =
          item?.selected_option === item?.candidate_selected_option;
        if (activeStep === key) {
          return (
            <>
              <Grid className={classes.fullWidth}>
                <Typography
                  className="primarycolor fontsize"
                  style={{ marginBottom: 20 }}
                >
                  {item.question || ""}
                </Typography>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {item?.options.map((ii, index1) => {
                    const str = ii?.id;
                    const isSelected =
                      item?.selected_option === str.toString() ||
                      item?.candidate_selected_option === str.toString();
                    const viewFalse =
                      !isTrue &&
                      item?.candidate_selected_option === str.toString();
                    const viewTrue = item?.selected_option === str.toString();
                    return (
                      <div
                        className="radiomain"
                        style={{
                          marginBottom: 10,
                          padding: 10,
                          backgroundColor: "#fff",
                          borderColor: "#1f4771",
                        }}
                      >
                        <Radio
                          checked={isSelected}
                          onChange={() => {
                            return null;
                          }}
                          value="a"
                          name="radio-buttons"
                          inputProps={{ "aria-label": "A" }}
                          sx={{
                            "&.Mui-checked": {
                              color: viewFalse ? "#FF0404" : "#00B51E",
                            },
                          }}
                          checkedIcon={
                            viewTrue ? <CheckCircle /> : <RadioButtonChecked />
                          }
                          style={{ padding: 0, marginRight: 6 }}
                        />
                        <Typography
                          className="regulerfont"
                          style={{
                            color: "#1f4771",
                          }}
                        >
                          {ii?.value}
                        </Typography>
                      </div>
                    );
                  })}
                </div>
              </Grid>
              <Grid
                item
                container
                xs={12}
                justifyContent={activeStep === 0 ? "flex-end" : "space-between"}
                style={{ marginTop: 30 }}
                // className={classes.bottomView}
              >
                {activeStep === 0 ? null : (
                  <Button
                    onClick={handleBack}
                    variant="outlined"
                    className={classes.buttonStyle}
                  >
                    Previous
                  </Button>
                )}
                <Button
                  onClick={() => {
                    handleSteps(selectedQue);
                  }}
                  variant="contained"
                  className={classes.buttonStyle}
                >
                  {lastIndex === activeStep ? "finish" : "next"}
                </Button>
              </Grid>
            </>
          );
        }
      });
    }
  }

  if (pageLoad) {
    return (
      <Grid container>
        <div className={classes.loaderView}>
          <CircularProgress size={50} />
        </div>
      </Grid>
    );
  }

  return (
    <Grid container className={classes.container}>
      {isArray(quizList) && quizList.length > 0 ? (
        <>
          <Grid item xs={12} style={{ marginBottom: 30 }}>
            {renderStepper()}
          </Grid>
          {renderQuestionDiv()}
        </>
      ) : (
        <div className={classes.loaderView}>
          <Typography className={classes.sectionTitle}>
            No Result Found
          </Typography>
        </div>
      )}
    </Grid>
  );
}
