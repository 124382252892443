import { makeStyles } from "@mui/styles";
import Images from "./Images";

export const candidate_dashboard_menu_link = [
  {
    id: 1,
    title: "Jobs",
    link: "/candidate/jobs",
    key: ["/candidate/jobs", "/company/profile"],
  },
  {
    id: 2,
    title: "Preferences",
    link: "/candidate/preferences",
    key: ["/candidate/preferences"],
  },
  {
    id: 3,
    title: "My Profile",
    link: "/candidate/profile",
    key: ["/candidate/profile"],
  },
  {
    id: 4,
    title: "Account",
    link: "/candidate/account",
    key: ["/candidate/account"],
  },
];

export const employer_dashboard_menu_link = [
  {
    id: 1,
    title: "Jobs",
    link: "/employer/jobs",
    key: [
      "/employer/jobs",
      "/employer/jobs/candidates",
      "/employer/jobs/matching-job",
      "/candidate/profile",
      "/employer/jobs/post-job",
      "/company/question",
      "/employer/jobs/view-jobs",
    ],
  },
  {
    id: 2,
    title: "My Company",
    link: "/employer/company",
    key: ["/company/profile", "/employer/company"],
  },
  {
    id: 3,
    title: "My Account",
    link: "/employer/account",
    key: ["/employer/account"],
  },
];

export const CMS_List = [
  {
    id: 1,
    title: "About us",
    link: "/cms/about-us",
  },
  {
    id: 2,
    title: "Contact us",
    link: "/cms/contact-us",
  },
  {
    id: 3,
    title: "Privacy Policy",
    link: "/cms/privacy-policy",
  },
  {
    id: 4,
    title: "Terms & Conditions",
    link: "/cms/terms-and-condition",
  },
];

export const dummy_data = [
  {
    id: 1,
    value: "10",
    display: "100",
  },
  {
    id: 2,
    value: "20",
    display: "200",
  },
  {
    id: 3,
    value: "30",
    display: "300",
  },
  {
    id: 4,
    value: "40",
    display: "400",
  },
];

export const dummy_sliderdata = [
  {
    id: 1,
    name: "ERICK OLSON",
    detail: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).`,
  },

  {
    id: 2,
    name: "Micheal Doe",
    detail: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).`,
  },

  {
    id: 3,
    name: "ERICK OLSON",
    detail: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).`,
  },

  {
    id: 4,
    name: "Micheal Doe",
    detail: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).`,
  },
];

export const experienceData = [
  {
    title: "Business Analyst",
    website: "xyz.co",
    date: "April 2019 - Present · 2 years 10 months",
    desc1:
      "I have been working here for 3 + years & got many chances to put the different role under this 3 year.",
    desc2:
      "I have work as Jr. backend developer, Jr.QA, Sr.QA, Project Co-ordinator, Project Manager & Business Analyst.",
  },
];

export const educationData = [
  {
    title: "Master of Computer Application",
    university: "Charusat Univeristy",
    startDate: "June 2017",
    endDate: "April 2019",
    qualification: "Professional Qualification",
    result: "M.C.A - 7.1",
  },
  {
    title: "Bachelor of Computer Application",
    university: "Charusat Univeristy",
    startDate: "June 2014",
    endDate: "April 2017",
    qualification: "Professional Qualification",
    result: "B.C.A - 9.1",
  },
];

export const languageData = [
  {
    title: "Hindi - Fluent",
  },
  {
    title: "Gujarati - Fluent",
  },
  {
    title: "English - Intermediate",
  },
];

export const skillsData = [
  {
    title: "Microsoft Office",
  },
  {
    title: "Teamwork",
  },
  {
    title: "Business Analyst",
  },
  {
    title: "QA",
  },
  {
    title: "PHP",
  },
  {
    title: "Word",
  },
];

export const bioDetail = {
  birthDate: "31/06/1998",
  address: "5th Floor, 111 Buckingham Palace Road, London - SW1W 0SR",
  gender: "Female",
  hometown: "London",
  status: "Single",
};

export const profileSideBarArray = [
  {
    title: "Profile Visibility",
    type: "switch",
    key: "",
  },
  {
    title: "Availability",
    icon: Images.availability,
    key: "availability",
  },
  {
    title: "Job Type",
    icon: Images.job,
    key: "jobtype",
  },
  {
    title: "Experience",
    icon: Images.experience,
    key: "experience",
  },
  {
    title: "Skills",
    icon: Images.skills,
    key: "skills",
  },
  {
    title: "Education",
    icon: Images.education,
    key: "education",
  },
  {
    title: "Languages",
    icon: Images.language,
    key: "language",
  },
  {
    title: "Bio",
    icon: Images.bio,
    key: "bio",
  },
];

export const profileSideBarArray1 = [
  {
    title: "Availability",
    icon: Images.availability,
    key: "availability",
  },
  {
    title: "Job Type",
    icon: Images.job,
    key: "jobtype",
  },
  {
    title: "Experience",
    icon: Images.experience,
    key: "experience",
  },
  {
    title: "Skills",
    icon: Images.skills,
    key: "skills",
  },
  {
    title: "Education",
    icon: Images.education,
    key: "education",
  },
  {
    title: "Languages",
    icon: Images.language,
    key: "language",
  },
  {
    title: "Bio",
    icon: Images.bio,
    key: "bio",
  },
];

export const quizData = [
  {
    id: 1,
    question: "How are you?",
    options: [
      {
        op_1: "fine",
      },
      {
        op_2: "thanks",
      },
      {
        op_3: "not well",
      },
      {
        op_4: "good",
      },
    ],
    standard_ans: "op_3",
    is_last: false,
  },
  {
    id: 2,
    question: "What is your age",
    options: [
      {
        op_1: "20",
      },
      {
        op_2: "21",
      },
      {
        op_3: "22",
      },
      {
        op_4: "25",
      },
    ],
    standard_ans: "op_3",
    is_last: false,
  },
  {
    id: 3,
    question: "What is your name",
    options: [
      {
        op_1: "sahil",
      },
      {
        op_2: "vikas",
      },
      {
        op_3: "fejan",
      },
      {
        op_4: "sagar",
      },
    ],
    standard_ans: "op_1",
    is_last: false,
  },
  {
    id: 14,
    question: "What is PHP?",
    options: [
      {
        op_1: "PHP is an open-source programming language",
      },
      {
        op_2: " PHP is a server-side scripting language",
      },
      {
        op_3: "PHP is used to develop dynamic and interactive websites",
      },
      {
        op_4: "All of the mentioned",
      },
    ],
    standard_ans: "op_4",
    is_last: false,
  },
  {
    id: 15,
    question: "Who is the father of PHP?",
    options: [
      {
        op_1: "Drek Kolkevi ",
      },
      {
        op_2: " List Barely",
      },
      {
        op_3: "Rasmus Lerdorf ",
      },
      {
        op_4: "Willam Makepiece",
      },
    ],
    standard_ans: "op_3",
    is_last: false,
  },
  {
    id: 16,
    question: "How to define a function in PHP?",
    options: [
      {
        op_1: " functionName(parameters) {function body}",
      },
      {
        op_2: "function {function body}",
      },
      {
        op_3: " function functionName(parameters) {function body}",
      },
      {
        op_4: "data type functionName(parameters) {function body}",
      },
    ],
    standard_ans: "op_3",
    is_last: false,
  },
  {
    id: 17,
    question: "Which is the right way of declaring a variable in PHP?",
    options: [
      {
        op_1: "$3hello",
      },
      {
        op_2: "$_hello",
      },
      {
        op_3: "$this",
      },
      {
        op_4: "$5_Hello",
      },
    ],
    standard_ans: "op_2",
    is_last: true,
  },
];

export const communicationData = [
  // {
  //   id: 1,
  //   name: "Critical platform notifications",
  //   detail: "e.g. when you are invited to interviews, receive job offers, etc.",
  //   email: true,
  //   phone: true,
  //   disable: true,
  // },
  {
    id: 2,
    name: "Profile reminders",
    detail: "Hints and tips to appear more employable to employers.",
    email: false,
    phone: false,
    disable: false,
  },
  {
    id: 3,
    name: "News about Direct Expertise",
    detail:
      "Occasional updates from us about events, special offers, updates, etc.",
    email: false,
    phone: false,
    disable: false,
  },
];

export const matchingJobList = [
  {
    title: "Content Associate",
    description: "Insurtech Insights",
    salary: "£35,000 - £40,000",
    location: "South West London",
    start: "ASAP",
    duration: "Permanent",
    rate: "",
  },
  {
    title: "Sales Operations Executive",
    description: "Netgem TV",
    salary: "£35,000 - £40,000",
    location: "South West London",
    start: "ASAP",
    duration: "6 Months",
    rate: "£12.00 / hour",
  },
  {
    title: "Fulfilment Operations Associate",
    description: "Hofy",
    salary: "£12.00 / hour",
    location: "South West London",
    start: "ASAP",
    duration: "3 Months",
    rate: "£12.00 / hour",
  },
  {
    title: "Operations Executive",
    description: "JPC",
    salary: "£27,000 - £32,000",
    location: "The City of London",
    start: "ASAP",
    duration: "Permanent",
    rate: "",
  },
];

export const interestedJobList = [
  {
    title: "Content Associate",
    description: "Insurtech Insights",
    salary: "£35,000 - £40,000",
    location: "South West London",
    start: "ASAP",
    duration: "Permanent",
    rate: "",
  },
  {
    title: "Sales Operations Executive",
    description: "Netgem TV",
    salary: "£35,000 - £40,000",
    location: "South West London",
    start: "ASAP",
    duration: "6 Months",
    rate: "£12.00 / hour",
  },
];

export const dummyMessage = [
  {
    title: "Kieran",
    description: "Welcome",
    message:
      "Receive interview requests and job offers direct from employers - usually within 48 hours. We really hope you enjoy using the platform! If you have any questions please email directexpertise14@gmail.com Happy job hunting.",
    date: "31 Jan 2022",
  },
  {
    title: "Joy Dove",
    description: "Welcome",
    message:
      "Receive interview requests and job offers direct from employers - usually within 48 hours. We really hope you enjoy using the platform! If you have any questions please email directexpertise14@gmail.com Happy job hunting.",
    date: "26 Jan 2022",
  },
  {
    title: "Michal",
    description: "Welcome",
    message:
      "Receive interview requests and job offers direct from employers - usually within 48 hours. We really hope you enjoy using the platform! If you have any questions please email directexpertise14@gmail.com Happy job hunting.",
    date: "18 Jan 2022",
  },
  {
    title: "Insurtech Insights",
    description: "Welcome",
    message:
      "Receive interview requests and job offers direct from employers - usually within 48 hours. We really hope you enjoy using the platform! If you have any questions please email directexpertise14@gmail.com Happy job hunting.",
    date: "5 Dec 2021",
  },
];

export const myJobsList = [
  {
    title: "Content Associate",
    description: "Insurtech Insights",
    salary: "£35,000 - £40,000",
    start: "26 Feb 2022",
    vacancies: "1",
    jobPost: "25 Feb 2022",
    lastApply: "28 Feb 2022",
  },
  {
    title: "Sales Operations Executive",
    description: "Netgem TV",
    salary: "£35,000 - £40,000",
    start: "26 Feb 2022",
    vacancies: "5",
    jobPost: "25 Feb 2022",
    lastApply: "28 Feb 2022",
  },
  {
    title: "Fulfilment Operations Associate",
    description: "Hofy",
    salary: "£12.00 / hour",
    start: "26 Feb 2022",
    vacancies: "2",
    jobPost: "25 Feb 2022",
    lastApply: "28 Feb 2022",
  },
  {
    title: "Operations Executive",
    description: "JPC",
    salary: "£27,000 - £32,000",
    start: "26 Feb 2022",
    vacancies: "4",
    jobPost: "25 Feb 2022",
    lastApply: "28 Feb 2022",
  },
];

export const jobMatchingList = [
  {
    name: "Kieran",
    image: Images.demo,
  },
  {
    name: "Kieran",
    image: Images.estep1,
  },
  {
    name: "Kieran",
    image: Images.estep2,
  },
];

export const quationarr = [
  {
    id: 1,
    question: "How are you?",
    options: [
      {
        id: 1,
        value: "fine",
      },
      {
        id: 2,
        value: "thanks",
      },
      {
        id: 3,
        value: "not well",
      },
      {
        id: 4,
        value: "good",
      },
    ],
    standard_ans: "3",
    is_last: false,
  },
  {
    id: 2,
    question: "What is your age",
    options: [
      {
        id: 1,
        value: "20",
      },
      {
        id: 2,
        value: "21",
      },
      {
        id: 3,
        value: "22",
      },
      {
        id: 4,
        value: "25",
      },
    ],
    standard_ans: "3",
    is_last: false,
  },
  {
    id: 3,
    question: "What is your name",
    options: [
      {
        id: 1,
        value: "sahil",
      },
      {
        id: 2,
        value: "vikas",
      },
      {
        id: 3,
        value: "fejan",
      },
      {
        id: 4,
        value: "sagar",
      },
    ],
    standard_ans: "1",
    is_last: false,
  },
  {
    id: 14,
    question: "What is PHP?",
    options: [
      {
        id: 1,
        value: "PHP is an open-source programming language",
      },
      {
        id: 2,
        value: " PHP is a server-side scripting language",
      },
      {
        id: 3,
        value: "PHP is used to develop dynamic and interactive websites",
      },
      {
        id: 4,
        value: "All of the mentioned",
      },
    ],
    standard_ans: "4",
    is_last: false,
  },
  {
    id: 15,
    question: "Who is the father of PHP?",
    options: [
      {
        id: 1,
        value: "Drek Kolkevi ",
      },
      {
        id: 2,
        value: " List Barely",
      },
      {
        id: 3,
        value: "Rasmus Lerdorf ",
      },
      {
        id: 4,
        value: "Willam Makepiece",
      },
    ],
    standard_ans: "3",
    is_last: false,
  },
  {
    id: 16,
    question: "How to define a function in PHP?",
    options: [
      {
        id: 1,
        value: " functionName(parameters) {function body}",
      },
      {
        id: 2,
        value: "function {function body}",
      },
      {
        id: 3,
        value: " function functionName(parameters) {function body}",
      },
      {
        id: 4,
        value: "data type functionName(parameters) {function body}",
      },
    ],
    standard_ans: "3",
    is_last: false,
  },
  {
    id: 17,
    question: "Which is the right way of declaring a variable in PHP?",
    options: [
      {
        id: 1,
        value: "$3hello",
      },
      {
        id: 2,
        value: "$_hello",
      },
      {
        id: 3,
        value: "$this",
      },
      {
        id: 4,
        value: "$5_Hello",
      },
    ],
    standard_ans: "2",
    is_last: true,
  },
];

export const newJobPostArray = [
  {
    title: "Job Visibility",
    type: "switch",
    key: "",
  },
  {
    title: "About your job",
    icon: Images.job,
    key: "job",
  },
  {
    title: "Employment type",
    icon: Images.availability,
    key: "emptype",
  },
  {
    title: "Video for your role",
    icon: Images.job,
    key: "role",
  },
  {
    title: "Job postcode",
    icon: Images.education,
    key: "joblocation",
  },
  {
    title: "Description of role",
    icon: Images.language,
    key: "descrole",
  },
  {
    title: "Required skills",
    icon: Images.skills,
    key: "skills",
  },
  {
    title: "Language required",
    icon: Images.language,
    key: "language",
  },
];

export const employeOffers = [
  {
    title: "Content Associate",
    description: "Insurtech Insights",
    salary: "£35,000 - £40,000",
    offerTo: "Kieran",
  },
  {
    title: "Sales Operations Executive",
    description: "Netgem TV",
    salary: "£35,000 - £40,000",
    offerTo: "Kieran",
  },
];

export const empCommunicationData1 = [
  // {
  //   id: 1,
  //   name: "Critical platform notifications",
  //   detail:
  //     "e.g., candidates interested in your job post,  messages from candidates, suitable candidate profiles, etc.",
  //   email: true,
  //   phone: true,
  //   disable: true,
  // },
  {
    id: 2,
    name: "New jobs requests",
    detail: "Get notified about new jobs which you might be interested in.",
    email: false,
    phone: false,
    disable: false,
  },
  {
    id: 3,
    name: "News about Direct Expertise",
    detail:
      "Occasional updates from us about events, special offers updates, etc.",
    email: false,
    phone: false,
    disable: false,
  },
];

export const CandidateAnswer = [
  {
    job_post_id: 52,
    question_id: 6,
    question: "What motivates you?",
    options: [
      {
        id: 1,
        value:
          "Many things motivate me. My goal is to be the best of what I can be, often motivates me to go beyond my own expectations. When I see myself being productive every day, it motivates me to continue.",
      },
      {
        id: 2,
        value:
          "Responsibility towards work motivates me the most, and my aim within any company is to move up to greater levels of responsibility to achieve each goal with better responsibilities.",
      },
      {
        id: 3,
        value:
          "I am a very result-oriented person. My primary motivation is to achieve the desired result.  While I enjoy working on the project on my own, I am particularly motivated by the buzz of working in a team. It’s very exciting working closely with others, who ",
      },
      {
        id: 4,
        value:
          "Primarily, my ability to work hard and delivering result motivates me.  But subsequent recognition of my efforts gives me the encouragement for my next efforts.",
      },
    ],
    candidate_selected_option: "2",
    selected_option: "1",
  },
  {
    job_post_id: 52,
    question_id: 7,
    question: "How do you deal with criticism?",
    options: [
      {
        id: 1,
        value:
          "I enjoy solving problems and I am a great team player. I also believe that my values are aligned with this company’s values",
      },
      {
        id: 2,
        value:
          " I am always enthusiastic about learning new things and during the process, I might tend to make mistakes. If someone provides me with constructive criticism, I am always open to it and I will work on correcting myself and learn from my mistakes. ",
      },
      {
        id: 3,
        value:
          "If the feedback is negative, then I am mature enough to ignore the feedback and continue working on doing my job to the best of my capabilities without dampening my spirit.",
      },
      {
        id: 4,
        value:
          "I will be super thrilled if I win such a lottery as it would mean that I would be having a hefty saving for me and the future of my family.",
      },
    ],
    candidate_selected_option: "3",
    selected_option: "3",
  },
  {
    job_post_id: 52,
    question_id: 3,
    question: "What is your name",
    options: [
      {
        id: 1,
        value: "sahil",
      },
      {
        id: 2,
        value: "vikas",
      },
      {
        id: 3,
        value: "fejan",
      },
      {
        id: 4,
        value: "sagar",
      },
    ],
    candidate_selected_option: "1",
    selected_option: "2",
  },
];

export const htmlContent = makeStyles(() => ({
  // Styles to Content Written by user from Editor
  style: {
    "& p,span": {
      fontFamily: `PoppinsRegular !important`,
    },
    "& h1,h2,h3,h4": {
      fontFamily: `PoppinsSemiBold !important`,
    },
    wordBreak: "break-word",
  },
}));

export const milesList = [
  {
    label: "up to 1 mile",
    value: "1",
  },
  {
    label: "up to 2 miles",
    value: "2",
  },
  {
    label: "up to 5 miles",
    value: "5",
  },
  {
    label: "up to 7 miles",
    value: "7",
  },
  {
    label: "up to 10 miles",
    value: "10",
  },
  {
    label: "up to 15 miles",
    value: "15",
  },
  {
    label: "up to 25 miles",
    value: "25",
  },
  {
    label: "up to 35 miles",
    value: "35",
  },
  {
    label: "up to 50 miles",
    value: "50",
  },
  {
    label: "up to 75 miles",
    value: "75",
  },
  {
    label: "up to 100 miles",
    value: "100",
  },
  {
    label: "up to 250 miles",
    value: "250",
  },
  {
    label: "up to 500 miles",
    value: "500",
  },
  {
    label: "up to 750 miles",
    value: "750",
  },
];

export const experienceArr = [
  {
    label: "No experience required",
    value: "0",
  },
  {
    label: "0 - 1",
    value: "0 - 1",
  },
  {
    label: "1 - 2",
    value: "1 - 2",
  },
  {
    label: "2 - 4",
    value: "2 - 4",
  },
  {
    label: "4 - 5",
    value: "4 - 5",
  },
  {
    label: "5 - 10",
    value: "5 - 10",
  },
  {
    label: "10 - 15",
    value: "10 - 15",
  },
  {
    label: "15 - 20",
    value: "15 - 20",
  },
  {
    label: "20 +",
    value: "20+",
  },
];
