import React, { useState, useEffect } from "react";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Modal,
  Button,
  useTheme,
  CircularProgress,
  useMediaQuery,
  TextField,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { isEdge } from "react-device-detect";
import useStyles from "../styles";
import { isEmpty } from "lodash";
import { Setting } from "../../../Utils/Setting";

const errorObj = {
  currentPassErr: false,
  newPassErr: false,
  confirmErr: false,
  currentPassMsg: "",
  newPassMsg: "",
  confirmMsg: "",
};

function MChange(props) {
  const {
    visible = false,
    handleModal = () => null,
    saveClick = () => null,
    loader = false,
  } = props;
  const classes = useStyles();

  const [currentPass, setCurrentPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [errObj, setErrObj] = useState(errorObj);

  const [passVisible, setPasswordVisible] = useState(false);
  const [newPassVisible, setNewPassVisible] = useState(false);
  const [confirmPassVisible, setConfirmPassVisible] = useState(false);

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const passRegex = Setting.JS_Regex.pwd1_Regex;

  useEffect(() => {
    resetFormData();
  }, [visible]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 300 : 330,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
  };

  // this function for reset form data
  function resetFormData(type = "") {
    setCurrentPass("");
    setNewPass("");
    setConfirmPass("");
    setPasswordVisible(false);
    setNewPassVisible(false);
    setConfirmPassVisible(false);
    setErrObj(errorObj);
    if (type === "close" && handleModal) {
      handleModal();
    }
  }

  function checkValidation() {
    const error = { ...errObj };
    let valid = true;
    if (isEmpty(currentPass)) {
      valid = false;
      error.currentPassErr = true;
      error.currentPassMsg = "Please enter current password";
    } else if (!passRegex.test(currentPass)) {
      valid = false;
      error.currentPassErr = true;
      error.currentPassMsg = "Password must be 8 character long";
    } else if (isEmpty(newPass)) {
      valid = false;
      error.newPassErr = true;
      error.newPassMsg = "Please enter new password";
    } else if (!passRegex.test(currentPass)) {
      valid = false;
      error.newPassErr = true;
      error.newPassMsg = "Password must be 8 characters long";
    } else if (currentPass === newPass) {
      valid = false;
      error.newPassErr = true;
      error.newPassMsg = "Old & New password can't be same";
    } else if (isEmpty(confirmPass)) {
      valid = false;
      error.confirmErr = true;
      error.confirmMsg = "Please enter confirm password";
    } else if (newPass !== confirmPass) {
      valid = false;
      error.confirmErr = true;
      error.confirmMsg = "New & confirm password must be same";
    }

    setErrObj(error);

    if (valid) {
      const passData = {
        newPass: newPass,
        confirmPass: currentPass,
      };
      saveClick(passData);
    }
  }

  return (
    <Modal
      open={visible}
      onClose={() => resetFormData("close")}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <div className={classes.splitViewStyle}>
            <Typography variant="subtitle1" component="h2">
              Change Password
            </Typography>
            <Typography
              className="primarycolor mediumfont"
              style={{ marginTop: 10 }}
            >
              Current password
            </Typography>
            <TextField
              placeholder="Current password"
              value={currentPass}
              error={errObj.currentPassErr}
              helperText={errObj.currentPassErr ? errObj.currentPassMsg : null}
              onChange={(v) => {
                setCurrentPass(v.target.value);
                setErrObj({
                  ...errObj,
                  currentPassErr: false,
                  currentPassMsg: "",
                });
              }}
              InputProps={{
                endAdornment: isEdge ? null : !passVisible ? (
                  <VisibilityOff
                    style={{ color: "#1F4771", cursor: "pointer" }}
                    onClick={() => {
                      setPasswordVisible(!passVisible);
                    }}
                  />
                ) : (
                  <Visibility
                    style={{ color: "#1F4771", cursor: "pointer" }}
                    onClick={() => {
                      setPasswordVisible(!passVisible);
                    }}
                  />
                ),
              }}
              type={passVisible ? "none" : "password"}
              style={{ marginTop: 10 }}
            />
            <Typography
              className="primarycolor mediumfont"
              style={{ marginTop: 10 }}
            >
              New password
            </Typography>
            <TextField
              placeholder="New password"
              value={newPass}
              type={newPassVisible ? "none" : "password"}
              error={errObj.newPassErr}
              helperText={errObj.newPassErr ? errObj.newPassMsg : null}
              onChange={(v) => {
                setNewPass(v.target.value);
                setErrObj({
                  ...errObj,
                  newPassErr: false,
                  newPassMsg: "",
                });
              }}
              InputProps={{
                endAdornment: isEdge ? null : !newPassVisible ? (
                  <VisibilityOff
                    style={{ color: "#1F4771", cursor: "pointer" }}
                    onClick={() => {
                      setNewPassVisible(!newPassVisible);
                    }}
                  />
                ) : (
                  <Visibility
                    style={{ color: "#1F4771", cursor: "pointer" }}
                    onClick={() => {
                      setNewPassVisible(!newPassVisible);
                    }}
                  />
                ),
              }}
              style={{ marginTop: 10 }}
            />
            <Typography
              className="primarycolor mediumfont"
              style={{ marginTop: 10 }}
            >
              Confirm password
            </Typography>
            <TextField
              placeholder="Confirm Password"
              value={confirmPass}
              type={confirmPassVisible ? "none" : "password"}
              error={errObj.confirmErr}
              helperText={errObj.confirmErr ? errObj.confirmMsg : null}
              onChange={(v) => {
                setConfirmPass(v.target.value);
                setErrObj({
                  ...errObj,
                  confirmErr: false,
                  confirmMsg: "",
                });
              }}
              InputProps={{
                endAdornment: isEdge ? null : !confirmPassVisible ? (
                  <VisibilityOff
                    style={{ color: "#1F4771", cursor: "pointer" }}
                    onClick={() => {
                      setConfirmPassVisible(!confirmPassVisible);
                    }}
                  />
                ) : (
                  <Visibility
                    style={{ color: "#1F4771", cursor: "pointer" }}
                    onClick={() => {
                      setConfirmPassVisible(!confirmPassVisible);
                    }}
                  />
                ),
              }}
              style={{ marginTop: 10 }}
            />
            <Button
              variant="contained"
              onClick={() => {
                checkValidation();
              }}
              style={{ marginTop: 20 }}
              disabled={loader}
            >
              {loader ? (
                <CircularProgress style={{ color: "#fff" }} size={26} />
              ) : (
                "save"
              )}
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export default MChange;
