import React, { useState } from "react";
import { Stepper, Step, StepLabel } from "@mui/material";
import PropTypes from "prop-types";

function CStepper(props) {
  const { data, activeStep } = props;

  return (
    <>
      <Stepper activeStep={activeStep}>
        {data.map((item, index) => {
          return (
            <Step key={index}>
              <StepLabel></StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </>
  );
}

export default CStepper;
