import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  splitViewStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  inputFieldStyle: {
    margin: "10px 0px 25px !important",
  },
  closeBtnStyle: { height: 40, width: 40, cursor: "pointer" },
  modalBtnStyle: { minWidth: "140px !important" },
  modalTitle: {
    "@media (max-width:1024px)": {
      fontSize: "24px !important",
    },
  },
}));

export default useStyles;
