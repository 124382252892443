import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { Grid, Typography } from "@mui/material";
import { AddCircle, Info } from "@mui/icons-material";
import moment from "moment";
import { isArray, isEmpty } from "lodash";
import MEducation from "../Modal/MEducation";
import { useSelector } from "react-redux";
import { color } from "../../../Config/theme";
import Images from "../../../Config/Images";
import useStyles from "../styles";
import { toast } from "react-toastify";
import { Setting } from "../../../Utils/Setting";
import { getAPIProgressData, getApiData } from "../../../Utils/APIHelper";
import ConfirmDialog from "../../Modal/ConfirmDialog";

function Education(props, ref) {
  const { from = "", data = {}, getProfileData = () => null } = props;
  const { token } = useSelector((state) => state.auth);
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [itemData, setItemData] = useState({});

  const header = { Authorization: `Bearer ${token}` };
  const isModify = !isEmpty(itemData);

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [removeBtnLoad, setRemoveBtnLoad] = useState(false);
  const [deleteType, setDeleteType] = useState({});

  useEffect(() => {
    if (!modal) {
      setItemData({});
    }
  }, [modal]);

  useImperativeHandle(ref, () => ({
    handleModal: () => {
      handleModal();
    },
  }));

  function handleModal() {
    setModal(true);
  }

  async function userEducation(data) {
    const datef = data?.startDate
      ? moment(data?.startDate).format("YYYY-MM-DD")
      : "";
    const datet = data?.endDate
      ? moment(data?.endDate).format("YYYY-MM-DD")
      : "";
    setLoader(true);
    const educationData = {
      "CandidateEducation[institute_name]": data?.institution,
      "CandidateEducation[course_title]": data?.courseTitle,
      "CandidateEducation[level]": data?.level,
      "CandidateEducation[result]": data?.result,
      "CandidateEducation[currently_studying]":
        data?.level !== 270 ? (data?.study ? 1 : 0) : "",
      "CandidateEducation[from_date]": datef,
      "CandidateEducation[to_date]": datet,
    };

    if (isModify) {
      educationData["CandidateEducation[id]"] = itemData?.id;
    }

    try {
      let endPoint = Setting.endpoints.employeeAddEducation;
      const response = await getAPIProgressData(
        endPoint,
        "POST",
        educationData,
        header
      );
      if (response?.status) {
        setModal(false);
        getProfileData();
        setLoader(false);
      } else {
        //display error msg here
        toast.error(response?.message);
        setLoader(false);
      }
    } catch (err) {
      console.log("Catch Part", err);
      //display something went wrong error msg here
      err("Something_went_wrong");
      setLoader(false);
    }
  }

  async function deleteEducation() {
    setRemoveBtnLoad(true);
    try {
      let endPoint = `${Setting.endpoints.employeeDeleteEducation}?id=${deleteType?.id}`;
      const response = await getApiData(endPoint, "GET", null, header);
      if (response && response.status && response.status === true) {
        getProfileData();
      } else {
        console.log("err==>>");
        toast.error(response?.message);
      }
      setRemoveBtnLoad(false);
    } catch (err) {
      setRemoveBtnLoad(false);
      console.log("Catch Part", err);
      err("Something_went_wrong");
    }
  }

  return (
    <Grid
      item
      xs={12}
      className={[
        classes.shadowViewStyle,
        classes.paddingStyle,
        classes.marginDiv,
      ]}
    >
      <div id="education" className={classes.splitViewStyle}>
        <Typography className={classes.sectionTitle}>Education</Typography>
        {from === "employee" ? null : (
          <AddCircle
            color={color.primary}
            className={classes.addIcon}
            onClick={() => setModal(true)}
          />
        )}
      </div>
      {isArray(data?.user_education) && data?.user_education.length > 0 ? (
        data?.user_education.map((item) => {
          const missingData =
            isEmpty(item?.course_title) ||
            isEmpty(item?.institute_name) ||
            isEmpty(item?.level) ||
            isEmpty(item?.from_date) ||
            isEmpty(item?.result);
          return (
            <div key={`education_${item.created_at}`}>
              <div
                className={`${classes.splitViewStyle} ${classes.contentDetailContainer}`}
              >
                {Number(item?.level) === 270 ? (
                  <Typography style={{ fontFamily: "PoppinsMedium" }}>
                    No formal education
                  </Typography>
                ) : (
                  <Typography style={{ fontFamily: "PoppinsMedium" }}>
                    {item.course_title || ""}
                  </Typography>
                )}
                {from === "employee" ? null : (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {missingData && Number(item?.level) !== 270 && (
                      <div className={classes.missingDataDiv}>
                        <Info className={classes.infoIconStyle} />
                        <Typography className={classes.sectionDesc}>
                          Missing info
                        </Typography>
                      </div>
                    )}
                    <div
                      className={classes.editBtnStyle}
                      style={{ marginRight: 10 }}
                      onClick={() => {
                        setModal(true);
                        setItemData(item);
                      }}
                    >
                      <img src={Images.edit} style={{ width: 14 }} />
                    </div>
                    <div
                      className={`${classes.editBtnStyle} ${classes.deleteBtnStyle}`}
                      onClick={() => {
                        setConfirmDialog(true);
                        setDeleteType(item);
                      }}
                    >
                      <img src={Images.odelete} style={{ width: 14 }} />
                    </div>
                  </div>
                )}
              </div>
              {Number(item?.level) !== 270 && (
                <>
                  <Typography className={classes.sectionDesc}>
                    {item.institute_name || ""}
                  </Typography>
                  <Typography className={classes.sectionDesc}>
                    {`${
                      item?.from_date &&
                      moment(item?.from_date, "YYYY-MM-DD").format(
                        "DD MMM YYYY"
                      )
                    } - ${
                      item?.currently_studying === 0
                        ? item?.to_date &&
                          moment(item?.to_date, "YYYY-MM-DD").format(
                            "DD MMM YYYY"
                          )
                        : "Studying"
                    }`}
                  </Typography>
                  {/* <Typography className={classes.sectionDesc}>
                {item.qualification || ""}
              </Typography> */}
                  <Typography className={classes.sectionDesc}>
                    {`${item.course_title || ""} - ${item.result || ""}`}
                  </Typography>
                </>
              )}
            </div>
          );
        })
      ) : (
        <Typography className={classes.sectionDesc}>
          {from === "employee"
            ? "-"
            : "Put your hard-won qualifications to good use."}
        </Typography>
      )}
      <MEducation
        visible={modal}
        handleModal={() => setModal(false)}
        onSave={(data) => {
          userEducation(data);
        }}
        itemData={itemData}
        loader={loader}
      />
      <ConfirmDialog
        title={`Are you sure you want to delete this education?`}
        visible={confirmDialog}
        handleModal={(bool) => {
          if (bool) {
            deleteEducation();
          } else {
            setConfirmDialog(false);
            setDeleteType({});
          }
        }}
        btnLoad={removeBtnLoad}
      />
    </Grid>
  );
}
export default forwardRef(Education);
