import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Modal,
  Button,
  useTheme,
  useMediaQuery,
  CircularProgress,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { isEdge, isAndroid } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import authActions from "../../../Redux/reducers/auth/actions";
import { Setting } from "../../../Utils/Setting";
import FBLoginButton from "../../Social_Login/FBLoginButton";
import GoogleLoginButton from "../../Social_Login/GoogleLoginButton";
import { getAPIProgressData } from "../../../Utils/APIHelper";
import useStyles from "../styles";
// import LinkedinLoginButton from "../../Social_Login/LinkedinLoginButton";

const errorObj = {
  emailErr: false,
  passwordErr: false,
  emailMsg: "",
  passwordMsg: "",
};

function MLogin(props) {
  const {
    visible = false,
    handleModal = () => null,
    forgotClick = () => null,
    loginClick = () => null,
    isEmployerHome = false,
  } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isKeyboardOpen = useDetectKeyboardOpen();
  const { useruuid } = useSelector((state) => state.auth);
  const { setUserData, setToken } = authActions;

  const emailRegex = Setting.JS_Regex.email_Regex;
  const passRegex = Setting.JS_Regex.pwd1_Regex;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errObj, setErrObj] = useState(errorObj);
  const [loginLoader, setLoginLoader] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    resetFormData();
  }, [visible]);

  const style = {
    position: "absolute",
    top: isKeyboardOpen && isAndroid ? "100%" : "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 300 : 330,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
  };

  // this function for reset form data
  function resetFormData(type = "") {
    setEmail("");
    setPassword("");
    setErrObj(errorObj);
    if (type === "close" && handleModal) {
      handleModal();
    }
  }

  function checkValidation() {
    const error = { ...errObj };
    let valid = true;

    if (isEmpty(email)) {
      valid = false;
      error.emailErr = true;
      error.emailMsg = "Please enter email address";
    } else if (!emailRegex.test(email)) {
      valid = false;
      error.emailErr = true;
      error.emailMsg = "Please enter valid email";
    } else if (isEmpty(password)) {
      valid = false;
      error.passwordErr = true;
      error.passwordMsg = "Please enter Password";
    }
    // else if (!passRegex.test(password)) {
    //   valid = false;
    //   error.passwordErr = true;
    //   error.passwordMsg =
    //   "Password must be 8 character long with upper,lower case one special chacter & number";
    // }

    setErrObj(error);

    if (valid) {
      userLogin({
        email: email,
        password: password,
      });
    }
  }

  async function userLogin(data) {
    setLoginLoader(true);
    const loginData = {
      "LoginForm[email]": data?.email,
      "LoginForm[password]": data?.password,
      "LoginForm[user_type]": isEmployerHome ? "employer" : "user",
      "LoginForm[uuid]": useruuid,
    };

    try {
      let endPoint = Setting.endpoints.login;
      const response = await getAPIProgressData(
        endPoint,
        "POST",
        loginData,
        true
      );
      if (response?.status) {
        const dd = response?.data;
        const link_url = isEmployerHome
          ? "/employer/company"
          : "/candidate/profile";
        dispatch(setToken(response?.data?.access_token || ""));
        navigate(link_url);
        dispatch(setUserData(dd));
        setLoginLoader(false);
        loginClick();
        toast.success("Logged in successfully");
      } else {
        //display error msg here
        toast.error(response?.message);
        setLoginLoader(false);
      }
    } catch (err) {
      console.log("Catch Part", err);
      //display something went wrong error msg here
      toast.error("Something_went_wrong");
      setLoginLoader(false);
    }
  }

  return (
    <Modal
      open={visible}
      onClose={() => resetFormData("close")}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      style={{ overflowY: "scroll" }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <div className={classes.splitViewStyle}>
            <Typography variant="subtitle1" component="h2">
              Log In
            </Typography>
            <TextField
              placeholder="Email"
              value={email}
              error={errObj.emailErr}
              onChange={(v) => {
                setEmail(v.target.value);
                setErrObj({ ...errObj, emailErr: false, emailMsg: "" });
              }}
              type="text"
              helperText={errObj.emailErr ? errObj.emailMsg : null}
              style={{ marginTop: 10 }}
            />
            <TextField
              placeholder="Password"
              value={password}
              error={errObj.passwordErr}
              onChange={(v) => {
                setPassword(v.target.value);
                setErrObj({ ...errObj, passwordErr: false, passwordMsg: "" });
              }}
              type={passwordVisible ? "none" : "password"}
              autoComplete="new-password"
              style={{ marginTop: 10 }}
              helperText={errObj.passwordErr ? errObj.passwordMsg : null}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  ev.preventDefault();
                  checkValidation();
                }
              }}
              InputProps={{
                endAdornment: isEdge ? null : !passwordVisible ? (
                  <VisibilityOff
                    style={{ color: "#1F4771", cursor: "pointer" }}
                    onClick={() => {
                      setPasswordVisible(!passwordVisible);
                    }}
                  />
                ) : (
                  <Visibility
                    style={{ color: "#1F4771", cursor: "pointer" }}
                    onClick={() => {
                      setPasswordVisible(!passwordVisible);
                    }}
                  />
                ),
              }}
            />
            <div
              style={{ display: "flex" }}
              onClick={() => {
                forgotClick();
              }}
            >
              <Typography
                className="primarycolor regulerfont"
                style={{ cursor: "pointer", marginTop: 8 }}
              >
                Forgot Password?
              </Typography>
            </div>
            <Button
              variant="contained"
              style={{ marginTop: 14 }}
              onClick={() => {
                checkValidation();
              }}
              disabled={loginLoader}
            >
              {loginLoader ? (
                <CircularProgress style={{ color: "#fff" }} size={26} />
              ) : (
                "Log in"
              )}
            </Button>
            <div className={classes.divider}>
              <div className={classes.dividerline} />
              <Typography className="primarycolor">OR</Typography>
              <div className={classes.dividerline} />
            </div>

            <GoogleLoginButton
              from="Login"
              onCloseModal={() => {
                handleModal();
              }}
            />
            <FBLoginButton
              from="Login"
              onCloseModal={() => {
                handleModal();
              }}
            />
            {/* <LinkedinLoginButton /> */}

            <Typography
              className="primarycolor regulerfont"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 50,
              }}
            >
              Don’t have a Direct Expertise account?{" "}
              <Typography
                className="smallsemiboldfont"
                style={{ cursor: "pointer", width: 70 }}
                onClick={() => {
                  if (isEmployerHome) {
                    resetFormData("close");
                  } else {
                    navigate("/candidate/signup");
                    resetFormData("close");
                  }
                }}
              >
                <u>Sign up</u>
              </Typography>
            </Typography>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export default MLogin;
