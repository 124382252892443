import React, { useEffect, useState } from "react";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Modal,
  Button,
  useMediaQuery,
  useTheme,
  CircularProgress,
  TextField,
  Grid,
  Rating,
} from "@mui/material";
import useStyles from "../styles";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import { Setting } from "../../../Utils/Setting";
import { getAPIProgressData } from "../../../Utils/APIHelper";
import authActions from "../../../Redux/reducers/auth/actions";
import { useDispatch } from "react-redux";

function MRate(props) {
  const {
    visible = false,
    handleModal = () => null,
    type = "",
    postId = "",
    ratings = {},
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { setRating } = authActions;
  const [rating, setRatings] = useState("");
  const [rateLoader, setRateLoader] = useState("");
  const [description, setDescription] = useState("");

  const [descriptionErr, setDescriptionErr] = useState(false);

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const isShow = !isEmpty(ratings);

  useEffect(() => {
    if (visible && isShow) {
      setRatings(ratings?.rating);
      setDescription(ratings?.description);
    }
    !visible && resetFormData();
  }, [visible]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 300 : 330,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
  };

  function checkValidation() {
    let valid = true;

    if (isEmpty(rating.toString()) && type !== "report") {
      valid = false;
      toast.error("Please give rating first");
    }
    if (isEmpty(description)) {
      valid = false;
      setDescriptionErr(true);
    }

    if (valid) {
      type === "report" ? reportjob() : rateUS();
      setDescriptionErr(false);
    }
  }

  async function rateUS() {
    setRateLoader(true);
    const data = {
      "SystemReview[rating]": rating,
      "SystemReview[description]": description,
    };

    try {
      let endPoint = Setting.endpoints.rateUs;
      const response = await getAPIProgressData(endPoint, "POST", data, true);
      if (response?.status) {
        dispatch(setRating(response?.data));
        toast.success(response?.message);
        setRateLoader(false);
        resetFormData("close");
      } else {
        //display error msg here
        toast.error(response?.message);
        setRateLoader(false);
      }
    } catch (err) {
      console.log("Catch Part", err);
      //display something went wrong error msg here
      toast.error("Something_went_wrong");
      setRateLoader(false);
    }
  }

  async function reportjob() {
    setRateLoader(true);
    const data = {
      "ReportJob[job_post_id]": postId,
      "ReportJob[report]": description,
    };

    try {
      let endPoint = Setting.endpoints.employeeJobReport;
      const response = await getAPIProgressData(endPoint, "POST", data, true);
      if (response?.status) {
        toast.success(response?.message);
        setRateLoader(false);
        resetFormData("close");
      } else {
        //display error msg here
        toast.error(response?.message);
        setRateLoader(false);
      }
    } catch (err) {
      console.log("Catch Part", err);
      //display something went wrong error msg here
      toast.error("Something_went_wrong");
      setRateLoader(false);
    }
  }

  // this function for reset form data
  function resetFormData(type = "") {
    setRatings("");
    setDescription("");
    if (type === "close" && handleModal) {
      handleModal();
    }
  }

  return (
    <Modal
      open={visible}
      onClose={() => resetFormData("close")}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style} className={classes.boxmain}>
          <Grid container direction="column">
            <Typography
              className="primarycolor fontsize2"
              style={{ marginBottom: 14 }}
            >
              {type === "report" ? "Report Job" : "Rate us"}
            </Typography>
            {type !== "report" && (
              <Grid item>
                <Rating
                  name="half-rating"
                  defaultValue={2.5}
                  precision={0.5}
                  readOnly={isShow}
                  value={rating}
                  onChange={(event, newValue) => {
                    setRatings(newValue);
                  }}
                  style={{ fontSize: 40 }}
                />
              </Grid>
            )}
            <Grid item style={{ marginTop: 20 }}>
              {type !== "report" && (
                <Typography className="mediumsemiboldfont primarycolor">
                  Description
                </Typography>
              )}
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Description"
                className={classes.inputFieldStyle}
                multiline
                minRows={6}
                maxRows={6}
                error={descriptionErr}
                style={{ padding: "0px !important" }}
                helperText={descriptionErr ? "Please enter description" : null}
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                  setDescriptionErr(false);
                }}
                InputProps={{
                  readOnly: isShow ? true : false,
                }}
              />
            </Grid>
            {isEmpty(ratings) && (
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  checkValidation();
                }}
                style={{ marginTop: 20 }}
              >
                {rateLoader ? (
                  <CircularProgress style={{ color: "#fff" }} size={24} />
                ) : (
                  "Submit"
                )}
              </Button>
            )}
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
}

export default MRate;
