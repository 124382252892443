import { makeStyles } from "@mui/styles";
import { color } from "../../Config/theme";

const useStyles = makeStyles((theme) => ({
  splitViewStyle: {
    display: "flex",
    flexDirection: "column",
  },
  divider: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 14,
    marginBottom: 10,
  },
  dividerline: {
    borderBottom: "1px solid #1f4771",
    width: "44%",
  },
  sectionTitle: {
    color: color.primary,
    fontFamily: "PoppinsSemiBold !important",
    fontSize: "18px !important",
  },
  sectionDesc: {
    color: color.black,
    fontFamily: "PoppinsRegular !important",
    fontSize: "14px !important",
    margin: "5px 0px !important",
  },
  tagViewStyle: {
    position: "relative",
    border: "0.5px solid #1F4771",
    background: "#F5F9FF 0% 0% no-repeat padding-box",
    borderRadius: 4,
    padding: "10px 15px",
    marginRight: 16,
    marginBottom: 16,
  },
  skillDivStyle: { display: "flex", flexWrap: "wrap" },
}));

export default useStyles;
