import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Switch,
  Tab,
  Tabs,
  Typography,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import { flattenDeep, isArray, isEmpty, isNull } from "lodash";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/styles";
import { useSelector } from "react-redux";
import { Setting } from "../../../Utils/Setting";
import MyJob from "../../../Components/Jobs/MyJob";
import EmployeeOffer from "../../../Components/Jobs/EmployeeOffer";
import Message from "../../../Components/Jobs/Message";
import { color } from "../../../Config/theme";
import useStyles from "./styles";
import { getApiData } from "../../../Utils/APIHelper";
import JobDetail from "../../../Components/Jobs/JobDetail";

export default function Jobs() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { token, candidateProfile } = useSelector((state) => state.auth);
  const [currentTab, setCurrentTab] = useState("1");
  const [jobType, setJobType] = useState(false);
  const [jobs, setJobs] = useState({
    data: [],
    pageData: {},
  });
  const [newJobs, setNewJobs] = useState({
    data: [],
    pageData: {},
  });
  const [offer, setOffer] = useState({
    data: [],
    pageData: {},
  });
  const [message, setMessage] = useState({
    data: [],
    pageData: {},
  });
  const [pageLoad, setPageLoad] = useState(true);
  const [loadMoreBtn, setLoadMoreBtn] = useState(false);

  const CustomSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: "58px !important",
    height: "36px !important",
    padding: "6px !important",
    "& .MuiSwitch-switchBase": {
      padding: 1,
      left: 5,
      top: 4,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(21px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: color.primary,
          opacity: 1,
          border: "none",
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: color.primary,
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      color: "#d4dbe5",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      border: "1px solid #d4dbe5",
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
  }));

  useEffect(() => {
    document.title = Setting.page_name.EMPLOYER_JOBS;
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setPageLoad(true);
    setJobs({ data: [], pageData: {} });
    setMessage({ data: [], pageData: {} });
    setOffer({ data: [], pageData: {} });
    setTimeout(() => {
      getJobsList();
      getOfferList();
      getMessageList();
    }, 200);
  }, [jobType]);

  useEffect(() => {
    getMessageList();
    getOfferList();
  }, []);

  useEffect(() => {
    getNewJobList();
  }, [candidateProfile]);

  // this function for get jobs list
  async function getJobsList(type = null, key = "") {
    const status = !isNull(type) ? type : jobType ? 0 : 1;
    let page = Number(jobs?.pageData?.currentPage) || 1;
    if (key === "load") {
      page = page + 1;
    }
    try {
      const response = await getApiData(
        `${Setting.endpoints.employerJobList}?job_status=${status}&page=${page}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (!isEmpty(response.data) && isArray(response?.data.rows)) {
          const newData =
            key === "load"
              ? flattenDeep([...jobs?.data, response?.data?.rows])
              : response?.data?.rows;
          setJobs({
            data: newData || [],
            pageData: response?.data?.pagination,
          });
        }
      }
      // getOfferList();
      setPageLoad(false);
      setLoadMoreBtn(false);
    } catch (error) {
      console.log("error ===>>>", error);
      setPageLoad(false);
      setLoadMoreBtn(false);
    }
  }

  // this function for get offer list
  async function getOfferList(key = "") {
    let page = Number(message?.pageData?.currentPage) || 1;
    if (key === "load") {
      page = page + 1;
    }
    try {
      const response = await getApiData(
        `${Setting.endpoints.employerOffer}?page=${page}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (!isEmpty(response.data) && isArray(response?.data.rows)) {
          const newData =
            key === "load"
              ? flattenDeep([...offer?.data, response?.data?.rows])
              : response?.data?.rows;
          setOffer({
            data: newData || [],
            pageData: response?.data?.pagination,
          });
        }
      }
      // getMessageList();
      setLoadMoreBtn(false);
    } catch (error) {
      console.log("error ===>>>", error);
      setLoadMoreBtn(false);
    }
  }

  // this function for get message list
  async function getMessageList(key = "") {
    // setPageLoad(true);
    let page = Number(message?.pageData?.currentPage) || 1;
    if (key === "load") {
      page = page + 1;
    }
    try {
      const response = await getApiData(
        `${Setting.endpoints.employeeMessageList}?page=${page}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (!isEmpty(response.data) && isArray(response?.data.rows)) {
          const newData =
            key === "load"
              ? flattenDeep([...message?.data, response?.data?.rows])
              : response?.data?.rows;
          setMessage({
            data: newData || [],
            pageData: response?.data?.pagination,
          });
        }
      }
      // setPageLoad(false);
      setLoadMoreBtn(false);
    } catch (error) {
      console.log("error ===>>>", error);
      // setPageLoad(false);
      setLoadMoreBtn(false);
    }
  }

  // this function redirects to post new job screen
  function postJob() {
    navigate("/employer/jobs/post-job");
  }

  // this function for get New job list
  async function getNewJobList() {
    const sector_id = !isEmpty(candidateProfile) && candidateProfile?.sector_id;
    try {
      const response = await getApiData(
        `${Setting.endpoints.employeeAllJob}?sector_id=${sector_id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (!isEmpty(response.data) && isArray(response?.data.rows)) {
          setNewJobs({
            data: response?.data?.rows || [],
            pageData: response?.data?.pagination,
          });
        }
      }
    } catch (error) {
      console.log("error ===>>", error);
    }
  }

  const loadMoreData =
    currentTab === "1"
      ? jobs?.pageData?.isMore
      : currentTab === "2"
      ? offer?.pageData?.isMore
      : currentTab === "3"
      ? message?.pageData?.isMore
      : false;

  return (
    <Grid container className={classes.container}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
        <Tabs
          value={currentTab}
          onChange={(e, val) => setCurrentTab(val)}
          indicatorColor="secondary"
          className={classes.tabStyle}
        >
          <Tab label="My Jobs" value="1" />
          <Tab label="Offers" value="2" />
          <Tab label="Messages" value="3" />
        </Tabs>
      </Box>
      <div className={classes.postJobDiv}>
        <Button variant="contained" color="primary" onClick={postJob}>
          Post New Job
        </Button>
        {currentTab === "1" ? (
          <div className={classes.switchContainer}>
            <Typography
              className={`${classes.switchTextStyle} ${
                !jobType ? classes.regularFont : ""
              }`}
              style={{ marginRight: 10 }}
            >
              Live Jobs
            </Typography>
            <FormControlLabel
              control={
                <CustomSwitch
                  checked={jobType}
                  onChange={() => setJobType(!jobType)}
                />
              }
              label=""
              style={{ marginRight: 0 }}
            />
            <Typography
              className={`${classes.switchTextStyle} ${
                jobType ? classes.regularFont : ""
              }`}
            >
              Hidden Jobs
            </Typography>
          </div>
        ) : null}
      </div>
      <div className={classes.listContainerStyle}>
        {currentTab === "1" && isArray(jobs?.data) && jobs?.data?.length > 0 ? (
          jobs?.data.map((item) => {
            return (
              <MyJob
                key={`job_${item?.id}`}
                data={item}
                isHidden={jobType}
                jobType={jobType}
                updateList={(type) => {
                  setPageLoad(true);
                  setJobs({ data: [], pageData: {} });
                  setTimeout(() => {
                    getJobsList(type);
                  }, 200);
                }}
              />
            );
          })
        ) : pageLoad && currentTab === "1" ? (
          <div className={classes.loaderView}>
            <CircularProgress size={50} />
          </div>
        ) : (
          currentTab === "1" && (
            <div className={classes.noDataView}>
              <Typography className={classes.sectionTitle}>
                No Jobs available
              </Typography>
            </div>
          )
        )}

        {currentTab === "2" && isArray(offer.data) && offer.data.length > 0 ? (
          offer.data.map((item) => {
            return (
              <EmployeeOffer
                data={item}
                getOfferData={() => {
                  getOfferList();
                }}
              />
            );
          })
        ) : pageLoad && currentTab === "2" ? (
          <div className={classes.loaderView}>
            <CircularProgress size={50} />
          </div>
        ) : (
          currentTab === "2" && (
            <div className={classes.noDataView}>
              <Typography className={classes.sectionTitle}>
                No Offers available
              </Typography>
            </div>
          )
        )}
        {currentTab === "3" &&
        isArray(message?.data) &&
        message?.data.length > 0 ? (
          message?.data.map((item, index) => {
            return (
              <Message
                key={`message_${item?.created_at || "_"}_${index}`}
                data={item}
              />
            );
          })
        ) : pageLoad && currentTab === "3" ? (
          <div className={classes.loaderView}>
            <CircularProgress size={50} />
          </div>
        ) : (
          currentTab === "3" && (
            <div className={classes.noDataView}>
              <Typography className={classes.sectionTitle}>
                No Messages
              </Typography>
            </div>
          )
        )}
        {loadMoreData && (
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={3}>
              <Button
                variant={loadMoreBtn ? "outlined" : "contained"}
                color="primary"
                fullWidth
                onClick={() => {
                  setLoadMoreBtn(true);
                  if (currentTab === "1") {
                    getJobsList(null, "load");
                  } else if (currentTab === "2") {
                    getOfferList("load");
                  } else if (currentTab === "3") {
                    getMessageList("load");
                  }
                }}
                disabled={loadMoreBtn}
              >
                {loadMoreBtn ? <CircularProgress size={28} /> : "Load More"}
              </Button>
            </Grid>
          </Grid>
        )}
      </div>
    </Grid>
  );
}
