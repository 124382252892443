import React, { useRef, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { AddCircle, ArrowBack, ArrowForward } from "@mui/icons-material";
import Slider from "react-slick";
import { isArray } from "lodash";
import { htmlContent } from "../../../Config/Static_Data";
import { color } from "../../../Config/theme";
import Images from "../../../Config/Images";
import useStyles from "../styles";
import MCulture from "../Modal/MCulture";

function CCulture(props) {
  const {
    isCandidate = false,
    from = "",
    data = {},
    updateData = () => null,
  } = props;
  const classes = useStyles();
  const sliderRef = useRef();
  const [cultureModal, setCultureModal] = useState({ bool: false, type: "" });

  const setting = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 491,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          infinite: true,
        },
      },
    ],
  };

  return (
    <Grid
      item
      xs={12}
      className={[
        classes.shadowViewStyle,
        classes.paddingStyle,
        classes.marginDiv,
      ]}
    >
      <div
        id="cculture"
        className={`${classes.splitViewStyle} ${classes.mb20}`}
      >
        <Typography className={classes.sectionTitle}>Culture</Typography>
        {isCandidate || from === "preview" ? null : data?.company_culture !==
          "" ? (
          <div style={{ display: "flex" }}>
            <Button
              variant="contained"
              className={classes.addImgButton}
              onClick={() => {
                setCultureModal({ bool: true, type: "image" });
              }}
            >
              Add Company Images
            </Button>
            <div
              className={classes.editBtnStyle}
              onClick={() => {
                setCultureModal({ bool: true, type: "culture" });
              }}
            >
              <img src={Images.edit} style={{ width: 14 }} />
            </div>
          </div>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              variant="contained"
              className={classes.addImageBtn}
              onClick={() => {
                setCultureModal({ bool: true, type: "image" });
              }}
            >
              Add Company Images
            </Button>
            <div
              onClick={() => {
                setCultureModal({ bool: true, type: "culture" });
              }}
            >
              <AddCircle color={color.primary} className={classes.addIcon} />
            </div>
          </div>
        )}
      </div>
      {isCandidate || from === "" || from === "preview" ? (
        <div>
          <div
            style={{ marginBottom: 10 }}
            className={htmlContent().style}
            dangerouslySetInnerHTML={{
              __html: data?.company_culture_text || "",
            }}
          />
          {isArray(data?.company_images) && data?.company_images.length > 0 && (
            <div>
              <div className={classes.splitViewStyle}>
                <Typography style={{ fontFamily: "PoppinsMedium" }}>
                  Company photos
                </Typography>
                {data?.company_images.length > 3 && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      className={classes.socialDiv}
                      onClick={() => {
                        sliderRef.current.slickPrev();
                      }}
                    >
                      <ArrowBack className={classes.arrowStyle} />
                    </div>
                    <div
                      className={classes.socialDiv}
                      onClick={() => {
                        sliderRef.current.slickNext();
                      }}
                    >
                      <ArrowForward className={classes.arrowStyle} />
                    </div>
                  </div>
                )}
              </div>
              <div style={{ margin: "20px 0px" }}>
                {data?.company_images.length > 3 ? (
                  <Slider ref={sliderRef} {...setting}>
                    {data?.company_images.map((ii) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          key={`Slide_${ii.id}_${ii.company_id}`}
                        >
                          <img src={ii.image} className={classes.sliderImg} />
                        </Grid>
                      );
                    })}
                  </Slider>
                ) : (
                  <div className={classes.customImgSlider}>
                    {data?.company_images.map((ii) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          key={`Slide_${ii.id}_${ii.company_id}`}
                        >
                          <img src={ii.image} className={classes.sliderImg} />
                        </Grid>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <Typography className={classes.sectionDesc}>
          Add your culture's beliefs, diversity statement, photos or anything
          else that makes it special.
        </Typography>
      )}
      <MCulture
        visible={cultureModal?.bool}
        type={cultureModal?.type}
        images={data?.company_images || []}
        cultureDetail={data?.company_culture_text || ""}
        companyId={data?.company_id}
        handleModal={(detail, type, key, point) => {
          if (point === "remove") {
            updateData(detail, key);
          }
          if (point !== "remove") {
            setCultureModal({ bool: false, type: "" });
          }
          if (type === "update") {
            updateData(detail, key);
          }
        }}
      />
    </Grid>
  );
}
export default CCulture;
