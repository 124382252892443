import React, { useState } from "react";
import { Typography, Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MOffers from "../../Profile/Modal/MOffers";
import ConfirmDialog from "../../ConfirmDialog";
import useStyles from "../styles";
import { useSelector } from "react-redux";
import { getApiData } from "../../../Utils/APIHelper";
import { Setting } from "../../../Utils/Setting";

function EmployeeOffer(props) {
  const { data = {}, getOfferData = () => {} } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const [visible, setVisible] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);

  const [removeBtnLoad, setRemoveBtnLoad] = useState(false);

  // this function for redirect to profile page
  function viewProfile() {
    navigate("/candidate/profile", {
      state: {
        from: "employee",
        jobId: data?.job_post_id,
        userId: data?.user_info?.candidate_id,
      },
    });
    window.scrollTo(0, 0);
  }

  // this function for remove offer from list
  async function removeOffer() {
    setRemoveBtnLoad(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.employerRemoveOffer}?offer_id=${data?.id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        getOfferData();
        setRemoveBtnLoad(false);
        setConfirmDialog(false);
      }
    } catch (error) {
      console.log("error ===>>", error);
      setRemoveBtnLoad(false);
    }
  }

  return (
    <Grid container className={classes.container}>
      <Grid xs={12} sm={8} md={9}>
        <Grid container>
          <Grid item xs={12} style={{ marginBottom: 15 }}>
            <Typography className={classes.sectionTitle}>
              {data?.title || ""}
            </Typography>
            <Typography style={{ fontFamily: "PoppinsMedium" }}>
              {data?.description || ""}
            </Typography>
          </Grid>
          <Grid xs={12} sm={5} md={4} className={classes.singleDetail}>
            <Typography className={classes.sectionDesc}>Salary</Typography>
            <Typography className={classes.sectionSubDetail}>
              {data?.job_role_type === "p_full_time" ||
              data?.job_role_type === "t_full_time"
                ? data?.charge_per_year
                : data?.job_role_type === "p_part_time" ||
                  data?.job_role_type === "t_part_time"
                ? data?.charge_per_hour
                : data?.job_role_type === "contract"
                ? `${data?.charge_per_hour} per hour, ${data?.charge_per_year} per year`
                : data?.job_role_type === "any"
                ? `${data?.charge_per_hour} per hour, ${data?.charge_per_year} per year`
                : 0}
            </Typography>
          </Grid>
          <Grid xs={12} sm={7} md={8} className={classes.singleDetail}>
            <Typography className={classes.sectionDesc}>
              Made offer to
            </Typography>
            <Typography className={classes.sectionSubDetail}>
              {data?.user_info?.name || ""}{" "}
              <span className={classes.profileLinkStyle} onClick={viewProfile}>
                (Click here to view profile)
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} sm={4} md={3}>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          style={{ marginBottom: 15 }}
          className={classes.respBtn}
          onClick={() => setVisible(true)}
        >
          View / Edit Offer
        </Button>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          className={classes.respBtn}
          onClick={() => setConfirmDialog(true)}
        >
          Remove Candidate
        </Button>
      </Grid>
      <MOffers
        type="edit"
        employerData={data}
        visible={visible}
        handleModal={() => setVisible(false)}
        apiSussecc={() => {
          getOfferData();
          setVisible(false);
        }}
      />
      <ConfirmDialog
        title="Are you sure you want to remove the candidate from offer?"
        visible={confirmDialog}
        handleModal={(bool) => {
          if (bool) {
            removeOffer();
          } else {
            setConfirmDialog(false);
          }
        }}
        btnLoad={removeBtnLoad}
      />
    </Grid>
  );
}

export default EmployeeOffer;
