const prod = process.env.NODE_ENV === "production";
const baseUrl = prod
  ? "https://admin.directexpertise.co.uk/v1/"
  : "https://admin.directexpertise.co.uk/v1/";

const Dev = true;

export const Setting = {
  baseUrl,
  api: baseUrl,

  stripeKey: !prod
    ? "pk_live_51Ko46qFsilK6HZms5TLgyyIbYQn2Ro2SODZxyPqC7M1PaiXYK9EpcFkquXXeituEPlYyqHQIiw5cGE8wPMebxpIJ00mFaufz7W"
    : "pk_test_51Ko46qFsilK6HZmsgThr6DYTNeAWEVoL0TR5kZgu5hAIORnPqff4nOiOu1KXpPQRGWloKhKKCMCtnYwLc3LfOaJD00a0JhRUkx",

  mainURL: Dev
    ? "https://dev.directexpertise.co.uk/"
    : "https://directexpertise.co.uk/",

  FACEBOOK_APP_ID: Dev ? "563057472700749" : "2614034339444082",

  GOOGLE_CLIENT_ID:
    "232985582305-3krv8lsoda60vnv4okp952qgpgo4lahd.apps.googleusercontent.com",
  GOOGLE_CLIENT_SECRET_KEY: "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",

  endpoints: {
    //common
    login: "user/login",
    logout: "user/logout",
    dropdown: "system/dropdown",
    userReviewList: "user/review-list",
    forgot_pass: "user/reset-password",
    new_pass: "user/forgot-password",
    change_password: "user/change-password",
    candidate_signup: "employee/signup",
    employer_signup: "employer/signup",
    email_Verification: "user/verify-email",
    social_sign_up: "user/social-sign-up",
    social_login: "user/social-login",
    employeeRemoveVideoOrPhoto: "employee/remove-video-or-photo",
    rateUs: "user/add-review",

    //candidate profile tab
    employeeAddExperience: "employee/add-experience",
    employeeDeleteExperience: "employee/delete-experience",

    employeeAddEducation: "employee/add-education",
    employeeDeleteEducation: "employee/delete-education",

    employeeSkills: "employee/skills",
    employeeAddSkills: "employee/add-skills",

    employeeProfile: "employee/my-profile",
    editEmployeeProfile: "employee/edit-profile",

    jobQuestionList: "job/question-list",
    jobStoreResult: "job/store-result",

    employeeImportCV: "employee/import-cv",

    employeeAvailablity: "employee/edit-availablity",
    employeeJobType: "employee/edit-job-type",
    employeeAddBio: "employee/add-bio",
    employeeAddLanguage: "employee/add-language",
    employeeEditPhoto: "employee/edit-photo",
    employeeAddProfileVideo: "employee/add-profile-video",

    //candidate preference tab
    employeePreference: "employee/edit-preference",

    //candidate job tab
    employerAllSkill: "employee/get-all-skills",
    employeeMatchingJobs: "employee/matching-jobs",
    employeeInterestedJobList: "employee/intrested-job-list",
    employeeCandidateJobInterest: "employee/candidate-job-intrest",
    employeeMyOffer: "employee/my-offer",
    employeeMessageList: "user/message-list",
    employeeSendMessage: "user/send-message",
    employeeCompanyDetails: "employee/company-details",
    employeeJobReport: "employee/report-job",
    employeeAllJob: "employee/all-jobs",

    //candidate account tab
    employeeCommunication: "employee/commnunication-preference",
    employeeVisibility: "user/change-visibility",
    employeeRemoveAccount: "user/remove-account",

    // employer company
    employerCompanyDetails: "employer/company-details",
    employerDetailsEdit: "employer/edit-details",
    employerUploadCompanyVideo: "employer/upload-company-video",
    employerJobList: "employer/job-list",
    employerAddCoverImage: "employer/add-cover-image",
    employerAddInformation: "employer/add-information",
    employerAddOverview: "employer/add-overview",
    employerAddBenefits: "employer/add-benefits",
    employerAddCulture: "employer/add-culture",
    employerRemoveCompanyImage: "employer/remove-company-image",
    employerUploadCompanyImage: "employer/upload-company-image",
    viewAllCandidates: "employer/view-all-candidates",

    //employer jobs
    employerChangeJobStatus: "employer/change-job-status",
    employerAddJob: "employer/add-job-post",
    employerQuestionList: "employer/my-questionlist",
    employerUpdateQuiz: "employer/update-quiz",
    employerOffer: "employer/my-offer",
    jobGetSortedCandidate: "job/get-sorted-candidate",
    employerRemoveOffer: "employer/remove-offer",
    employerMakeOffer: "employer/make-offer",
    employerUserDetails: "employer/user-details",
    jobCandidateResult: "job/candidate-result",
    employerViewCandidates: "employer/view-candidates",
    employerJobDetail: "employer/job-details",
    employerSkills: "employer/job-skills",

    // payment
    paymentCreateCard: "payment/create-card",
    paymentCardList: "payment/card-list",
    paymentMakePayment: "payment/make-payment",

    //CMS pages
    cmsdetails: "cms/cms-detail",

    employeeUploadCV: "employee/upload-cv",
    employeeUpdateTime: "employee/update-time",
    userVerifyToken: "user/verify-token",

    employeeShortListJobs: "employee/short-list-jobs",
    employeeunsubscribe: "employee/un-subscribe",
  },

  JS_Regex: {
    email_Regex:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    pwd1_Regex: /^.{8,}$/, // /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
    phone_Regex: /^(\+44\s?\d{10}|0044\s?\d{10}|0\s?\d{10})?$/,
    number_Regex:
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
    alphabatic_Regex: /^[A-Za-z]+$/,

    // pwd1_Regex: /(?<!\S)\S.{7,14}(?!\S)/,
  },

  page_name: {
    OFFLINE: "Offline",
    EMPLOYER_ACCOUNT: "Employer | Account",
    EMPLOYER_COMPANY: "Employer | Company",
    EMPLOYER_QUESTION: "Employer | Question",
    EMPLOYER_HOME: "Employer | Home",
    EMPLOYER_JOBS: "Employer | Jobs",
    EMPLOYER_DASHBOARD: "Employer | Dashboard",
    EMPLOYER_MATCHING_JOB: "Employer | Matching Jobs",
    EMPLOYER_POST_JOB: "Employer | Post Job",
    EMPLOYER_VIEW_CANDIDATE: "Employer | View Candidate",

    CANDIDATE_ACCOUNT: "Candidate | Account",
    CANDIDATE_DASHBOARD: "Candidate | Dashboard",
    CANDIDATE_HOME: "Candidate | Home",
    CANDIDATE_JOBS: "Candidate | Jobs",
    CANDIDATE_PREFERENCE: "Candidate | Preferences",
    CANDIDATE_PROFILE: "Candidate | Profile",
    CANDIDATE_SIGN_UP: "Candidate | Sign up",

    RESETPASSWORD: "ResetPassword",
    VERIFICATION: "Verification",
    TERMS: "Terms & Conditions",
    PRIVACY: "Privacy & Policy",
    BROKENPAGE: "BrokenPage",
    CONTACT: "Contact Us",
    ABOUT: "About Us",
    QUIZ: "Quiz",
  },
};
