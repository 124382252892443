import { makeStyles } from "@mui/styles";
import { color } from "../../../../Config/theme";

const useStyles = makeStyles((theme) => ({
  splitViewStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  modalBtnStyle: { minWidth: "140px !important" },
  modalTitle: {
    "@media (max-width:1024px)": {
      fontSize: "24px !important",
    },
    textAlign: "center",
  },
  sectionTitle: {
    color: color.primary,
    fontFamily: "PoppinsSemiBold !important",
    fontSize: "20px !important",
  },
  sectionDesc: {
    color: color.black,
    fontFamily: "PoppinsRegular !important",
    fontSize: "14px !important",
    margin: "5px 0px !important",
  },
}));

export default useStyles;
