import { makeStyles } from "@mui/styles";
import { color } from "../../../Config/theme";

const useStyles = makeStyles((theme) => ({
  paperstyle: {
    borderRadius: 5,
    padding: 20,
    boxShadow: "0px 3px 15px #00000029",
    marginBottom: "20px !important",
  },
  mapContainerStyle: { position: "relative", width: "100%", height: 250 },
}));

export default useStyles;
