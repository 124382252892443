import React, { useEffect, useState } from "react";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Select,
  MenuItem,
  FormControl,
  Modal,
  Button,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Close, SignalWifiStatusbarNull } from "@mui/icons-material";
import { findIndex, isArray, isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAPIProgressData } from "../../../../Utils/APIHelper";
import { Setting } from "../../../../Utils/Setting";
import Images from "../../../../Config/Images";
import useStyles from "../../styles";

function MLanguage(props) {
  const {
    visible = false,
    list = [],
    data = [],
    employer = false,
    onSave = () => null,
    handleModal = () => SignalWifiStatusbarNull,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { token } = useSelector((state) => state.auth);
  const [proficiency, setProficiency] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedLanguageArr, setSelectedLanguageArr] = useState([]);
  const [btnLoad, setBtnLoad] = useState(false);
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 290 : 330,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
    maxHeight: 550,
  };

  useEffect(() => {
    const arr = [];
    if (visible && isArray(data) && data.length > 0) {
      data.map((item) => {
        if (isArray(list) && list.length > 0) {
          list.map((key) => {
            if (
              Number(employer ? item?.value : item.language_id) ===
              Number(key.value)
            ) {
              const obj = { ...key };
              obj.preference = item.preference;
              arr.push(obj);
            }
          });
        }
      });
    }
    setSelectedLanguageArr(arr);
  }, [visible]);

  function checkValidation() {
    let valid = true;

    if (isEmpty(selectedLanguageArr)) {
      valid = false;
      toast.error("Please select language");
    } else if (!isEmpty(selectedLanguage) && isEmpty(proficiency)) {
      valid = false;
      toast.error("Please select proficiency");
    }

    if (valid) {
      if (employer) {
        onSave(selectedLanguageArr);
      } else {
        submitData();
      }
    }
  }

  // this function for remove skill from selected skill
  function removeLanguage(index) {
    const arr = [...selectedLanguageArr];
    arr.splice(index, 1);
    setSelectedLanguageArr(arr);
  }

  // this function for reset form data
  function resetFormData(type = "", data = []) {
    setSelectedLanguage("");
    setProficiency("");
    setSelectedLanguageArr([]);
    if (type === "close" && handleModal) {
      handleModal(data, "close");
    } else if (type === "update" && handleModal) {
      handleModal(data, "update");
    }
  }

  // this function for submit data to server
  async function submitData() {
    if (isArray(selectedLanguageArr) && selectedLanguageArr.length > 0) {
      setBtnLoad(true);
      const obj = {};
      selectedLanguageArr.map((item) => {
        obj[`Language[${item.value}]`] = item.preference;
      });

      try {
        const response = await getAPIProgressData(
          Setting.endpoints.employeeAddLanguage,
          "POST",
          obj,
          { Authorization: `Bearer ${token}` }
        );

        if (response.status) {
          resetFormData("update", response?.data);
        } else {
          toast.error(response.message);
        }
        setBtnLoad(false);
      } catch (error) {
        console.log("error ===>>", error);
        setBtnLoad(false);
      }
    } else {
      toast.error("Please select language");
    }
  }

  return (
    <Modal
      open={visible}
      onClose={() => resetFormData("close")}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <div className={classes.splitViewStyle}>
            <Typography
              variant="subtitle1"
              component="h2"
              className={classes.modalTitle}
            >
              Add Language
            </Typography>
            <img
              src={Images.close}
              className={classes.closeBtnStyle}
              onClick={() => resetFormData("close")}
            />
          </div>
          <div className={classes.formDivStyle}>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.inputFieldStyle}
            >
              <Select
                IconComponent={() => (
                  <img src={Images.angle} className={classes.angleBtnStyle} />
                )}
                displayEmpty
                onChange={(e) => setSelectedLanguage(e.target.value)}
                value={selectedLanguage}
              >
                <MenuItem disabled value="">
                  Select language
                </MenuItem>
                {isArray(list) &&
                  list.length > 0 &&
                  list.map((item) => (
                    <MenuItem key={`language_${item.value}`} value={item}>
                      {item?.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.inputFieldStyle}
            >
              <Select
                IconComponent={() => (
                  <img src={Images.angle} className={classes.angleBtnStyle} />
                )}
                onChange={(e) => {
                  setProficiency(e.target.value);
                  const index = findIndex(
                    selectedLanguageArr,
                    (item) => item.value === selectedLanguage.value
                  );
                  const arr = [...selectedLanguageArr];
                  if (index > -1) {
                    arr[index].value = selectedLanguage.value;
                    arr[index].preference = e.target.value;
                  } else {
                    arr.push({
                      label: selectedLanguage.label,
                      preference: e.target.value,
                      value: selectedLanguage.value,
                    });
                  }
                  setSelectedLanguage("");
                  setProficiency("");
                  setSelectedLanguageArr(arr);
                }}
                value={proficiency}
                displayEmpty
                style={proficiency === "" ? { color: "#A2A2A2" } : {}}
                disabled={selectedLanguage === ""}
              >
                <MenuItem value="" disabled>
                  Proficiency
                </MenuItem>
                <MenuItem value="Beginner">Beginner</MenuItem>
                <MenuItem value="Intermediate">Intermediate</MenuItem>
                <MenuItem value="Proficient">Proficient</MenuItem>
                <MenuItem value="Fluent">Fluent</MenuItem>
                <MenuItem value="Native">Native</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div
            className={`${classes.skillDivStyle} sliderscroll`}
            style={{ maxHeight: 250, paddingTop: 10 }}
          >
            {isArray(selectedLanguageArr) &&
              selectedLanguageArr.length > 0 &&
              selectedLanguageArr.map((item, index) => (
                <div key={index} className={classes.tagViewStyle}>
                  <Typography variant="body2" style={{ color: "#5A5A5A" }}>
                    {`${item?.label} - ${item.preference}` || ""}
                  </Typography>
                  <div
                    className={classes.closeIconDivStyle}
                    onClick={() => removeLanguage(index)}
                  >
                    <Close className={classes.closeIconStyle} />
                  </div>
                </div>
              ))}
          </div>
          <div className={classes.splitViewStyle}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.modalBtnStyle}
              onClick={resetFormData}
              disabled={btnLoad}
            >
              Reset
            </Button>
            <Button
              variant={btnLoad ? "outlined" : "contained"}
              color="primary"
              className={classes.modalBtnStyle}
              onClick={() => {
                checkValidation();
              }}
              disabled={btnLoad}
            >
              {btnLoad ? <CircularProgress size={28} /> : "Save"}
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
export default MLanguage;
