import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Grid,
  Modal,
  Button,
  useTheme,
  useMediaQuery,
  TextField,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import useStyles from "../../styles";
import Images from "../../../../Config/Images";
import { Setting } from "../../../../Utils/Setting";

const errorObj = {
  firstErr: false,
  lastErr: false,
  emailErr: false,
  phoneErr: false,
  firstMsg: "",
  lastMsg: "",
  emailMsg: "",
  phoneMsg: "",
};

function MUserDetail(props) {
  const {
    modalSaveBtnLoader = false,
    visible = false,
    handleModal = () => null,
    socialUserInfo = {},
    onSignUpAction = () => null,
    from = "",
  } = props;

  const [errObj, setErrObj] = useState(errorObj);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const email_regex = Setting.JS_Regex.email_Regex;
  const number_Regex = Setting.JS_Regex.number_Regex;
  const isEmployer = from === "employer";

  useEffect(() => {
    setFirstName(socialUserInfo?.first_name);
    setEmail(socialUserInfo?.email);
  }, [socialUserInfo]);

  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 300 : 330,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
  };

  function resetFormData(type = "") {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setErrObj(errorObj);
    if (type === "close" && handleModal) {
      handleModal();
    }
  }

  function checkValidation() {
    const error = { ...errObj };
    let valid = true;

    if (isEmpty(firstName)) {
      valid = false;
      error.firstErr = true;
      error.firstMsg = isEmployer
        ? "Please enter company name"
        : "Please enter first name";
    }
    if (isEmpty(lastName) && !isEmployer) {
      valid = false;
      error.lastErr = true;
      error.lastMsg = "Please enter last name";
    }
    if (isEmpty(email)) {
      valid = false;
      error.emailErr = true;
      error.emailMsg = "Please enter email";
    } else if (!email_regex.test(email)) {
      valid = false;
      error.emailErr = true;
      error.emailMsg = "Please enter valid email";
    }
    if (isEmpty(phone)) {
      valid = false;
      error.phoneErr = true;
      error.phoneMsg = "Please enter phone number";
    } else if (phone.length < 10 || !number_Regex.test(phone)) {
      valid = false;
      error.phoneErr = true;
      error.phoneMsg = "Please enter valid phone number";
    }

    // else if (!phone_Regex.test(phone)) {
    //   valid = false;
    //   error.phoneErr = true;
    //   error.phoneMsg = "Number must be in +44 XXXXXXXXXX format";
    // }

    setErrObj(error);

    if (valid) {
      onSignUpAction({
        firstName,
        lastName,
        email,
        phone,
      });
    }
  }

  return (
    <Modal
      open={visible}
      onClose={() => resetFormData("close")}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <div className={classes.splitViewStyle} style={{ marginBottom: 20 }}>
            <Typography variant="subtitle1" component="h2">
              Add Details
            </Typography>
            <img
              alt="close"
              src={Images.close}
              className={classes.closeBtnStyle}
              onClick={() => resetFormData("close")}
            />
          </div>
          <div className="sliderscroll">
            <Typography
              className="primarycolor mediumfont"
              style={{ marginBottom: 8, marginTop: 8 }}
            >
              {isEmployer ? "Company name" : "First name"}
            </Typography>
            <TextField
              placeholder={isEmployer ? "Company name*" : "First name*"}
              value={firstName}
              error={errObj.firstErr}
              helperText={errObj.firstErr ? errObj.firstMsg : null}
              onChange={(v) => {
                setFirstName(v.target.value);
                setErrObj({ ...errObj, firstErr: false, firstMsg: "" });
              }}
              style={{ width: "100%", marginBottom: 8 }}
            />
            {isEmployer ? null : (
              <>
                <Typography
                  className="primarycolor mediumfont"
                  style={{ marginBottom: 8, marginTop: 8 }}
                >
                  Last name
                </Typography>
                <TextField
                  placeholder="Last name*"
                  value={lastName}
                  error={errObj.lastErr}
                  helperText={errObj.lastErr ? errObj.lastMsg : null}
                  onChange={(v) => {
                    setLastName(v.target.value);
                    setErrObj({ ...errObj, lastErr: false, lastMsg: "" });
                  }}
                  style={{ width: "100%", marginBottom: 8 }}
                />
              </>
            )}
            <Typography
              className="primarycolor mediumfont"
              style={{ marginBottom: 8, marginTop: 8 }}
            >
              Email
            </Typography>
            <TextField
              placeholder="Email*"
              value={email}
              error={errObj.emailErr}
              helperText={errObj.emailErr ? errObj.emailMsg : null}
              onChange={(v) => {
                setEmail(v.target.value);
                setErrObj({ ...errObj, emailErr: false, emailMsg: "" });
              }}
              style={{ width: "100%", marginBottom: 8 }}
            />
            <Typography
              className="primarycolor mediumfont"
              style={{ marginBottom: 8, marginTop: 8 }}
            >
              Phone
            </Typography>
            <TextField
              placeholder="Phone*"
              value={phone}
              inputProps={{
                maxLength: 10,
              }}
              error={errObj.phoneErr}
              helperText={errObj.phoneErr ? errObj.phoneMsg : null}
              onChange={(v) => {
                setPhone(v.target.value);
                setErrObj({ ...errObj, phoneErr: false, phoneMsg: "" });
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+44</InputAdornment>
                ),
              }}
              style={{ width: "100%", marginBottom: 8 }}
            />
          </div>
          <div
            style={{
              marginTop: 20,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid xs={12}>
              <Button
                variant="contained"
                style={{ width: "100%" }}
                onClick={
                  modalSaveBtnLoader
                    ? null
                    : () => {
                        checkValidation();
                      }
                }
              >
                {modalSaveBtnLoader ? (
                  <CircularProgress
                    style={{
                      width: 15,
                      height: 15,
                      color: "#FFFFFF",
                    }}
                  />
                ) : (
                  "SAVE"
                )}
              </Button>
            </Grid>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export default MUserDetail;
