import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { HighlightOff, Menu } from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { Grid, IconButton, Typography } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import _, { isEmpty } from "lodash";
import useStyles from "./styles";
import { Setting } from "../../Utils/Setting";
import Images from "../../Config/Images";
import { color } from "../../Config/theme";
import { isUserLogin } from "../../Utils/CommonFunctions";
import authActions from "../../Redux/reducers/auth/actions";
import MLogin from "../Modal/LoginModal";
import MForgot from "../Modal/ForgotModal";
import { getApiData } from "../../Utils/APIHelper";
import { toast } from "react-toastify";
import {
  employer_dashboard_menu_link,
  candidate_dashboard_menu_link,
} from "../../Config/Static_Data";
import { isMobile, isTablet } from "react-device-detect";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const { clearAllData, setBackAction } = authActions;

function Header(props) {
  const { isCandidate } = props;
  const Current_Url = window.location.href;

  const classes = useStyles();
  const location = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLogin = isUserLogin();
  const { userdata, useruuid, token, isBack } = useSelector(
    (state) => state.auth
  );

  const header = { Authorization: `Bearer ${token}` };

  const userRole = (!isEmpty(userdata) && userdata?.role) || "";

  const [loginModal, setLoginModal] = useState(false);
  const [forgotModal, setForgotModal] = useState(false);

  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down(500));
  const isAddPadding = useMediaQuery(theme.breakpoints.down(1260));

  const [open, setOpen] = React.useState(false);

  const isEmployerHome = Current_Url.includes("employer/home");

  useEffect(() => {
    const interval = setInterval(() => {
      if (isLogin && userRole === "user" && token !== "") {
        updateLoginTime();
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [userRole, isLogin, token]);

  useEffect(() => {
    LoginSignUpMenu();
  }, [isBack]);

  // this function for update login time to server
  async function updateLoginTime() {
    try {
      const response = getApiData(
        Setting.endpoints.employeeUpdateTime,
        "GET",
        {},
        {
          Authorization: `Bearer ${token}`,
        }
      );
    } catch (error) {
      console.log("error ===>>>", error);
    }
  }

  async function userLogout() {
    try {
      let endPoint = `${Setting.endpoints.logout}?uuid=${useruuid}`;
      const response = await getApiData(endPoint, "GET", null, header);
      dispatch(clearAllData());
      navigate("/");
      // if (response && response.status && response.status === true) {
      //   // toast.success(response?.message);
      // } else {
      //   console.log("err==>>");
      //   // toast.error(response?.message);
      // }
    } catch (err) {
      console.log("Catch Part", err);
      toast.error("Something_went_wrong");
    }
  }

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  function renderLoginModal() {
    return (
      <MLogin
        visible={loginModal}
        handleModal={() => {
          setLoginModal(false);
        }}
        forgotClick={() => {
          setLoginModal(false);
          setForgotModal(true);
        }}
        loginClick={() => {
          setLoginModal(false);
        }}
        isEmployerHome={isEmployerHome}
      />
    );
  }

  function renderForgotModal() {
    return (
      <MForgot
        visible={forgotModal}
        handleModal={() => {
          setForgotModal(false);
        }}
      />
    );
  }
  // Render Toggle Menu Only for Mobile Devices
  function renderToggleMenu(isDashboard = false) {
    let ary = [
      {
        id: 1,
        title: "Sign Up",
        link: isCandidate ? "/candidate/signup" : "/",
      },
      {
        id: 2,
        title: "Log In",
        link: "",
      },
      {
        id: 3,
        title: isEmployerHome ? "FOR CANDIDATE" : "FOR EMPLOYER",
        link: isEmployerHome ? "/" : "/employer/home",
      },
    ];

    if (isDashboard) {
      if (isCandidate) {
        ary = candidate_dashboard_menu_link;
      } else {
        ary = employer_dashboard_menu_link;
      }
    }

    return (
      <React.Fragment key={"top"}>
        <Menu onClick={toggleDrawer(true)} />
        <SwipeableDrawer
          anchor={"top"}
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          <Box
            sx={{ width: "auto" }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <List>
              {ary.map((obj, index) => {
                if (isDashboard) {
                  return (
                    <ListItem key={index}>
                      <NavLink to={obj?.link} className={classes.link_style}>
                        <Typography className={classes.menu_title_style}>
                          {_.toUpper(obj?.title)}
                        </Typography>
                      </NavLink>
                    </ListItem>
                  );
                } else {
                  if (index === 0 || index === 2) {
                    if (index === 0 && isEmployerHome) {
                      return null;
                    }
                    return (
                      <ListItem key={index}>
                        <NavLink
                          key={index}
                          to={obj?.link}
                          className={classes.link_style}
                        >
                          <Typography className={classes.menu_title_style}>
                            {_.toUpper(obj?.title)}
                          </Typography>
                        </NavLink>
                      </ListItem>
                    );
                  }
                  return (
                    <ListItem
                      key={index}
                      button
                      onClick={() => {
                        setLoginModal(true);
                      }}
                    >
                      <Typography className={classes.menu_title_style}>
                        {_.toUpper(obj?.title)}
                      </Typography>
                    </ListItem>
                  );
                }
              })}
              {isDashboard ? (
                <ListItem
                  button
                  onClick={() => {
                    userLogout();
                  }}
                >
                  <Typography className={classes.menu_title_style}>
                    LOG OUT
                  </Typography>
                </ListItem>
              ) : null}
            </List>
          </Box>
        </SwipeableDrawer>
      </React.Fragment>
    );
  }

  function LoginSignUpMenu() {
    const is_Sign_Up_Screen =
      Current_Url.includes("signup") ||
      Current_Url.includes("verification") ||
      Current_Url.includes("reset-password") ||
      Current_Url.includes("about-us") ||
      Current_Url.includes("contact-us") ||
      Current_Url.includes("terms-and-condition") ||
      Current_Url.includes("privacy-policy");

    if (is_Sign_Up_Screen) {
      return (
        <Grid className={classes.list_menu_con}>
          {Current_Url.includes("terms-and-condition") ||
          Current_Url.includes("privacy-policy") ||
          isBack === 0 ? (
            <IconButton
              onClick={() => {
                if (
                  Current_Url.includes("reset-password") ||
                  Current_Url.includes("verification")
                ) {
                  navigate("/");
                } else if (
                  (Current_Url.includes("terms-and-condition") ||
                    Current_Url.includes("privacy-policy")) &&
                  isBack !== 0
                ) {
                  window.close();
                } else {
                  navigate(-1);
                }
              }}
            >
              <HighlightOff
                fontSize={"medium"}
                style={{
                  color: color.secondary,
                  cursor: "pointer",
                  fontSize: 34,
                }}
              />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => {
                if (isBack === 1) {
                  dispatch(setBackAction(0));
                } else {
                  dispatch(setBackAction(isBack - 1));
                }
              }}
            >
              <ArrowBackIcon
                style={{ color: color.secondary }}
                fontSize={"medium"}
              />
            </IconButton>
          )}
        </Grid>
      );
    }

    if (isMobileDevice || isMobile || isTablet) {
      return renderToggleMenu();
    } else {
      return (
        <Grid item display={"flex"} className={classes.list_menu_con}>
          {isEmployerHome ? null : (
            <>
              <Grid item>
                <NavLink
                  to={"/candidate/signup"}
                  className={classes.link_style}
                >
                  <Typography className={classes.menu_title_style}>
                    SIGN UP
                  </Typography>
                </NavLink>
              </Grid>
              <div className={classes.verticle_line} />
            </>
          )}

          <Grid
            item
            onClick={() => {
              setLoginModal(true);
            }}
            className={classes.poniter_text}
          >
            <Typography className={classes.menu_title_style}>LOG IN</Typography>
          </Grid>

          <div className={classes.verticle_line} />

          <Grid item>
            <NavLink
              to={isEmployerHome ? "/" : "/employer/home"}
              className={classes.link_style}
            >
              <Typography className={classes.menu_title_style}>
                {isEmployerHome ? "FOR CANDIDATE" : "FOR EMPLOYER"}
              </Typography>
            </NavLink>
          </Grid>
        </Grid>
      );
    }
  }

  function renderDashboardMenu() {
    const ary = isCandidate
      ? candidate_dashboard_menu_link
      : employer_dashboard_menu_link;

    const is_Sign_Up_Screen =
      Current_Url.includes("quiz") ||
      Current_Url.includes("candidate-result") ||
      Current_Url.includes("unsubscribe_me") ||
      Current_Url.includes("about-us") ||
      Current_Url.includes("contact-us") ||
      Current_Url.includes("terms-and-condition") ||
      Current_Url.includes("privacy-policy");
    if (is_Sign_Up_Screen) {
      return (
        <Grid container className={classes.list_menu_con}>
          <HighlightOff
            fontSize={"medium"}
            style={{ color: color.secondary, cursor: "pointer" }}
            onClick={() => {
              if (Current_Url.includes("unsubscribe_me")) {
                navigate("/");
              } else {
                navigate(-1);
              }
            }}
          />
        </Grid>
      );
    }

    if (isMobileDevice || isTablet) {
      return renderToggleMenu(true);
    } else {
      return (
        <Grid container className={classes.list_menu_con}>
          {ary.map((obj, index) => {
            const activeKey = obj?.key?.includes(location.pathname);
            return (
              <Grid item key={index} className={classes.PR25}>
                <div className={classes.linkContainer}>
                  <NavLink to={obj?.link} className={classes.link_style}>
                    <Typography className={classes.menu_title_style}>
                      {_.toUpper(obj?.title)}
                    </Typography>
                  </NavLink>
                  {activeKey ? (
                    <div className={classes.activeLinkStyle} />
                  ) : null}
                </div>
              </Grid>
            );
          })}
          <Grid
            item
            onClick={() => {
              userLogout();
            }}
            className={classes.poniter_text}
          >
            <Typography className={classes.menu_title_style}>
              LOG OUT
            </Typography>
          </Grid>
        </Grid>
      );
    }
  }

  return (
    <div
      className={classes.header_main_con}
      style={
        isAddPadding ? { paddingLeft: "15px", paddingRight: "15px" } : null
      }
    >
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: isMobile ? "row-reverse" : "row",
          alignItems: "center",
        }}
        wrap="nowrap"
      >
        <Grid
          item
          style={{
            height: isMobileDevice ? "30px" : "40px",
            cursor: "pointer",
          }}
          onClick={() =>
            navigate(
              userRole === "employer"
                ? "/employer/jobs"
                : userRole === "user"
                ? "/candidate/jobs"
                : "/",
              { state: { from: "skip" } }
            )
          }
        >
          <img
            alt="logo"
            src={Images.header_logo}
            style={{ height: "100%", objectFit: "contain" }}
          />
        </Grid>
        <Grid item>{!isLogin ? LoginSignUpMenu() : renderDashboardMenu()}</Grid>
      </Grid>
      {renderLoginModal()}
      {renderForgotModal()}
    </div>
  );
}

Header.propTypes = {
  isCandidate: PropTypes.bool,
};

Header.defaultProps = {
  isCandidate: false,
};

export default Header;
