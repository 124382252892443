import { makeStyles } from "@mui/styles";
import { color } from "../../Config/theme";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "25px 20px",
    boxShadow: "0px 3px 15px #00000029",
    marginBottom: "25px !important",
    borderRadius: 4,
  },
  messageContainer: {
    "@media (max-width:768px)": {
      padding: "15px !important",
    },
  },
  sectionTitle: {
    color: color.primary,
    fontFamily: "PoppinsSemiBold !important",
    fontSize: "20px !important",
  },
  sectionDesc: {
    color: color.black,
    fontFamily: "PoppinsRegular !important",
    fontSize: "14px !important",
    margin: "5px 0px !important",
  },
  sectionSubDetail: {
    color: color.primary,
    fontFamily: "PoppinsRegular !important",
    fontSize: "18px !important",
    "@media (max-width:768px)": {
      fontSize: "15px !important",
    },
    "@media (max-width:425px)": {
      fontSize: "17px !important",
    },
  },
  centeredDetail: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  singleDetail: {
    "@media (max-width:425px)": {
      paddingRight: "10px !important",
      marginBottom: "10px !important",
    },
  },
  btnStyle: {
    "@media (max-width:1024px)": {
      fontSize: "14px !important",
    },
    "@media (max-width:768px)": {
      fontSize: "14px !important",
    },
    "@media (max-width:425px)": {
      marginTop: "20px !important",
    },
  },
  respBtn: {
    "@media (max-width:1024px)": {
      fontSize: "12px !important",
    },
    "@media (max-width:425px)": {
      fontSize: "14px !important",
    },
  },
  editBtnStyle: {
    height: "30px",
    width: "30px",
    borderRadius: "15px",
    backgroundColor: color.primary,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    boxShadow: "0px 3px 6px #00000052",
  },
  profileBtnDivStyle: {
    marginTop: "15px !important",
    "@media (max-width:768px)": {
      "& > div:first-child": {
        marginBottom: 15,
      },
      "& > div:nth-child(3)": {
        marginBottom: 15,
      },
    },
  },
  offerImgStyle: {
    width: "90%",
    height: "100%",
    borderRadius: 2,
    objectFit: "cover",
    "@media (max-width:425px)": {
      width: "70%",
    },
  },
  offerImgResp: {
    "@media (max-width:425px)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "20px",
    },
  },
  messageContainerDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "@media (max-width:425px)": {
      alignItems: "unset",
      justifyContent: "unset",
    },
  },
  messageImageContainer: { display: "flex", alignItems: "center" },
  msgImg: {
    width: 70,
    height: 70,
    borderRadius: 4,
    "@media (max-width:425px)": {
      width: 100,
      height: 100,
    },
  },
  msgBtnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 15,
  },
  hideDateView: {
    "@media (max-width:425px)": {
      display: "none",
    },
  },
  pl20: {
    paddingLeft: 20,
  },
  mobileRespDiv: {
    display: "none",
    "@media (max-width:425px)": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: "100%",
    },
  },
  mb10: {
    marginBottom: 10,
  },
  mb20: {
    marginBottom: 20,
  },
  shadowViewStyle: {
    boxShadow: "rgb(48 48 48 / 14%) 0px 3px 6px 2px",
    borderRadius: 3,
    height: "100%",
  },
  paddingStyle: {
    padding: "20px 25px",
  },
  marginDiv: {
    marginBottom: "24px !important",
  },
  matchSingleJobDetail: {
    marginBottom: 10,
    paddingRight: 15,
  },
  cJobImgStyle: {
    width: "100%",
    height: "200px",
    borderRadius: 2,
    objectFit: "cover",
    "@media (max-width:425px)": {
      height: "100%",
    },
  },
  mJobContainerStyle: {
    paddingLeft: 15,
    "@media (max-width:425px)": {
      paddingLeft: 0,
      marginTop: 15,
    },
  },
  jobBtnStyle: {
    "@media (max-width:425px)": {
      marginBottom: "15px !important",
    },
  },
  splitViewStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  addIcon: {
    color: color.primary,
    fontSize: "35px !important",
    cursor: "pointer",
    "&:hover": {
      color: "#243C53",
    },
  },
  profileLinkStyle: {
    fontFamily: "PoppinsRegular",
    fontSize: 12,
    textDecoration: "underline",
    cursor: "pointer",
  },
  detailDivStyle: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
}));

export default useStyles;
