import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  CircularProgress,
  Button,
  TextField,
} from "@mui/material";
import useStyles from "./styles";
import { toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import { Setting } from "../../Utils/Setting";
import { isEdge } from "react-device-detect";
import { getApiData, getAPIProgressData } from "../../Utils/APIHelper";
import { isEmpty } from "lodash";
import MForgot from "../../Components/Modal/ForgotModal";

const errorObj = {
  newPassErr: false,
  confirmErr: false,
  newPassMsg: "",
  confirmMsg: "",
};

function ResetPass() {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [errObj, setErrObj] = useState(errorObj);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [forgotModal, setForgotModal] = useState(false);
  const [newPassVisible, setNewPassVisible] = useState(false);
  const [confirmPassVisible, setConfirmPassVisible] = useState(false);
  const token = location?.search.substring(1);
  const passRegex = Setting.JS_Regex.pwd1_Regex;

  useEffect(() => {
    document.title = Setting.page_name.RESETPASSWORD;
    if (token !== "") {
      checkToken();
    } else {
      navigate("/");
    }
  }, []);

  // this function for check token
  async function checkToken() {
    try {
      const response = await getApiData(
        `${Setting.endpoints.userVerifyToken}?token=${token}`,
        "GET"
      );

      if (!response.status) {
        setTokenExpired(true);
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  }

  function checkValidation() {
    const error = { ...errObj };
    let valid = true;

    if (isEmpty(newPass)) {
      valid = false;
      error.newPassErr = true;
      error.newPassMsg = "Please enter new password";
    } else if (!passRegex.test(newPass)) {
      valid = false;
      error.newPassErr = true;
      error.newPassMsg = "Password must be 8 characters long";
    } else if (isEmpty(confirmPass)) {
      valid = false;
      error.confirmErr = true;
      error.confirmMsg = "Please enter confirm password";
    } else if (newPass !== confirmPass) {
      valid = false;
      error.confirmErr = true;
      error.confirmMsg = "New & confirm password must be same";
    }

    setErrObj(error);

    if (valid) {
      forgotPass();
    }
  }

  async function forgotPass() {
    setLoader(true);
    const ForgotData = {
      "ResetPasswordForm[password]": newPass,
      "ResetPasswordForm[token]": token,
    };

    try {
      let endPoint = Setting.endpoints.new_pass;
      const response = await getAPIProgressData(
        endPoint,
        "POST",
        ForgotData,
        false
      );
      if (response?.status) {
        toast.success(response?.message);
        setLoader(false);
        navigate("/");
      } else {
        //display error msg here
        toast.error(response?.message);
        setLoader(false);
      }
    } catch (err) {
      console.log("Catch Part", err);
      //display something went wrong error msg here
      toast.error("Something_went_wrong");
      setLoader(false);
    }
  }

  function renderMain() {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        direction="column"
      >
        <Grid xs={12} sm={8} md={4}>
          {tokenExpired ? (
            <>
              <Typography className="fontsize primarycolor" textAlign="center">
                Invalid or expired token
              </Typography>
              <Typography
                className="primarycolor regulerfont"
                style={{ marginTop: 10, textAlign: "center" }}
              >
                Please try again to reset your password.
              </Typography>
              <Typography
                className="primarycolor mediumfont"
                style={{ cursor: "pointer", marginTop: 8, textAlign: "center" }}
                onClick={() => setForgotModal(true)}
              >
                Forgot Password?
              </Typography>
            </>
          ) : (
            <>
              <Typography className="fontsize primarycolor" textAlign="center">
                Reset password
              </Typography>
              <Typography
                className="primarycolor mediumfont"
                style={{ marginTop: 30 }}
              >
                New password
              </Typography>
              <TextField
                fullWidth
                placeholder="New password"
                value={newPass}
                autoComplete="ViewCrunch"
                type={newPassVisible ? "none" : "password"}
                error={errObj.newPassErr}
                helperText={errObj.newPassErr ? errObj.newPassMsg : null}
                onChange={(v) => {
                  setNewPass(v.target.value);
                  setErrObj({
                    ...errObj,
                    newPassErr: false,
                    newPassMsg: "",
                  });
                }}
                inputProps={{
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
                InputProps={{
                  endAdornment: isEdge ? null : !newPassVisible ? (
                    <VisibilityOff
                      style={{ color: "#1F4771", cursor: "pointer" }}
                      onClick={() => {
                        setNewPassVisible(!newPassVisible);
                      }}
                    />
                  ) : (
                    <Visibility
                      style={{ color: "#1F4771", cursor: "pointer" }}
                      onClick={() => {
                        setNewPassVisible(!newPassVisible);
                      }}
                    />
                  ),
                }}
                style={{ marginTop: 10 }}
              />
              <Typography
                className="primarycolor mediumfont"
                style={{ marginTop: 10 }}
              >
                Confirm password
              </Typography>
              <TextField
                fullWidth
                placeholder="Confirm Password"
                value={confirmPass}
                autoComplete="ViewCrunch"
                type={confirmPassVisible ? "none" : "password"}
                error={errObj.confirmErr}
                helperText={errObj.confirmErr ? errObj.confirmMsg : null}
                onChange={(v) => {
                  setConfirmPass(v.target.value);
                  setErrObj({
                    ...errObj,
                    confirmErr: false,
                    confirmMsg: "",
                  });
                }}
                InputProps={{
                  endAdornment: isEdge ? null : !confirmPassVisible ? (
                    <VisibilityOff
                      style={{ color: "#1F4771", cursor: "pointer" }}
                      onClick={() => {
                        setConfirmPassVisible(!confirmPassVisible);
                      }}
                    />
                  ) : (
                    <Visibility
                      style={{ color: "#1F4771", cursor: "pointer" }}
                      onClick={() => {
                        setConfirmPassVisible(!confirmPassVisible);
                      }}
                    />
                  ),
                }}
                style={{ marginTop: 10 }}
              />
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  checkValidation();
                }}
                style={{ marginTop: 20 }}
                disabled={loader}
              >
                {loader ? (
                  <CircularProgress style={{ color: "#fff" }} size={26} />
                ) : (
                  "reset password"
                )}
              </Button>
            </>
          )}
        </Grid>
        {forgotModal && (
          <MForgot
            visible={forgotModal}
            handleModal={() => {
              setForgotModal(false);
            }}
          />
        )}
      </Grid>
    );
  }

  return <div className={classes.mainDiv}>{renderMain()}</div>;
}

export default ResetPass;
