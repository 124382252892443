import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    display: "flex",
    flex: "1 1 auto",
    height: "600px",
  },
}));

export default useStyles;
