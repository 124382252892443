import React, { useEffect, useState } from "react";
import FBLoginButton from "../../../Components/Social_Login/FBLoginButton";
import {
  Grid,
  Box,
  Radio,
  Button,
  Checkbox,
  useTheme,
  TextField,
  Typography,
  Autocomplete,
  useMediaQuery,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import "./styles.css";
import _, { isArray, isEmpty, isNull } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { AddLocationAlt, VisibilityOff, Visibility } from "@mui/icons-material";
import moment from "moment";
import CDropDown from "../../../Components/CDropDown";
import { Setting } from "../../../Utils/Setting";
import { useNavigate, useLocation } from "react-router-dom";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { isEdge } from "react-device-detect";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Images from "../../../Config/Images";
import CStepper from "../../../Components/CStepper";
import { toast } from "react-toastify";
import PlaceAutoComplete from "../../../Components/PlaceAutoComplete";
import MapContainer from "../../../Components/MapContainer";
import GoogleLoginButton from "../../../Components/Social_Login/GoogleLoginButton";
// import LinkedinLoginButton from "../../../Components/Social_Login/LinkedinLoginButton";
import MUserDetail from "../../../Components/Profile/Modal/MUserDetail";
import authActions from "../../../Redux/reducers/auth/actions";
import { getAPIProgressData } from "../../../Utils/APIHelper";
import { milesList } from "../../../Config/Static_Data";
import MLogin from "../../../Components/Modal/LoginModal";
import MForgot from "../../../Components/Modal/ForgotModal";
import ThankYou from "../../../Components/Profile/Modal/ThankYou";

const errorObj = {
  sectorErr: false,
  jobRoleErr: false,
  perYearErr: false,
  perHourErr: false,
  bothPerYearErr: false,
  bothPerHourErr: false,
  fromDateErr: false,
  regionErr: false,
  postCodeErr: false,
  cultureErr: false,
  companySizeErr: false,
  firstNameErr: false,
  lastNameErr: false,
  emailErr: false,
  phoneErr: false,
  passwordErr: false,
  milesErr: false,
  tempPerYearErr: false,
  tempPerHourErr: false,
  cityErr: false,
  sectorMsg: "",
  jobRoleMsg: "",
  perYearMsg: "",
  perHourMsg: "",
  bothPerYearMsg: "",
  bothPerHourMsg: "",
  fromDateMsg: "",
  regionMsg: "",
  postCodeMsg: "",
  cultureMsg: "",
  companySizeMsg: "",
  firstNameMsg: "",
  lastNameMsg: "",
  emailMsg: "",
  phoneMsg: "",
  passwordMsg: "",
  milesMsg: "",
  tempPerYearMsg: "",
  tempPerHourMsg: "",
  cityMsg: "",
};
const { setUserData, setToken, setBackAction } = authActions;

export default function SignUp() {
  const { dropdowndata, useruuid, isBack } = useSelector((state) => state.auth);
  const Current_Url = window.location.href;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isFirst = !isNull(location.state);
  const [errObj, setErrObj] = useState(errorObj);
  const [loginModal, setLoginModal] = useState(false);
  const [forgotModal, setForgotModal] = useState(false);
  const email_regex = Setting.JS_Regex.email_Regex;
  const number_Regex = Setting.JS_Regex.number_Regex;
  const passRegex = Setting.JS_Regex.pwd1_Regex;
  const alphabatic_Regex = Setting.JS_Regex.alphabatic_Regex;

  const isEmployerHome = Current_Url.includes("employer/home");
  const steps = [1, 2, 3, 4, 5, 6];
  const [activeStep, setActiveStep] = useState(isFirst ? 1 : 0);
  const firstStep = activeStep === 0;
  const lastStep = activeStep === steps.length - 1;

  const [openModal, setOpenModal] = useState(false);
  const [jobRoleArr, setJobRoleArr] = useState([]);

  const [loader, setLoader] = useState(false);
  const [socialUserInfo, setSocialUserInfo] = useState({});

  //Step 1st
  const [sector, setSector] = useState(isFirst ? location.state.sector : {});
  const [jobRole, setJobRole] = useState(isFirst ? location.state.jobRole : {});

  //Step 2nd
  const [selectedValue, setSelectedValue] = useState("p_full_time");
  const [perYear, setPerYear] = useState("");
  const [perHour, setPerHour] = useState("");
  const [tempPerYear, setTempPerYear] = useState("");
  const [tempPerHour, setTempPerHour] = useState("");
  const [bothPerYear, setBothPerYear] = useState("");
  const [bothPerHour, setBothPerHour] = useState("");

  //Step 3rd
  const [availability, setAvailability] = useState("now");
  const [availDate, SetAvailDate] = useState(null);
  const [openPicker, setOpenPicker] = useState(false);

  //Step 4th
  const [selectedLocation, setSelectedLocation] = useState({});
  const [selectedLocations, setSelextedLocations] = useState([]);
  const [selectedMiles, setSelectedMiles] = useState("");
  const [remoteOnly, setRemoteOnly] = useState(false);
  const isSelected = !_.isEmpty(selectedLocations);

  //Step 5th
  const [postCode, setPostCode] = useState("");

  //step 6th
  const [culture, setCulture] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [city, setCity] = useState("");

  //Step 7th
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");

  const [passVisible, setPasswordVisible] = useState(false);

  const [modalSaveBtnLoader, setModalSaveBtnLoader] = useState(false);
  const [modal, setModal] = useState(false);
  const [isSocialSignUp, setIsSocialSignUp] = useState("");

  const [agree, setAgree] = useState(false);

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    document.title = Setting.page_name.CANDIDATE_SIGN_UP;
  }, []);

  useEffect(() => {
    if (isFirst) {
      jobRoleData(sector?.value);
      dispatch(setBackAction(1));
    } else {
      dispatch(setBackAction(0));
    }
  }, []);

  useEffect(() => {
    setActiveStep(isBack);
  }, [isBack]);

  function handleBack() {
    setActiveStep(activeStep - 1);
    dispatch(setBackAction(isBack - 1));
  }

  function handleNext() {
    setActiveStep(activeStep + 1);
    dispatch(setBackAction(activeStep + 1));
  }

  function removeLocation(index) {
    const arr = [...selectedLocations];
    arr.map((i, ii) => {
      if (ii === index) {
        arr.splice(ii, 1);
      }
      setSelextedLocations(arr);
    });
  }

  function err(message) {
    toast.error(message, {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      progress: undefined,
    });
  }

  function jobRoleData(value) {
    dropdowndata?.sectors.map((ii) => {
      if (ii?.value === value) {
        setJobRoleArr(ii?.job_roles);
      }
    });
  }

  function checkStepsValidation() {
    const error = { ...errObj };
    if (activeStep === 0) {
      if (_.isEmpty(sector)) {
        error.sectorErr = true;
        error.sectorMsg = "Please select sector";
      } else if (_.isEmpty(jobRole)) {
        error.jobRoleErr = true;
        error.jobRoleMsg = "Please select job role";
      } else {
        handleNext();
      }
    } else if (activeStep === 1) {
      if (selectedValue === "p_full_time") {
        if (_.isEmpty(perYear)) {
          error.perYearErr = true;
          error.perYearMsg = "Please enter yearly amount";
        } else {
          handleNext();
        }
      } else if (selectedValue === "p_part_time") {
        if (_.isEmpty(perHour)) {
          error.perHourErr = true;
          error.perHourMsg = "Please enter hourly amount";
        } else {
          handleNext();
        }
      } else if (selectedValue === "t_full_time") {
        if (_.isEmpty(tempPerYear)) {
          error.tempPerYearErr = true;
          error.tempPerYearMsg = "Please enter yearly amount";
        } else {
          handleNext();
        }
      } else if (selectedValue === "t_part_time") {
        if (_.isEmpty(tempPerHour)) {
          error.tempPerHourErr = true;
          error.tempPerHourMsg = "Please enter hourly amount";
        } else {
          handleNext();
        }
      } else if (selectedValue === "contract" || selectedValue === "any") {
        if (_.isEmpty(bothPerYear)) {
          error.bothPerYearErr = true;
          error.bothPerYearMsg = "Please enter yearly amount";
        } else if (_.isEmpty(bothPerHour)) {
          error.bothPerHourErr = true;
          error.bothPerHourMsg = "Please enter hourly amount";
        } else {
          handleNext();
        }
      } else {
        handleNext();
      }
    } else if (activeStep === 2) {
      if (availability === "from") {
        if (!_.isDate(availDate)) {
          error.fromDateErr = true;
          error.fromDateMsg = "Please select date";
        } else {
          handleNext();
        }
      } else {
        handleNext();
      }
    } else if (activeStep === 3) {
      if (isEmpty(selectedLocation) && !remoteOnly) {
        error.regionErr = true;
        error.regionMsg = "Please select postcode or Remote only";
      } else if (
        !isEmpty(selectedLocation) &&
        isEmpty(selectedMiles) &&
        !remoteOnly
      ) {
        error.milesErr = true;
        error.milesMsg = "Please select miles";
      } else {
        handleNext();
      }
    } else if (activeStep === 4) {
      // if (_.isEmpty(culture.toString())) {
      //   error.cultureErr = true;
      //   error.cultureMsg = "Please select culture";
      // } else if (_.isEmpty(companySize.toString())) {
      //   error.companySizeErr = true;
      //   error.companySizeMsg = "Please select company size";
      // } else {
      handleNext();
      // }
    } else if (activeStep === 5) {
      if (_.isEmpty(firstName)) {
        error.firstNameErr = true;
        error.firstNameMsg = "Please enter first name";
      } else if (!alphabatic_Regex.test(firstName.trim())) {
        error.firstNameErr = true;
        error.firstNameMsg = "Only characters are allow";
      } else if (_.isEmpty(lastName)) {
        error.lastNameErr = true;
        error.lastNameMsg = "Please enter last name";
      } else if (!alphabatic_Regex.test(lastName.trim())) {
        error.lastNameErr = true;
        error.lastNameMsg = "Only characters are allow";
      } else if (_.isEmpty(email)) {
        error.emailErr = true;
        error.emailMsg = "Please enter email";
      } else if (!email_regex.test(email)) {
        error.emailErr = true;
        error.emailMsg = "Please enter valid email";
      } else if (_.isEmpty(phone)) {
        error.phoneErr = true;
        error.phoneMsg = "Please enter phone number";
      } else if (phone.length < 10 || !number_Regex.test(phone)) {
        error.phoneErr = true;
        error.phoneMsg = "Please enter valid phone number";
      }
      //  else if (!phone_Regex.test(phone)) {
      //   error.phoneErr = true;
      //   error.phoneMsg = "Number must be in +44 XXXXXXXXXX format";
      // }
      else if (_.isEmpty(password)) {
        error.passwordErr = true;
        error.passwordMsg = "Please enter password";
      } else if (!passRegex.test(password)) {
        error.passwordErr = true;
        error.passwordMsg = "Password must be 8 characters long";
      } else if (!agree) {
        err("Please accept the Terms and Conditions");
      } else {
        camdidateSignupProcess();
        setLoader(true);
      }
    }

    setErrObj(error);
  }

  async function camdidateSignupProcess(data = {}) {
    const isNew = jobRole?.value ? false : true;
    setIsSocialSignUp(_.isObject(data) && !_.isEmpty(data));
    const checkSocial = _.isObject(data) && !_.isEmpty(data);

    const signUpData = {
      "SignupForm[sector_id]": sector?.value,
      "SignupForm[job_role_id]": isNew ? "" : jobRole?.value,
      "SignupForm[is_new]": isNew ? 1 : 0,
      "SignupForm[job_role_type]": selectedValue,
      "SignupForm[availablity_type]": availability,
      "SignupForm[location]": selectedLocation?.location || "",
      "SignupForm[culture_type]": culture,
      "SignupForm[company_size]": companySize,
      "SignupForm[terms_condition]": agree ? 1 : 0,
      "SignupForm[first_name]": checkSocial
        ? data?.firstName
        : firstName.trim(),
      "SignupForm[last_name]": checkSocial ? data?.lastName : lastName.trim(),
      "SignupForm[email]": checkSocial ? data?.email : email,
      "SignupForm[phone]": checkSocial ? data?.phone : `+44${phone}`,
      "SignupForm[is_remote]": remoteOnly ? 1 : 0,
      "SignupForm[uuid]": useruuid,
      "SignupForm[lat]": selectedLocation?.lat || null,
      "SignupForm[long]": selectedLocation?.lng || null,
      "SignupForm[prefer_distance]": selectedMiles,
      "SignupForm[city_id]": city,
    };

    if (isNew) {
      signUpData["SignupForm[new_job_role]"] = jobRole?.label;
    }

    if (!checkSocial) {
      signUpData["SignupForm[password]"] = password;
    } else {
      signUpData["SignupForm[provider_type]"] = socialUserInfo?.provider_type;
      signUpData["SignupForm[provider_key]"] = socialUserInfo?.provider_key;
    }

    if (selectedValue === "p_full_time" || selectedValue === "t_full_time") {
      signUpData["SignupForm[charge_per_year]"] =
        selectedValue === "p_full_time" ? perYear : tempPerYear;
    } else if (
      selectedValue === "p_part_time" ||
      selectedValue === "t_part_time"
    ) {
      signUpData["SignupForm[charge_per_hour]"] =
        selectedValue === "p_part_time" ? perHour : tempPerHour;
    } else if (selectedValue === "contract" || selectedValue === "any") {
      signUpData["SignupForm[charge_per_year]"] = bothPerYear;
      signUpData["SignupForm[charge_per_hour]"] = bothPerHour;
    }

    if (availability === "from") {
      const formateDate = moment(availDate).format("yyyy-MM-DD");
      signUpData["SignupForm[availablity_date]"] = formateDate;
    }

    if (!remoteOnly) {
      if (isArray(selectedLocations) && !isEmpty(selectedLocations)) {
        selectedLocations.map((ii, index) => {
          signUpData[`SignupForm[regions][${index}]`] = ii?.value;
        });
      }
    }

    try {
      let endPoint = Setting.endpoints.candidate_signup;
      const response = await getAPIProgressData(
        endPoint,
        "POST",
        signUpData,
        false
      );
      if (response?.status) {
        setModal(true);
        if (checkSocial) {
          dispatch(setUserData(response?.data));
          dispatch(setToken(response?.data?.access_token || ""));
          setModalSaveBtnLoader(false);
        } else {
          setLoader(false);
          toast.success(response?.message);
        }
      } else {
        //display error msg here
        err(response?.message);
        setLoader(false);
        setModalSaveBtnLoader(false);
      }
    } catch (err) {
      console.log("Catch Part", err);
      //display something went wrong error msg here
      err("Something_went_wrong");
      setLoader(false);
      setModalSaveBtnLoader(false);
    }
  }

  function renderfirst() {
    const isSector = isEmpty(sector);
    return (
      <Grid
        direction="column"
        container
        //  style={{ minHeight: sm ? 320 : 500 }}
      >
        <Typography
          className="primarycolor fontsize"
          style={{ textAlign: sm ? "center" : "unset" }}
        >
          Select the sector and job role.
        </Typography>
        <Typography
          className="primarycolor mediumfont"
          style={{ marginBottom: 8 }}
        >
          Select the sector
        </Typography>
        <Autocomplete
          style={{ maxWidth: sm ? "unset" : 300 }}
          options={dropdowndata?.sectors}
          disableClearable
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select sector"
              error={errObj.sectorErr}
              helperText={errObj.sectorErr ? errObj.sectorMsg : null}
            />
          )}
          onChange={(e, v) => {
            setSector({ value: v.value, label: v.label });
            jobRoleData(v.value);
            setJobRole({});
            setErrObj({ ...errObj, sectorErr: false, sectorMsg: "" });
          }}
          onInputChange={(v) => {
            if (!isNull(v) && v.target.value.length === 0) {
              setSector({});
            }
          }}
          freeSolo
          size="small"
          value={sector?.label}
        />
        <Typography
          className="primarycolor mediumfont"
          style={{ marginBottom: 8, marginTop: 8 }}
        >
          Select the job role
        </Typography>
        <Autocomplete
          key={jobRole?.label}
          style={{ maxWidth: sm ? "unset" : 300 }}
          options={jobRoleArr}
          disabled={isSector}
          disableClearable
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select job"
              error={errObj.jobRoleErr}
              helperText={errObj.jobRoleErr ? errObj.jobRoleMsg : null}
            />
          )}
          onChange={(e, v) => {
            setJobRole({ value: v.value, label: v.label });
            setErrObj({ ...errObj, jobRoleErr: false, jobRoleMsg: "" });
          }}
          onInputChange={(v) => {
            if (!isNull(v) && v.target.value.length === 0) {
              setJobRole({});
            }
          }}
          freeSolo
          size="small"
          value={jobRole?.label}
        />
      </Grid>
    );
  }

  function renderSecond() {
    return (
      <Grid
        container
        direction="column"
        // style={{ minHeight: sm ? 320 : 500 }}
      >
        <Typography
          className="primarycolor fontsize"
          style={{ textAlign: sm ? "center" : "unset", marginBottom: 10 }}
        >
          Tell us what kind of role you're looking for?
        </Typography>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Grid
            container
            justifyContent="space-between"
            style={{ marginBottom: 16 }}
          >
            <Grid item xs={12} sm={5.8} md={3.9}>
              <div className="radiomain">
                <Radio
                  checked={selectedValue === "p_full_time"}
                  onChange={(v) => {
                    setSelectedValue(v.target.value);
                  }}
                  value="p_full_time"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
                <Typography className="primarycolor regulerlargefont">
                  Permanent - Full Time
                </Typography>
              </div>
            </Grid>
            {selectedValue === "p_full_time" ? (
              <>
                <Grid
                  item
                  xs={12}
                  sm={5.8}
                  md={3.9}
                  style={{ display: "flex", marginTop: sm ? 16 : 0 }}
                >
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Salary"
                    value={perYear}
                    error={errObj.perYearErr}
                    helperText={errObj.perYearErr ? errObj.perYearMsg : null}
                    onChange={(e) => {
                      setPerYear(e.target.value);
                      setErrObj({
                        ...errObj,
                        perYearErr: false,
                        perYearMsg: "",
                      });
                    }}
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <div className="inputside">per year</div>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={3.9}></Grid>
              </>
            ) : null}
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            style={{ marginBottom: 16 }}
          >
            <Grid item xs={12} sm={5.8} md={3.9}>
              <div className="radiomain">
                <Radio
                  checked={selectedValue === "p_part_time"}
                  onChange={(v) => {
                    setSelectedValue(v.target.value);
                  }}
                  value="p_part_time"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
                <Typography className="primarycolor regulerlargefont">
                  Permanent - Part Time
                </Typography>
              </div>
            </Grid>
            {selectedValue === "p_part_time" ? (
              <>
                <Grid
                  item
                  xs={12}
                  sm={5.8}
                  md={3.9}
                  style={{ display: "flex", marginTop: sm ? 16 : 0 }}
                >
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Salary"
                    value={perHour}
                    error={errObj.perHourErr}
                    helperText={errObj.perHourErr ? errObj.perHourMsg : null}
                    type="number"
                    onChange={(e) => {
                      setPerHour(e.target.value);
                      setErrObj({
                        ...errObj,
                        perHourErr: false,
                        perHourMsg: "",
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <div className="inputside">per hour</div>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={3.9}></Grid>
              </>
            ) : null}
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            style={{ marginBottom: 16 }}
          >
            <Grid item xs={12} sm={5.8} md={3.9}>
              <div className="radiomain">
                <Radio
                  checked={selectedValue === "t_full_time"}
                  onChange={(v) => {
                    setSelectedValue(v.target.value);
                  }}
                  value="t_full_time"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
                <Typography className="primarycolor regulerlargefont">
                  Temporary - Full Time
                </Typography>
              </div>
            </Grid>
            {selectedValue === "t_full_time" ? (
              <>
                <Grid
                  item
                  xs={12}
                  sm={5.8}
                  md={3.9}
                  style={{ display: "flex", marginTop: sm ? 16 : 0 }}
                >
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Salary"
                    value={tempPerYear}
                    error={errObj.tempPerYearErr}
                    helperText={
                      errObj.tempPerYearErr ? errObj.tempPerYearMsg : null
                    }
                    onChange={(e) => {
                      setTempPerYear(e.target.value);
                      setErrObj({
                        ...errObj,
                        tempPerYearErr: false,
                        tempPerYearMsg: "",
                      });
                    }}
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <div className="inputside">per year</div>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={3.9}></Grid>
              </>
            ) : null}
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            style={{ marginBottom: 16 }}
          >
            <Grid item xs={12} sm={5.8} md={3.9}>
              <div className="radiomain">
                <Radio
                  checked={selectedValue === "t_part_time"}
                  onChange={(v) => {
                    setSelectedValue(v.target.value);
                  }}
                  value="t_part_time"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
                <Typography className="primarycolor regulerlargefont">
                  Temporary - Part Time
                </Typography>
              </div>
            </Grid>
            {selectedValue === "t_part_time" ? (
              <>
                <Grid
                  item
                  xs={12}
                  sm={5.8}
                  md={3.9}
                  style={{ display: "flex", marginTop: sm ? 16 : 0 }}
                >
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Salary"
                    value={tempPerHour}
                    error={errObj.tempPerHourErr}
                    helperText={
                      errObj.tempPerHourErr ? errObj.tempPerHourMsg : null
                    }
                    type="number"
                    onChange={(e) => {
                      setTempPerHour(e.target.value);
                      setErrObj({
                        ...errObj,
                        tempPerHourErr: false,
                        tempPerHourMsg: "",
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <div className="inputside">per hour</div>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={3.9}></Grid>
              </>
            ) : null}
          </Grid>

          <Grid
            container
            justifyContent="space-between"
            style={{ marginBottom: 16 }}
          >
            <Grid item xs={12} sm={5.8} md={3.9}>
              <div className="radiomain">
                <Radio
                  checked={selectedValue === "contract"}
                  onChange={(v) => {
                    setSelectedValue(v.target.value);
                    setBothPerYear("");
                    setBothPerHour("");
                    setErrObj({
                      ...errObj,
                      bothPerYearErr: false,
                      bothPerYearMsg: "",
                      bothPerHourErr: false,
                      bothPerHourMsg: "",
                    });
                  }}
                  value="contract"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
                <Typography className="primarycolor regulerlargefont">
                  Contract
                </Typography>
              </div>
            </Grid>
            {selectedValue === "contract" ? (
              <Grid
                container
                xs={12}
                sm={5.8}
                md={7.8}
                justifyContent={md ? "flex-end" : "space-between"}
              >
                <Grid
                  item
                  xs={12}
                  sm={11.6}
                  md={5.9}
                  className="lastfirst"
                  style={{ marginTop: sm ? 16 : 0 }}
                >
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Salary"
                    value={bothPerYear}
                    error={errObj.bothPerYearErr}
                    helperText={
                      errObj.bothPerYearErr ? errObj.bothPerYearMsg : null
                    }
                    type="number"
                    onChange={(e) => {
                      setBothPerYear(e.target.value);
                      setErrObj({
                        ...errObj,
                        bothPerYearErr: false,
                        bothPerYearMsg: "",
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <div className="inputside">per year</div>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={11.6} md={5.9} className="lastfirst">
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Salary"
                    value={bothPerHour}
                    error={errObj.bothPerHourErr}
                    helperText={
                      errObj.bothPerHourErr ? errObj.bothPerHourMsg : null
                    }
                    type="number"
                    onChange={(e) => {
                      setBothPerHour(e.target.value);
                      setErrObj({
                        ...errObj,
                        bothPerHourErr: false,
                        bothPerHourMsg: "",
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <span>£</span>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <div className="inputside">per hour</div>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            ) : null}
          </Grid>

          <Grid
            container
            justifyContent="space-between"
            style={{ marginBottom: 16 }}
          >
            <Grid item xs={12} sm={5.8} md={3.9}>
              <div className="radiomain">
                <Radio
                  checked={selectedValue === "any"}
                  onChange={(v) => {
                    setSelectedValue(v.target.value);
                    setBothPerYear("");
                    setBothPerHour("");
                    setErrObj({
                      ...errObj,
                      bothPerYearErr: false,
                      bothPerYearMsg: "",
                      bothPerHourErr: false,
                      bothPerHourMsg: "",
                    });
                  }}
                  value="any"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
                <Typography className="primarycolor regulerlargefont">
                  Flexible
                </Typography>
              </div>
            </Grid>
            {selectedValue === "any" ? (
              <Grid
                container
                xs={12}
                sm={5.8}
                md={7.8}
                justifyContent={md ? "flex-end" : "space-between"}
              >
                <Grid
                  item
                  xs={12}
                  sm={11.6}
                  md={5.9}
                  className="lastfirst"
                  style={{ marginTop: sm ? 16 : 0 }}
                >
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Salary"
                    value={bothPerYear}
                    error={errObj.bothPerYearErr}
                    helperText={
                      errObj.bothPerYearErr ? errObj.bothPerYearMsg : null
                    }
                    type="number"
                    onChange={(e) => {
                      setBothPerYear(e.target.value);
                      setErrObj({
                        ...errObj,
                        bothPerYearErr: false,
                        bothPerYearMsg: "",
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <div className="inputside">per year</div>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={11.6} md={5.9} className="lastfirst">
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Salary"
                    value={bothPerHour}
                    error={errObj.bothPerHourErr}
                    helperText={
                      errObj.bothPerHourErr ? errObj.bothPerHourMsg : null
                    }
                    type="number"
                    onChange={(e) => {
                      setBothPerHour(e.target.value);
                      setErrObj({
                        ...errObj,
                        bothPerHourErr: false,
                        bothPerHourMsg: "",
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <span>£</span>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <div className="inputside">per hour</div>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </div>
      </Grid>
    );
  }

  function renderThird() {
    return (
      <Grid
        container
        direction="column"
        // style={{ minHeight: sm ? 320 : 500 }}
      >
        <Typography
          className="primarycolor fontsize"
          style={{ marginBottom: 10, textAlign: sm ? "center" : "unset" }}
        >
          When can you start?
        </Typography>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Grid
            container
            justifyContent="space-between"
            style={{ marginBottom: 16 }}
          >
            <Grid item xs={12} sm={5.8} md={3.9}>
              <div className="radiomain">
                <Radio
                  checked={availability === "now"}
                  onChange={(v) => {
                    setAvailability(v.target.value);
                  }}
                  value="now"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
                <Typography className="primarycolor regulerlargefont">
                  I’m available now
                </Typography>
              </div>
            </Grid>
            {availability === "now" ? (
              <>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}></Grid>
              </>
            ) : null}
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            style={{ marginBottom: 16 }}
          >
            <Grid item xs={12} sm={5.8} md={3.9}>
              <div className="radiomain">
                <Radio
                  checked={availability === "from"}
                  onChange={(v) => {
                    setAvailability(v.target.value);
                  }}
                  value="from"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
                <Typography className="primarycolor regulerlargefont">
                  I’m available from
                </Typography>
              </div>
            </Grid>
            {availability === "from" ? (
              <>
                <Grid
                  item
                  xs={12}
                  sm={5.8}
                  md={3.9}
                  style={{ display: "flex", marginTop: sm ? 16 : 0 }}
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      showToolbar={false}
                      value={availDate}
                      inputFormat={"dd/MM/yyyy"}
                      minDate={new Date()}
                      onChange={(newValue) => {
                        SetAvailDate(newValue);
                        setOpenPicker(false);
                        setErrObj({
                          ...errObj,
                          fromDateErr: false,
                          fromDateMsg: "",
                        });
                      }}
                      open={openPicker}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          // className={classes.inputFieldStyle}
                          {...params}
                          error={errObj.fromDateErr}
                          helperText={
                            errObj.fromDateErr ? errObj.fromDateMsg : null
                          }
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "Select Date",
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <div
                                  className="datepicker pickerStyle"
                                  onClick={() => {
                                    setOpenPicker(!openPicker);
                                  }}
                                >
                                  <img
                                    alt="error"
                                    src={Images.picker}
                                    style={{ width: 24 }}
                                  />
                                </div>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={3.9}></Grid>
              </>
            ) : null}
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            style={{ marginBottom: 16 }}
          >
            <Grid item xs={12} sm={5.8} md={3.9}>
              <div className="radiomain">
                <Radio
                  checked={availability === "not-available"}
                  onChange={(v) => {
                    setAvailability(v.target.value);
                  }}
                  value="not-available"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
                <Typography className="primarycolor regulerlargefont">
                  I’m not available
                </Typography>
              </div>
            </Grid>
            {availability === "not-available" ? (
              <>
                <Grid item xs={3.9}></Grid>
                <Grid item xs={3.9}></Grid>
              </>
            ) : null}
          </Grid>
        </div>
      </Grid>
    );
  }

  function renderFourth() {
    return (
      <Grid
        container
        direction="column"
        // style={{ minHeight: sm ? 320 : 500 }}
      >
        <Typography
          className="primarycolor fontsize"
          style={{ marginBottom: 10, textAlign: sm ? "center" : "unset" }}
        >
          Enter your postcode & preferred radius for travel
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={12} md={6}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <PlaceAutoComplete
                placeholder="Enter postcode for the UK only"
                style={{ maxWidth: 500, marginBottom: 10 }}
                onChange={(obj) => {
                  setSelectedLocation(obj);
                  setErrObj({ ...errObj, regionErr: false, regionMsg: "" });
                }}
                disable={remoteOnly ? true : false}
                error={errObj.regionErr}
                helperText={errObj.regionErr ? errObj.regionMsg : null}
              />
              {remoteOnly || isEmpty(selectedLocation) ? null : (
                <CDropDown
                  disable={remoteOnly ? true : false}
                  data={milesList}
                  value={selectedMiles}
                  error={errObj.milesErr}
                  helperText={errObj.milesErr ? errObj.milesMsg : null}
                  onChange={(v) => {
                    setSelectedMiles(v.target.value);
                    setErrObj({ ...errObj, milesErr: false, milesMsg: "" });
                  }}
                  placeholder="Radius"
                  style={{ maxWidth: 500 }}
                />
              )}
              {/* <CDropDown
                disable={remoteOnly ? true : false}
                data={dropdowndata?.region}
                value={selectedLocations}
                error={errObj.regionErr}
                helperText={errObj.regionErr ? errObj.regionMsg : null}
                onChange={(v) => {
                  setSelextedLocations(v.target.value);
                  setErrObj({ ...errObj, regionErr: false, regionMsg: "" });
                }}
                multiple={true}
                placeholder="Select or enter a location for the UK only"
                style={{ maxWidth: 500 }}
              /> */}
              <div style={{ marginTop: 10, marginBottom: 10 }}>
                <Typography className="mediumfont">
                  {isSelected ? "Selected locations" : "Remote only"}
                </Typography>
              </div>
              {/* {isSelected ? (
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {selectedLocations.map((item, index) => {
                    return (
                      <div key={index} className="selextedlocationmain">
                        <Typography className="regulerlargefont lightsilvercolor">
                          {item?.label}
                        </Typography>
                        <Button
                          variant="contained"
                          className="closebtn"
                          onClick={() => {
                            removeLocation(index);
                          }}
                        >
                          <Close style={{ fontSize: 16 }} />
                        </Button>
                      </div>
                    );
                  })}
                </div>
              ) : ( */}
              <div className="checkboxmain">
                <Checkbox
                  checked={remoteOnly}
                  onChange={() => {
                    setRemoteOnly(!remoteOnly);
                    setErrObj({ ...errObj, regionErr: false, regionMsg: "" });
                  }}
                />
                <Typography
                  className="lightsilvercolor regulerfont"
                  style={{ lineHeight: sm ? 1.5 : 0 }}
                >
                  Remote
                </Typography>
              </div>
              {/* )} */}
            </div>
          </Grid>
          {isEmpty(selectedLocation) ? null : (
            <Grid item xs={12} sm={12} md={5}>
              <div style={{ position: "relative", width: "100%", height: 250 }}>
                <MapContainer data={selectedLocation} miles={selectedMiles} />
              </div>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }

  function renderFifth() {
    return (
      <Grid container direction="column" style={{ minHeight: sm ? 320 : 500 }}>
        <Typography
          className="primarycolor fontsize"
          style={{ marginBottom: 10, textAlign: sm ? "center" : "unset" }}
        >
          Enter your Postcode.
        </Typography>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <TextField
            placeholder="Enter your Postcode"
            value={postCode}
            error={errObj.postCodeErr}
            helperText={errObj.postCodeErr ? errObj.postCodeMsg : null}
            onChange={(v) => {
              setPostCode(v.target.value);
              setErrObj({ ...errObj, postCodeErr: false, postCodeMsg: "" });
            }}
            InputProps={{
              endAdornment: <AddLocationAlt style={{ color: "#1F4771" }} />,
            }}
            style={{ maxWidth: 500 }}
          />
        </div>
      </Grid>
    );
  }

  function renderSixth() {
    return (
      <Grid
        container
        direction="column"
        // style={{ minHeight: sm ? 320 : 500 }}
      >
        <Typography
          className="primarycolor fontsize"
          style={{ marginBottom: 10, textAlign: sm ? "center" : "unset" }}
        >
          What kind of company would you like to work for?
        </Typography>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <CDropDown
            data={dropdowndata?.company_culture}
            placeholder="Culture"
            label="Select the culture"
            value={culture}
            error={errObj.cultureErr}
            helperText={errObj.cultureErr ? errObj.cultureMsg : null}
            onChange={(v) => {
              setCulture(v.target.value);
              setErrObj({
                ...errObj,
                cultureErr: false,
                cultureMsg: "",
              });
            }}
            style={{ maxWidth: sm ? "unset" : 300 }}
          />
          <CDropDown
            data={dropdowndata?.company_size}
            placeholder="Company size"
            label="Select the company size"
            value={companySize}
            error={errObj.companySizeErr}
            helperText={errObj.companySizeErr ? errObj.companySizeMsg : null}
            onChange={(v) => {
              setCompanySize(v.target.value);
              setErrObj({
                ...errObj,
                companySizeErr: false,
                companySizeMsg: "",
              });
            }}
            style={{ maxWidth: sm ? "unset" : 300 }}
          />
          <CDropDown
            data={dropdowndata?.city}
            placeholder="City"
            label="Select the city"
            value={city}
            error={errObj.cityErr}
            helperText={errObj.cityErr ? errObj.cityMsg : null}
            onChange={(v) => {
              setCity(v.target.value);
              setErrObj({
                ...errObj,
                cityErr: false,
                cityMsg: "",
              });
            }}
            style={{ maxWidth: sm ? "unset" : 300 }}
          />
        </div>
      </Grid>
    );
  }

  function renderSeventh() {
    return (
      <Grid
        container
        direction="column"
        // style={{ minHeight: sm ? 320 : lastStep ? 420 : 500 }}
      >
        <Typography
          className="primarycolor fontsize"
          style={{ marginBottom: 10 }}
        >
          Candidate Sign up
        </Typography>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <Grid container>
            <Grid item xs={12} sm={5} className="detailmain">
              <TextField
                placeholder="First name*"
                value={firstName}
                error={errObj.firstNameErr}
                helperText={errObj.firstNameErr ? errObj.firstNameMsg : null}
                onChange={(v) => {
                  setFirstName(v.target.value);
                  setErrObj({
                    ...errObj,
                    firstNameErr: false,
                    firstNameMsg: "",
                  });
                }}
                style={{ width: md ? "100%" : "70%", marginBottom: 16 }}
              />
              <TextField
                placeholder="Last name*"
                value={lastName}
                error={errObj.lastNameErr}
                helperText={errObj.lastNameErr ? errObj.lastNameMsg : null}
                onChange={(v) => {
                  setLastName(v.target.value);
                  setErrObj({ ...errObj, lastNameErr: false, lastNameMsg: "" });
                }}
                style={{ width: md ? "100%" : "70%", marginBottom: 16 }}
              />
              <TextField
                placeholder="Email*"
                value={email}
                error={errObj.emailErr}
                helperText={errObj.emailErr ? errObj.emailMsg : null}
                onChange={(v) => {
                  setEmail(v.target.value);
                  setErrObj({ ...errObj, emailErr: false, emailMsg: "" });
                }}
                style={{ width: md ? "100%" : "70%", marginBottom: 16 }}
              />
              <TextField
                placeholder="Phone*"
                value={phone}
                error={errObj.phoneErr}
                helperText={errObj.phoneErr ? errObj.phoneMsg : null}
                onChange={(v) => {
                  setPhone(v.target.value);
                  setErrObj({ ...errObj, phoneErr: false, phoneMsg: "" });
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+44</InputAdornment>
                  ),
                }}
                inputProps={{
                  paddingLeft: 0,
                  maxLength: 10,
                }}
                style={{ width: md ? "100%" : "70%", marginBottom: 16 }}
              />
              <TextField
                placeholder="Password*"
                autoComplete="new-password"
                error={errObj.passwordErr}
                helperText={errObj.passwordErr ? errObj.passwordMsg : null}
                type={passVisible ? "none" : "password"}
                value={password}
                onChange={(v) => {
                  setPassword(v.target.value);
                  setErrObj({ ...errObj, passwordErr: false, passwordMsg: "" });
                }}
                InputProps={{
                  endAdornment: isEdge ? null : !passVisible ? (
                    <VisibilityOff
                      style={{ color: "#1F4771", cursor: "pointer" }}
                      onClick={() => {
                        setPasswordVisible(!passVisible);
                      }}
                    />
                  ) : (
                    <Visibility
                      style={{ color: "#1F4771", cursor: "pointer" }}
                      onClick={() => {
                        setPasswordVisible(!passVisible);
                      }}
                    />
                  ),
                }}
                style={{ width: md ? "100%" : "70%", marginBottom: 16 }}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    ev.preventDefault();
                    checkStepsValidation();
                  }
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              className="dividermain"
              justifyContent="space-between"
            >
              <div className="divider" />
              <Typography>OR</Typography>
              <div className="divider" />
            </Grid>
            <Grid item xs={12} sm={5} direction="column" className="socialmain">
              <GoogleLoginButton
                from="SignUp"
                onGetUserInfo={(v) => {
                  setSocialUserInfo(v);
                  setOpenModal(true);
                }}
              />
              <FBLoginButton
                from="SignUp"
                onGetUserInfo={(v) => {
                  setSocialUserInfo(v);
                  setOpenModal(true);
                }}
              />
              {/* <LinkedinLoginButton from="SignUp" /> */}
            </Grid>
          </Grid>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            checked={agree}
            onChange={() => {
              setAgree(!agree);
            }}
            style={{ width: 40, marginLeft: -9 }}
          />
          <Typography className="lightsilvercolor ">
            I agree to the{" "}
            <a
              href={`${Setting?.mainURL}cms/terms-and-condition`}
              rel="noreferrer"
              target={"_blank"}
              className="primarycolor"
            >
              <u>T&Cs</u>
            </a>{" "}
            and{" "}
            <a
              href={`${Setting?.mainURL}cms/privacy-policy`}
              rel="noreferrer"
              target={"_blank"}
              className="primarycolor"
            >
              <u>Privacy Policy</u>
            </a>
          </Typography>
        </div>
      </Grid>
    );
  }

  function renderForm() {
    if (activeStep === 0) {
      return renderfirst();
    } else if (activeStep === 1) {
      return renderSecond();
    } else if (activeStep === 2) {
      return renderThird();
    } else if (activeStep === 3) {
      return renderFourth();
    } else if (activeStep === 4) {
      // return renderFifth();
      return renderSixth();
    } else if (activeStep === 5) {
      // return renderSixth();
      return renderSeventh();
    }
    // else if (activeStep === 6) {
    //   return renderSeventh();
    // }
  }

  function renderMain() {
    return (
      <Box sx={{ flexGrow: 1, paddingTop: 4 }}>
        <Grid container>
          <Grid item xs={12} style={{ marginBottom: 30 }}>
            <CStepper data={steps} activeStep={activeStep} />
          </Grid>
          <Grid item container xs={12} style={{ marginTop: 30 }}>
            {renderForm()}
          </Grid>
          <Grid
            item
            container
            xs={12}
            justifyContent={firstStep ? "flex-end" : "space-between"}
            flexDirection={lastStep ? "column" : "row"}
            style={{ marginTop: 30 }}
            className="btnStyle"
          >
            {firstStep || lastStep ? null : (
              <Button
                onClick={() => {
                  handleBack();
                }}
                variant={"outlined"}
                style={{ width: sm ? 130 : md ? 200 : 300 }}
              >
                {"Previous"}
              </Button>
            )}
            <Button
              onClick={() => {
                checkStepsValidation();
              }}
              variant="contained"
              style={{ width: sm ? 130 : md ? 200 : 300 }}
              disabled={loader}
            >
              {loader ? (
                <CircularProgress style={{ color: "#fff" }} size={28} />
              ) : lastStep ? (
                "Sign up"
              ) : (
                "next"
              )}
            </Button>
            {lastStep ? (
              <Typography className={`primarycolor regulerfont loginText`}>
                Already have an account?{" "}
                <Typography
                  className="smallsemiboldfont"
                  style={{ cursor: "pointer", width: 70, marginLeft: 3 }}
                  onClick={() => setLoginModal(true)}
                >
                  <u> Log In</u>
                </Typography>
              </Typography>
            ) : null}
          </Grid>
        </Grid>
        <MUserDetail
          visible={openModal}
          handleModal={() => {
            setOpenModal(false);
            setModalSaveBtnLoader(false);
            setIsSocialSignUp(false);
          }}
          socialUserInfo={socialUserInfo}
          modalSaveBtnLoader={modalSaveBtnLoader}
          onSignUpAction={(v) => {
            camdidateSignupProcess(v);
            setModalSaveBtnLoader(true);
          }}
        />
        {loginModal && (
          <MLogin
            visible={loginModal}
            handleModal={() => {
              setLoginModal(false);
            }}
            forgotClick={() => {
              setLoginModal(false);
              setForgotModal(true);
            }}
            loginClick={() => {
              setLoginModal(false);
            }}
            isEmployerHome={isEmployerHome}
          />
        )}
        {forgotModal && (
          <MForgot
            visible={forgotModal}
            handleModal={() => {
              setForgotModal(false);
            }}
          />
        )}
        <ThankYou
          visible={modal}
          handleModal={() => {
            setModal(false);
            dispatch(setBackAction(0));
            if (isSocialSignUp) {
              navigate("/candidate/profile");
            } else {
              navigate(-1);
            }
          }}
        />
      </Box>
    );
  }

  return <div>{renderMain()}</div>;
}
