import React, { useEffect, useState } from "react";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Chip,
  FormControl,
  Modal,
  Button,
  TextField,
  Autocomplete,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { isArray, isEmpty, isObject, toLower } from "lodash";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getAPIProgressData } from "../../../../Utils/APIHelper";
import { Setting } from "../../../../Utils/Setting";
import Images from "../../../../Config/Images";
import useStyles from "../../styles";

function MSkills(props) {
  const {
    visible = false,
    handleModal = () => null,
    list = [],
    data = [],
    employer = false,
    onSave = () => null,
  } = props;
  const { token } = useSelector((state) => state.auth);
  const classes = useStyles();
  const theme = useTheme();
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [userSkills, setUserSkills] = useState([]);
  const [btnLoad, setBtnLoad] = useState(false);
  const isListFill = !isEmpty(list);
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 290 : 330,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
    maxHeight: 550,
  };

  useEffect(() => {
    if (!isListFill && visible) {
      toast.error("Please fill job details first");
    }
  }, [visible]);

  useEffect(() => {
    const arr = [];
    if ((visible && isArray(data) && data.length > 0) || employer) {
      data.map((item) => {
        if (isArray(list) && list.length > 0) {
          list.map((key) => {
            if (Number(employer ? item.value : item) === Number(key.value)) {
              arr.push(key);
            }
          });
        }
      });
    }
    setSelectedSkills(arr);
  }, [visible]);

  useEffect(() => {
    const dummyArr = [...userSkills];

    selectedSkills.map((ii) => {
      const dtd = dummyArr.find(
        (ii2) => toLower(ii2.label) === toLower(ii.label)
      );
      if (isEmpty(dtd)) {
        if (!ii?.value) {
          dummyArr.push(ii);
        }
        setUserSkills(dummyArr);
      }
    });
  }, [selectedSkills]);

  function checkValidation() {
    let valid = true;

    if (isEmpty(selectedSkills)) {
      valid = false;
      toast.error("Please select skills");
    }

    if (valid) {
      onSave(selectedSkills, userSkills);
    }
  }

  // this function for remove skill from selected skill
  function removeSkill(index) {
    const arr = [...selectedSkills];
    arr.splice(index, 1);
    setSelectedSkills(arr);
  }

  // this function for reset form data
  function resetFormData(type = "", data = []) {
    setSelectedSkills([]);
    setUserSkills([]);
    if (type === "close" && handleModal) {
      handleModal(data, "close");
    } else if (type === "update" && handleModal) {
      handleModal(data, "update");
    }
  }

  // this function for submit data to server
  async function submitData() {
    if (isArray(selectedSkills) && selectedSkills.length > 0) {
      setBtnLoad(true);
      const skillLenght = selectedSkills.length;
      const userSkillLenght = userSkills.length;
      const obj = {};
      selectedSkills.map((item, index) => {
        if (item?.value) {
          obj[`SkillForm[skills][${skillLenght + index + 1}]`] = item.value;
        }
      });
      userSkills.map((item, index) => {
        obj[`SkillForm[name][${userSkillLenght + index + 1}]`] = item.label;
      });

      try {
        const response = await getAPIProgressData(
          Setting.endpoints.employeeAddSkills,
          "POST",
          obj,
          { Authorization: `Bearer ${token}` }
        );

        if (response.status) {
          resetFormData("update", response?.data?.skills);
        } else {
          toast.error(response.message);
        }
        setBtnLoad(false);
      } catch (error) {
        console.log("error ===>>", error);
        setBtnLoad(false);
      }
    } else {
      toast.error("Please select skills");
    }
  }

  function SkillArr(reason, details) {
    const dummyArr = [...selectedSkills];

    if (reason === "selectOption") {
      dummyArr.push(details?.option);
    } else if (reason === "createOption") {
      const data = selectedSkills.find(
        (ii) => toLower(ii?.label) === toLower(details?.option)
      );

      if (isObject(data) && !isEmpty(data)) {
        return null;
      } else {
        const dummyObj = {
          label: details?.option,
        };
        dummyArr.push(dummyObj);
      }
    }

    setSelectedSkills(dummyArr);
  }

  return (
    <Modal
      open={visible}
      onClose={() => resetFormData("close")}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <div className={classes.splitViewStyle}>
            <Typography variant="subtitle1" component="h2">
              Add Skills
            </Typography>
            <img
              src={Images.close}
              className={classes.closeBtnStyle}
              onClick={() => resetFormData("close")}
            />
          </div>
          <div className={classes.formDivStyle}>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.inputFieldStyle}
            >
              <Autocomplete
                fullWidth
                multiple
                disableListWrap={true}
                // freeSolo
                options={list}
                filterSelectedOptions
                value={selectedSkills}
                onChange={(v1, v, reason, details) => {
                  SkillArr(reason, details);
                }}
                disableClearable
                // limitTags={1}
                renderTags={(tagValue, getTagProps) => {
                  // const renderTagsValue = tagValue
                  //   .map(function (elem) {
                  //     return elem.label;
                  //   })
                  //   .join(", ");
                  // return (
                  //   <Typography
                  //     style={{ maxWidth: 360 }}
                  //     noWrap={true}
                  //     color="textPrimary"
                  //   >
                  //     {renderTagsValue}
                  //   </Typography>
                  // );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className={classes.inputDivStyle}
                    placeholder="Search"
                  />
                )}
              />
            </FormControl>
          </div>
          <div
            className={`${classes.skillDivStyle} sliderscroll`}
            style={{ maxHeight: 250, paddingTop: 10 }}
          >
            {isArray(selectedSkills) &&
              selectedSkills.length > 0 &&
              selectedSkills.map((item, index) => {
                if (item.value === "") {
                  return null;
                }
                return (
                  <div
                    key={`select_skill_${item.value}`}
                    className={classes.tagViewStyle}
                  >
                    <Typography variant="body2" style={{ color: "#5A5A5A" }}>
                      {item.label}
                    </Typography>
                    <div
                      className={classes.closeIconDivStyle}
                      onClick={() => removeSkill(index)}
                    >
                      <Close className={classes.closeIconStyle} />
                    </div>
                  </div>
                );
              })}
          </div>
          <div
            className={classes.splitViewStyle}
            style={{ paddingTop: 10, marginBottom: 0 }}
          >
            <Button
              variant="outlined"
              className={classes.modalBtnStyle}
              onClick={resetFormData}
              disabled={btnLoad}
            >
              Reset
            </Button>
            <Button
              variant={btnLoad ? "outlined" : "contained"}
              className={classes.modalBtnStyle}
              onClick={() => {
                employer ? checkValidation() : submitData();
              }}
              disabled={btnLoad}
            >
              {btnLoad ? <CircularProgress size={28} /> : "Save"}
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
export default MSkills;
