import React, { useEffect, useState } from "react";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Select,
  MenuItem,
  FormControl,
  Modal,
  Button,
  TextField,
  InputAdornment,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import Images from "../../../../Config/Images";
import useStyles from "../../styles";

function MAvailability(props) {
  const {
    visible = false,
    handleModal = () => null,
    loader = false,
    onSave = () => null,
    data = {},
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [availability, setAvailability] = useState("now");
  const [date, setDate] = useState(new Date());
  const [picker, setPicker] = useState(false);
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 290 : 330,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    if (visible) {
      setAvailability(data?.availablity_type || "now");
      setDate(new Date(data?.availablity_date) || new Date());
    }
    if (!visible) {
      resetFormData();
    }
  }, [visible]);

  // this function for reset form data
  function resetFormData(type = "") {
    setAvailability("now");
    setDate(new Date());
    if (type === "close" && handleModal) {
      handleModal();
    }
  }

  function checkValidation() {
    const availData = {
      availability: availability,
      date: date,
    };

    onSave(availData);
  }

  return (
    <Modal
      open={visible}
      onClose={() => resetFormData("close")}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <div className={classes.splitViewStyle}>
            <Typography variant="subtitle1" component="h2">
              Add Availability
            </Typography>
            <img
              src={Images.close}
              className={classes.closeBtnStyle}
              onClick={() => resetFormData("close")}
            />
          </div>
          <div className={classes.formDivStyle}>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.inputFieldStyle}
            >
              <Select
                IconComponent={() => (
                  <img src={Images.angle} className={classes.angleBtnStyle} />
                )}
                onChange={(e) => setAvailability(e.target.value)}
                value={availability}
              >
                <MenuItem value="now">I'm available now</MenuItem>
                <MenuItem value="from">I'm available from</MenuItem>
                <MenuItem value="not-available">I'm not available</MenuItem>
              </Select>
            </FormControl>
            {availability === "from" && (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={date}
                  showToolbar={false}
                  minDate={new Date()}
                  inputFormat={"dd/MM/yyyy"}
                  onChange={(newValue) => {
                    setDate(newValue);
                    setPicker(!picker);
                  }}
                  open={picker}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      className={classes.inputFieldStyle}
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "Select Date",
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <div
                              className={`${classes.cViewStyle} ${classes.pickerStyle}`}
                              onClick={() => setPicker(!picker)}
                            >
                              <img src={Images.picker} style={{ width: 24 }} />
                            </div>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            )}
          </div>
          <div className={classes.splitViewStyle}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.modalBtnStyle}
              onClick={resetFormData}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.modalBtnStyle}
              onClick={() => {
                checkValidation();
              }}
              disabled={loader}
            >
              {loader ? (
                <CircularProgress style={{ color: "#fff" }} size={26} />
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
export default MAvailability;
