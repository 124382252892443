import React from "react";
import "./styles.css";
import PropTypes from "prop-types";
import _ from "lodash";
import { Select, MenuItem, Typography, FormHelperText } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { KeyboardArrowDown } from "@mui/icons-material";

function CDropDown(props) {
  const {
    label,
    data,
    placeholder,
    value,
    onChange,
    multiple,
    style,
    disable,
    error,
    helperText,
    id,
  } = props;

  return (
    <FormControl sx={[{ m: 1, marginRight: 0, marginLeft: 0 }, style]}>
      {_.isEmpty(label) ? null : (
        <Typography
          className="primarycolor mediumfont"
          style={{ marginBottom: 8 }}
        >
          {label}
        </Typography>
      )}
      <Select
        disabled={disable}
        className="icondd"
        id={id}
        IconComponent={(props) => (
          <KeyboardArrowDown
            {...props}
            style={{ fontSize: 40, cursor: "pointer", color: "#34567b" }}
          />
        )}
        error={error}
        variant="outlined"
        value={value}
        onChange={(v) => {
          onChange(v);
        }}
        // style={{ minHeight: "1.3375em" }}
        style={value === "" ? { color: "#A2A2A2" } : {}}
        multiple={multiple}
        displayEmpty
      >
        <MenuItem value="" disabled>
          {placeholder}
        </MenuItem>
        {data.map((item, index) => {
          return (
            <MenuItem key={index} value={multiple ? item : item?.value}>
              {item?.label}
            </MenuItem>
          );
        })}
      </Select>
      {helperText ? (
        <FormHelperText style={{ color: "red" }}>{helperText}</FormHelperText>
      ) : null}
    </FormControl>
  );
}

CDropDown.propTypes = {
  label: PropTypes.string,
  data: PropTypes.array,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  multiple: PropTypes.bool,
  style: PropTypes.object,
  disable: PropTypes.bool,
  id: PropTypes.string,
};

CDropDown.defaultProps = {
  label: "",
  data: [],
  placeholder: "placeholder",
  onChange: () => {},
  multiple: false,
  disable: false,
  id: "",
};

export default CDropDown;
