import { makeStyles } from "@mui/styles";
import { color } from "../../Config/theme";

const useStyles = makeStyles((theme) => ({
  splitViewStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  modalBtnStyle: { minWidth: "140px !important" },
  modalTitle: {
    marginBottom: "20px !important",
    lineHeight: "36px !important",
    "@media (max-width:1024px)": {
      fontSize: "24px !important",
    },
    "@media (max-width:768px)": {
      lineHeight: "30px !important",
    },
  },
  sectionTitle: {
    color: color.primary,
    fontFamily: "PoppinsSemiBold !important",
    fontSize: "20px !important",
  },
  sectionDesc: {
    color: color.black,
    fontFamily: "PoppinsRegular !important",
    fontSize: "14px !important",
    margin: "5px 0px !important",
  },
  skillDivStyle: { display: "flex", flexWrap: "wrap", marginTop: 15 },
  tagViewStyle: {
    position: "relative",
    border: "0.5px solid #1F4771",
    background: "#F5F9FF 0% 0% no-repeat padding-box",
    borderRadius: 4,
    padding: "10px 15px",
    marginRight: 18,
    marginBottom: 18,
  },
  noteTextStyle: {
    color: color.primary,
    fontFamily: "PoppinsRegular !important",
    fontSize: "14px !important",
    marginBottom: "15px !important",
  },
}));

export default useStyles;
