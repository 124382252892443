import React from "react";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Modal,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import useStyles from "./styles";

function ViewOfferModal(props) {
  const { visible = false, handleModal = () => null, data = {} } = props;
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: md ? 300 : 600,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
    maxHeight: 550,
  };

  return (
    <Modal
      open={visible}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <Typography
            variant="subtitle1"
            component="h2"
            className={classes.modalTitle}
          >
            Offer Detail
          </Typography>
          <div className="sliderscroll" style={{ height: 350 }}>
            {data?.job_role && (
              <div style={{ marginTop: 10 }}>
                <Typography className={classes.sectionTitle}>
                  Job Type
                </Typography>
                <Typography className={classes.sectionDesc}>
                  {data?.job_role || "-"}
                </Typography>
              </div>
            )}
            {data?.job_role_type && (
              <div style={{ marginTop: 10 }}>
                <Typography className={classes.sectionTitle}>Basis</Typography>
                <Typography className={classes.sectionDesc}>
                  {data?.job_role_type === "p_full_time"
                    ? "Permanent - Full Time"
                    : data?.job_role_type === "p_part_time"
                    ? "Permanent - Part Time"
                    : data?.job_role_type === "t_full_time"
                    ? "Temporary - Full Time"
                    : data?.job_role_type === "t_part_time"
                    ? "Temporary - Part Time"
                    : data?.job_role_type === "any"
                    ? "Flexible"
                    : "Contract" || "-"}
                </Typography>
              </div>
            )}
            {data?.description && (
              <div style={{ marginTop: 10 }}>
                <Typography className={classes.sectionTitle}>
                  Description
                </Typography>
                <Typography className={classes.sectionDesc}>
                  {data?.description || "-"}
                </Typography>
              </div>
            )}
            {data?.start_date && (
              <div style={{ marginTop: 10 }}>
                <Typography className={classes.sectionTitle}>
                  Start Date
                </Typography>
                <Typography className={classes.sectionDesc}>
                  {moment(data?.start_date).format("DD MMM YYYY") || "-"}
                </Typography>
              </div>
            )}
            {data?.end_date && (
              <div style={{ marginTop: 10 }}>
                <Typography className={classes.sectionTitle}>
                  End Date
                </Typography>
                <Typography className={classes.sectionDesc}>
                  {moment(data?.end_date).format("DD MMM YYYY") || "-"}
                </Typography>
              </div>
            )}
            {data?.start_time && (
              <div style={{ marginTop: 10 }}>
                <Typography className={classes.sectionTitle}>
                  Start Time
                </Typography>
                <Typography className={classes.sectionDesc}>
                  {data?.start_time || "-"}
                </Typography>
              </div>
            )}
            {data?.end_time && (
              <div style={{ marginTop: 10 }}>
                <Typography className={classes.sectionTitle}>
                  End Time
                </Typography>
                <Typography className={classes.sectionDesc}>
                  {data?.end_time || "-"}
                </Typography>
              </div>
            )}
            {data?.location && (
              <div style={{ marginTop: 10 }}>
                <Typography className={classes.sectionTitle}>
                  Postcode
                </Typography>
                <Typography className={classes.sectionDesc}>
                  {data?.location || "-"}
                </Typography>
              </div>
            )}
            {data?.note && (
              <div style={{ marginTop: 10 }}>
                <Typography className={classes.sectionTitle}>
                  Other Notes
                </Typography>
                <Typography className={classes.sectionDesc}>
                  {data?.note || "-"}
                </Typography>
              </div>
            )}
          </div>
          <div
            className={classes.splitViewStyle}
            style={{ justifyContent: "unset", marginTop: 10 }}
          >
            <Button
              variant="contained"
              color="primary"
              className={classes.modalBtnStyle}
              fullWidth
              onClick={handleModal}
            >
              Okay
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export default ViewOfferModal;
