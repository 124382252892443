import React, { useState } from "react";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Modal,
  Button,
  useTheme,
  useMediaQuery,
  TextField,
  CircularProgress,
} from "@mui/material";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import Images from "../../Config/Images";
import { getAPIProgressData } from "../../Utils/APIHelper";
import { Setting } from "../../Utils/Setting";
import useStyles from "./styles";

const errorObj = {
  messageErr: false,
  message: "",
};

function SendMessageModal(props) {
  const {
    visible = false,
    handleModal = () => null,
    receiverId = null,
  } = props;
  const { token } = useSelector((state) => state.auth);
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const [message, setMessage] = useState("");
  const [errObj, setErrObj] = useState(errorObj);
  const [btnLoad, setBtnLoad] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: md ? 300 : 600,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
  };

  // this function for reset form data
  function resetFormData(type = "") {
    setMessage("");
    setErrObj(errorObj);
    if (type === "close" && handleModal) {
      handleModal();
    }
  }

  // this function validates field
  function validation() {
    const error = { ...errObj };
    let valid = true;

    if (isEmpty(message.trim())) {
      valid = false;
      error.messageErr = true;
      error.message = "Message can not be blank";
    } else if (message.length > 1000) {
      valid = false;
      error.messageErr = true;
      error.message = "Message maximum length is 1000 character";
    }

    setErrObj(error);

    if (valid) {
      sendMessage();
    }
  }

  // this function for send message
  async function sendMessage() {
    setBtnLoad(true);
    const obj = {
      "Messages[receiver_id]": receiverId,
      "Messages[message]": message,
    };

    try {
      const response = await getAPIProgressData(
        Setting.endpoints.employeeSendMessage,
        "POST",
        obj,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (response.status) {
        toast.success(response.message);
        resetFormData("close");
      } else {
        toast.error(response.message);
      }
      setBtnLoad(false);
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
      setBtnLoad(false);
    }
  }

  return (
    <Modal
      open={visible}
      onClose={() => (btnLoad ? null : resetFormData("close"))}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <div className={classes.splitViewStyle}>
            <Typography
              variant="subtitle1"
              component="h2"
              className={classes.modalTitle}
            >
              Write a message
            </Typography>
            <img
              src={Images.close}
              className={classes.closeBtnStyle}
              onClick={() => (btnLoad ? null : resetFormData("close"))}
            />
          </div>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Message"
            className={classes.inputFieldStyle}
            multiline
            minRows={8}
            maxRows={8}
            style={{ padding: "0px !important" }}
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
              setErrObj({ ...errObj, message: "", messageErr: false });
            }}
            error={errObj.messageErr}
            helperText={errObj.message ? errObj.message : ""}
          />
          <div
            className={classes.splitViewStyle}
            style={{ justifyContent: "unset" }}
          >
            <Button
              variant="outlined"
              color="primary"
              className={classes.modalBtnStyle}
              onClick={resetFormData}
              disabled={btnLoad}
            >
              Reset
            </Button>
            <div style={{ width: 30 }} />
            <Button
              variant={btnLoad ? "outlined" : "contained"}
              color="primary"
              className={classes.modalBtnStyle}
              onClick={validation}
              disabled={btnLoad}
            >
              {btnLoad ? <CircularProgress size={28} /> : "Send"}
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export default SendMessageModal;
