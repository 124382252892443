import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Modal,
  Button,
  useTheme,
  useMediaQuery,
  TextField,
  InputAdornment,
  Grid,
  Autocomplete,
} from "@mui/material";
import useStyles from "../../styles";
import Images from "../../../../Config/Images";
import CDropDown from "../../../CDropDown";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { isDate, isEmpty, isNull } from "lodash";
import { experienceArr } from "../../../../Config/Static_Data";

const errorObj = {
  sectorErr: false,
  jobRoleErr: false,
  vacanciesErr: false,
  jobTitleErr: false,
  jobCloseDateErr: false,
  educationErr: false,
  experienceErr: false,
  sectorMsg: "",
  jobRoleMsg: "",
  vacanciesMsg: "",
  jobTitleMsg: "",
  jobCloseDateMsg: "",
  educationMsg: "",
  experienceMsg: "",
};

function MAboutJob(props) {
  const {
    visible = false,
    handleModal = () => null,
    onSave = () => null,
    editData = {},
  } = props;

  const { dropdowndata } = useSelector((state) => state.auth);
  const [sector, setSector] = useState({});
  const [role, setRole] = useState({});
  const [vacancies, setVacancies] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [closeDate, setCloseDate] = useState(null);
  const [openPicker, setOpenPicker] = useState(false);
  const [errObj, setErrObj] = useState(errorObj);
  const [jobRoleArr, setJobRoleArr] = useState([]);
  const [education, setEducation] = useState("");
  const [experience, setExperience] = useState("");

  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const isSector = isEmpty(sector);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 300 : 360,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
    maxHeight: 530,
  };

  useEffect(() => {
    if (visible) {
      jobRoleData(editData?.sector, editData?.job_role_id);
      setVacancies(editData?.vacancies || "");
      setJobTitle(editData?.jobTitle || "");
      setCloseDate(editData?.closeDate ? new Date(editData?.closeDate) : null);
      setExperience(editData?.experience || "");
      setEducation(editData?.education || "");
    }
    if (!visible) {
      resetFormData();
    }
  }, [visible]);

  function jobRoleData(value, jobId) {
    dropdowndata?.sectors.map((ii) => {
      if (ii?.value === value) {
        setJobRoleArr(ii?.job_roles);
        setSector({ value: ii.value, label: ii.label });
        ii?.job_roles.map((key) => {
          if (key?.value.toString() === jobId.toString()) {
            setRole(key);
          }
        });
      }
    });
  }

  function resetFormData(type = "") {
    setSector({});
    setRole({});
    setVacancies("");
    setJobTitle("");
    setCloseDate(null);
    setOpenPicker(false);
    setErrObj(errorObj);
    setEducation("");
    setExperience("");
    if (type === "close" && handleModal) {
      handleModal();
    }
  }

  function checkValidation() {
    const error = { ...errObj };
    let valid = true;
    let section = null;
    let scroll = false;

    if (isEmpty(sector)) {
      valid = false;
      scroll = true;
      error.sectorErr = true;
      error.sectorMsg = "Please select sector";
      section = document.querySelector("#sector");
    }
    if (isEmpty(role)) {
      valid = false;
      error.jobRoleErr = true;
      error.jobRoleMsg = "Please select job role";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#role1");
      }
    }
    if (isEmpty(experience.toString())) {
      valid = false;
      error.experienceErr = true;
      error.experienceMsg = "Please select experience";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#experience");
      }
    }
    if (isEmpty(education.toString())) {
      valid = false;
      error.educationErr = true;
      error.educationMsg = "Please select education level";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#education");
      }
    }
    if (isEmpty(vacancies.toString())) {
      valid = false;
      error.vacanciesErr = true;
      error.vacanciesMsg = "Please enter vacancies";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#vacancies");
      }
    } else if (Number(vacancies) < 0) {
      valid = false;
      error.vacanciesErr = true;
      error.vacanciesMsg = "vacancies must be positive";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#vacancies");
      }
    }
    if (isEmpty(jobTitle)) {
      valid = false;
      error.jobTitleErr = true;
      error.jobTitleMsg = "Please enter job title";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#jobTitle");
      }
    }
    if (!isDate(closeDate)) {
      valid = false;
      error.jobCloseDateErr = true;
      error.jobCloseDateMsg = "Please select job close date";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#closeDate");
      }
    }

    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "center" });
    }

    setErrObj(error);

    if (valid) {
      const jobData = {
        sector: sector?.value,
        job_role_id: role?.value,
        vacancies: vacancies,
        jobTitle: jobTitle,
        closeDate: closeDate || "",
        education: education,
        experience: experience,
      };
      onSave(jobData);
    }
  }

  return (
    <Modal
      open={visible}
      onClose={() => resetFormData("close")}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <div className={classes.splitViewStyle} style={{ marginBottom: 20 }}>
            <Typography
              variant="subtitle1"
              component="h2"
              className={classes.modalTitle}
            >
              Add information about your job
            </Typography>
            <img
              alt="close"
              src={Images.close}
              className={classes.closeBtnStyle}
              onClick={() => resetFormData("close")}
            />
          </div>
          <div className="sliderscroll" style={{ height: 330 }}>
            <Typography
              className="primarycolor mediumfont"
              style={{ marginBottom: 8 }}
            >
              Select sector
            </Typography>
            <Autocomplete
              fullWidth
              options={dropdowndata?.sectors}
              disableClearable
              id="sector"
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select sector"
                  error={errObj.sectorErr}
                  helperText={errObj.sectorErr ? errObj.sectorMsg : null}
                />
              )}
              onChange={(e, v) => {
                setSector({ value: v.value, label: v.label });
                jobRoleData(v.value, "");
                setRole({});
                setErrObj({ ...errObj, sectorErr: false, sectorMsg: "" });
              }}
              onInputChange={(v) => {
                if (!isNull(v) && v.target.value.length === 0) {
                  setSector({});
                }
              }}
              freeSolo
              size="small"
              value={sector?.label}
            />
            <Typography
              className="primarycolor mediumfont"
              style={{ marginBottom: 8, marginTop: 8 }}
            >
              Select the job role
            </Typography>
            <Autocomplete
              key={role?.label}
              fullWidth
              id="role1"
              options={jobRoleArr}
              disabled={isSector}
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select job"
                  error={errObj.jobRoleErr}
                  helperText={errObj.jobRoleErr ? errObj.jobRoleMsg : null}
                />
              )}
              onChange={(e, v) => {
                setRole({ value: v.value, label: v.label });
                setErrObj({ ...errObj, jobRoleErr: false, jobRoleMsg: "" });
              }}
              onInputChange={(v) => {
                if (!isNull(v) && v.target.value.length === 0) {
                  setRole({});
                }
              }}
              freeSolo
              size="small"
              value={role?.label}
            />
            <CDropDown
              data={experienceArr}
              value={experience}
              id="experience"
              error={errObj.experienceErr}
              helperText={errObj.experienceMsg}
              onChange={(v) => {
                setExperience(v.target.value);
                setErrObj({
                  ...errObj,
                  experienceErr: false,
                  experienceMsg: "",
                });
              }}
              placeholder="Select Experience"
              label="Select Experience"
              style={{ width: "100%" }}
            />
            <CDropDown
              data={dropdowndata?.education_level}
              value={education}
              id="education"
              error={errObj.educationErr}
              helperText={errObj.educationMsg}
              onChange={(v) => {
                setEducation(v.target.value);
                setErrObj({ ...errObj, educationErr: false, educationMsg: "" });
              }}
              placeholder="Select Education"
              label="Level"
              style={{ width: "100%" }}
            />
            <Typography
              className="primarycolor mediumfont"
              style={{ marginBottom: 8, marginTop: 8 }}
            >
              No. of Vacancies
            </Typography>
            <TextField
              variant="outlined"
              placeholder="Vacancies"
              value={vacancies}
              id="vacancies"
              type="number"
              error={errObj.vacanciesErr}
              helperText={errObj.vacanciesMsg}
              onChange={(v) => {
                setVacancies(v.target.value);
                setErrObj({ ...errObj, vacanciesErr: false, vacanciesMsg: "" });
              }}
              style={{ width: "100%", marginBottom: 8 }}
            />
            <Typography
              className="primarycolor mediumfont"
              style={{ marginBottom: 8, marginTop: 8 }}
            >
              Title for job posting
            </Typography>
            <TextField
              variant="outlined"
              placeholder="Job title"
              value={jobTitle}
              id="jobTitle"
              error={errObj.jobTitleErr}
              helperText={errObj.jobTitleMsg}
              onChange={(v) => {
                setJobTitle(v.target.value);
                setErrObj({ ...errObj, jobTitleErr: false, jobTitleMsg: "" });
              }}
              style={{ width: "100%", marginBottom: 8 }}
            />
            <Typography
              className="primarycolor mediumfont"
              style={{ marginBottom: 8, marginTop: 8 }}
            >
              Job close date
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={closeDate}
                inputFormat={"dd/MM/yyyy"}
                showToolbar={false}
                onChange={(newValue) => {
                  setCloseDate(newValue);
                  setOpenPicker(false);
                  setErrObj({
                    ...errObj,
                    jobCloseDateErr: false,
                    jobCloseDateMsg: "",
                  });
                }}
                open={openPicker}
                minDate={new Date()}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    id="closeDate"
                    {...params}
                    error={errObj.jobCloseDateErr}
                    helperText={errObj.jobCloseDateMsg}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "Select Date",
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <div
                            className="datepicker pickerStyle"
                            onClick={() => {
                              setOpenPicker(!openPicker);
                            }}
                          >
                            <img
                              alt="error"
                              src={Images.picker}
                              style={{ width: 24 }}
                            />
                          </div>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
          <div
            style={{
              marginTop: 20,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid xs={5.8} style={{ marginRight: 10 }}>
              <Button
                variant="outlined"
                style={{ width: "100%" }}
                onClick={() => {
                  resetFormData();
                }}
              >
                RESET
              </Button>
            </Grid>
            <Grid xs={5.8}>
              <Button
                variant="contained"
                style={{ width: "100%" }}
                onClick={() => {
                  checkValidation();
                }}
              >
                SAVE
              </Button>
            </Grid>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export default MAboutJob;
