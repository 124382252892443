import React from "react";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { color } from "../../../Config/theme";
import useStyles from "../styles";

function SkillQuiz(props) {
  const { candidateResult = () => null, percent = 0 } = props;
  const classes = useStyles();

  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          variant="determinate"
          disableShrink
          value={100}
          sx={{
            color: color.secondary,
            animationDuration: "550ms",
            position: "absolute",
            left: 0,
          }}
        />
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="caption" component="div" color={color.primary}>
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Grid
      item
      xs={12}
      className={[
        classes.shadowViewStyle,
        classes.paddingStyle,
        classes.marginDiv,
      ]}
    >
      <div id="skillQuiz" className={classes.splitViewStyle}>
        <Typography className={classes.sectionTitle}>
          Check out the candidate's responses
        </Typography>
      </div>
      {/* <Typography className={classes.sectionDesc}>
        To show your profile in employer TOP 10 list you need to complete the
        skills quiz.
      </Typography> */}
      <Grid container style={{ marginTop: 15 }}>
        <Grid xs={12} sm={12} md={5} lg={4}>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={() => {
              candidateResult();
            }}
          >
            Your SJT
          </Button>
        </Grid>
        <Grid xs={12} sm={12} md={7} lg={8} className={classes.skillBtnView}>
          <div style={{ marginRight: 10 }}>
            <CircularProgressWithLabel value={percent || 0} />
          </div>
          <Typography
            className={classes.sectionDesc}
            style={{ margin: "5px 0px 5px 10px !important" }}
          >
            Candidate result
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default SkillQuiz;
