import { makeStyles } from "@mui/styles";
import { color } from "../../../Config/theme";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "3% !important",
    marginBottom: "50px !important",
    "@media (max-width:1024px)": {
      marginTop: "3% !important",
    },
    "@media (max-width:425px)": {
      marginTop: "6% !important",
    },
  },
  tabStyle: {
    "& > div": {
      "@media (max-width:768px)": {
        overflow: "scroll !important",
        "&::-webkit-scrollbar": {
          display: "none !important",
        },
      },
    },
  },
  listContainerStyle: {
    marginTop: 25,
    width: "100%",
    "@media (max-width:768px)": {
      marginTop: 40,
    },
  },
  noDataView: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 400,
  },
  sectionTitle: {
    color: color.primary,
    fontFamily: "PoppinsSemiBold !important",
    fontSize: "20px !important",
  },
  loaderView: {
    width: "100%",
    minHeight: 400,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
