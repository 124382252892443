import { makeStyles } from "@mui/styles";
import { color } from "../../Config/theme";

const useStyles = makeStyles((theme) => ({
  header_main_con: {
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10,
    display: "flex",
    position: "fixed",
    height: "60px",
    alignItems: "center",
    backgroundColor: color.white,
    boxShadow: "0px 2px 5px #888",
  },
  menu_title_style: {
    color: "#3A5B7E",
    fontSize: "15px !important",
    fontFamily: "PoppinsMedium !important",
  },
  link_style: {
    textDecoration: "none",
  },
  verticle_line: {
    borderLeft: "2px solid #ffa500",
    height: "20px",
    margin: "0px 13px",
  },
  commonFlex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  list_menu_con: {
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "flex-end",
  },
  PR25: {
    paddingRight: "25px",
  },
  poniter_text: {
    cursor: "pointer",
  },
  linkContainer: { position: "relative" },
  activeLinkStyle: {
    height: 2,
    backgroundColor: color.secondary,
    position: "absolute",
    left: 0,
    right: 0,
  },
}));

export default useStyles;
