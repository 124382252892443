import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "2% !important",
    marginBottom: "50px !important",
  },
  buttonStyle: {
    width: "300px",
    "@media (max-width:640px)": {
      width: "280px",
    },
    "@media (max-width:600px)": {
      width: "240px",
    },
    "@media (max-width:525px)": {
      width: "180px",
    },
    "@media (max-width:425px)": {
      width: "160px",
    },
    "@media (max-width:375px)": {
      width: "130px",
    },
  },
  fullWidth: {
    marginBottom: 40,
    "@media (max-width:425px)": {
      width: "100%",
    },
  },
  bottomView: {
    marginTop: "30px",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#fff",
    padding: "30px 110px",
    "@media (max-width:768px)": {
      padding: "25px 40px",
    },
    "@media (max-width:425px)": {
      padding: "25px 15px",
    },
  },
  loaderView: {
    width: "100%",
    minHeight: 400,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
