import React, { useState } from "react";
import { Typography, Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SendMessageModal from "../../SendMessageModal";
import useStyles from "../styles";

function Candidates(props) {
  const { data = {} } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);

  // this function for redirect to profile page
  function viewProfile() {
    navigate("/candidate/profile", {
      state: { from: "employee", jobId: data?.post_id, userId: data?.user_id },
    });
    window.scrollTo(0, 0);
  }

  return (
    <Grid container className={classes.container}>
      <Grid xs={12} sm={3} md={2}>
        <img src={data.user_image} className={classes.cJobImgStyle} />
      </Grid>
      <Grid xs={12} sm={9} md={10} className={classes.mJobContainerStyle}>
        <Grid container className={classes.detailDivStyle}>
          <Grid xs={12}>
            <Typography className={classes.sectionTitle}>
              {data?.firstname || ""} {data?.lastname || ""}
            </Typography>
          </Grid>
          <Grid xs={12} className={classes.matchSingleJobDetail}>
            <Typography className={classes.sectionDesc}>
              Availability
            </Typography>
            <Typography className={classes.sectionSubDetail}>
              {data?.availablity || "-"}
            </Typography>
          </Grid>
          <Grid container>
            <Grid xs={12} sm={4} md={3}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={viewProfile}
                className={classes.jobBtnStyle}
              >
                View Profile
              </Button>
            </Grid>
            <div style={{ width: 30 }} />
            <Grid xs={12} sm={4} md={3}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => setOpenModal(true)}
              >
                Message
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SendMessageModal
        visible={openModal}
        handleModal={() => setOpenModal(false)}
        receiverId={data?.user_id || null}
      />
    </Grid>
  );
}

export default Candidates;
