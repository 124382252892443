import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Typography, Grid } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import { isArray, isEmpty } from "lodash";
import useStyles from "../styles";
import Images from "../../../Config/Images";
import moment from "moment";
import MAboutJob from "../../Profile/Modal/MAboutJob";

function AboutJob(props) {
  const { setData = () => null, data = {} } = props;
  const { dropdowndata } = useSelector((state) => state.auth);
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState({});

  const [sectorName, setSectorName] = useState("");
  const [JobRoleName, setJobroleName] = useState("");

  const isComplete = !isEmpty(editData);
  const isDataEmpty = !isEmpty(data);

  useEffect(() => {
    if (isDataEmpty) {
      setEditData(
        {
          sector: data?.sector_id,
          job_role_id: data?.job_id,
          vacancies: data?.vacancies,
          jobTitle: data?.title,
          closeDate: data?.expired_date,
          education: data?.education_level,
          experience: data?.required_experience,
        } || {}
      );
    }
  }, [data]);

  useEffect(() => {
    setData(editData);
    setDropDownData();
  }, [editData]);

  function setDropDownData() {
    dropdowndata?.sectors.map((ii) => {
      if (Number(ii?.value) === Number(editData?.sector)) {
        setSectorName(ii?.label);

        ii?.job_roles.map((ii2) => {
          if (Number(ii2?.value) === Number(editData?.job_role_id)) {
            setJobroleName(ii2?.label);
          }
        });
      }
    });
  }

  const educationLvl =
    isArray(dropdowndata?.education_level) &&
    dropdowndata?.education_level.find(
      (item) => Number(item.value) === Number(editData?.education)
    );

  return (
    <Grid
      item
      xs={12}
      className={[
        classes.shadowViewStyle,
        classes.paddingStyle,
        classes.marginDiv,
      ]}
    >
      <div id="job" className={classes.splitViewStyle}>
        <Typography className={classes.sectionTitle}>About your job</Typography>
        {isComplete ? (
          <div
            className={classes.editBtnStyle}
            onClick={() => setOpenModal(true)}
          >
            <img src={Images.edit} style={{ width: 14 }} />
          </div>
        ) : (
          <AddCircle
            className={classes.addIcon}
            onClick={() => {
              setOpenModal(true);
            }}
          />
        )}
      </div>
      {isComplete ? (
        <div style={{ marginTop: 10 }}>
          <Typography className="regulerfont primarycolor">
            Sector: {sectorName}
          </Typography>
          <Typography className="regulerfont primarycolor">
            Job Role: {JobRoleName}
          </Typography>
          <Typography className="regulerfont primarycolor">
            Job Experience: {editData?.experience}
          </Typography>
          <Typography className="regulerfont primarycolor">
            Job Education Level: {!isEmpty(educationLvl) && educationLvl?.label}
          </Typography>
          <Typography className="regulerfont primarycolor">
            Vacancies: {editData?.vacancies}
          </Typography>
          <Typography className="regulerfont primarycolor">
            Job Title: {editData?.jobTitle}
          </Typography>
          {editData?.closeDate && (
            <Typography className="regulerfont primarycolor">
              Job close date:{" "}
              {moment(editData?.closeDate).format("DD MMMM YYYY")}
            </Typography>
          )}
        </div>
      ) : (
        <Typography className={classes.sectionDesc}>
          Add job information
        </Typography>
      )}
      <MAboutJob
        visible={openModal}
        handleModal={() => {
          setOpenModal(false);
        }}
        onSave={(data) => {
          setEditData(data);
          setOpenModal(false);
        }}
        editData={editData}
      />
    </Grid>
  );
}

export default AboutJob;
