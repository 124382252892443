import React, { useState } from "react";
import {
  Typography,
  Button,
  Grid,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import SendMessageModal from "../../SendMessageModal";
import Images from "../../../Config/Images";
import ViewOfferModal from "../../ViewOfferModal";
import useStyles from "../styles";
import MRate from "../../Modal/RateModal";

function OfferDetail(props) {
  const { data = {}, type = "" } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [viewOfferModal, setViewOfferModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  // this function for redirect to profile page
  function viewProfile(bool = false) {
    navigate("/company/profile", {
      state: { jobId: data?.job_post_id, shortlist: bool, shortListData: data },
    });
    window.scrollTo(0, 0);
  }

  return (
    <>
      <Grid container className={classes.container}>
        <Grid xs={12} sm={3} md={2} className={classes.offerImgResp}>
          <img
            src={data?.company_logo}
            className={classes.offerImgStyle}
            onError={(e) => {
              e.target.src = Images.heroimg;
            }}
            alt="company logo"
          />
        </Grid>
        <Grid xs={12} sm={9} md={10}>
          <Grid container>
            <Grid xs={12} lg={10} style={{ marginBottom: 15 }}>
              {type === "shortlist" && data?.is_payment ? (
                <Typography className={classes.sectionTitle}>
                  {data?.job_title || ""}
                </Typography>
              ) : (
                <Typography className={classes.sectionTitle}>
                  {data?.title || ""}
                </Typography>
              )}
              <Typography style={{ fontFamily: "PoppinsMedium" }}>
                {data?.sector || ""}
              </Typography>
            </Grid>
            {!sm && (
              <Grid item xs={12} lg={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setReportModal(true)}
                  style={{ minWidth: 150 }}
                >
                  Report
                </Button>
              </Grid>
            )}
            <Grid
              xs={12}
              sm={4}
              md={type === "shortlist" ? 4 : 3}
              className={classes.singleDetail}
            >
              <Typography className={classes.sectionDesc}>
                {data?.rate ? "Rate" : "Salary"}
              </Typography>
              {data?.job_role_type === "contract" ||
              data?.job_role_type === "any" ? (
                <>
                  {data?.charge_per_year && (
                    <Typography className={classes.sectionSubDetail}>
                      {`£${data?.charge_per_year} per year`}
                    </Typography>
                  )}
                  {data?.charge_per_hour && (
                    <Typography className={classes.sectionSubDetail}>
                      {`£${data?.charge_per_hour} per hour`}
                    </Typography>
                  )}
                </>
              ) : (
                <Typography className={classes.sectionSubDetail}>
                  {data?.job_role_type === "p_part_time" ||
                  data?.job_role_type === "t_part_time"
                    ? `£${data?.charge_per_hour || 0}`
                    : data?.job_role_type === "p_full_time" ||
                      data?.job_role_type === "t_full_time"
                    ? `£${data?.charge_per_year || 0}`
                    : "0"}
                </Typography>
              )}
            </Grid>
            <Grid
              xs={12}
              sm={type === "shortlist" ? 4 : 3}
              className={classes.singleDetail}
            >
              <Typography className={classes.sectionDesc}>Postcode</Typography>
              <Typography className={classes.sectionSubDetail}>
                {data?.location || ""}
              </Typography>
            </Grid>
            {type === "shortlist" ? null : (
              <Grid xs={12} sm={2} md={3} className={classes.singleDetail}>
                <Typography className={classes.sectionDesc}>Start</Typography>
                <Typography className={classes.sectionSubDetail}>
                  {(data?.start_date &&
                    moment(data?.start_date, "YYYY-MM-DD").format(
                      "DD MMM YYYY"
                    )) ||
                    "-"}
                </Typography>
              </Grid>
            )}
            <Grid xs={12} sm={3} className={classes.singleDetail}>
              <Typography className={classes.sectionDesc}>Duration</Typography>
              <Typography className={classes.sectionSubDetail}>
                {data?.job_role_type === "p_part_time"
                  ? "Permanent Part Time"
                  : data?.job_role_type === "t_part_time"
                  ? "Temporary Part Time"
                  : data?.job_role_type === "p_full_time"
                  ? "Permanent Full Time"
                  : data?.job_role_type === "t_full_time"
                  ? "Temporary Full Time"
                  : data?.job_role_type === "contract"
                  ? "Contract"
                  : data?.job_role_type === "any"
                  ? "Flexible"
                  : "-"}
              </Typography>
            </Grid>
            <Grid
              container
              className={classes.profileBtnDivStyle}
              alignItems="flex-end"
            >
              {type === "shortlist" ? (
                <Grid xs={12} sm={5} md={4}>
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={() => viewProfile(true)}
                  >
                    View Profile
                  </Button>
                </Grid>
              ) : (
                <>
                  <Grid xs={12} sm={5} md={3.5}>
                    <Button
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onClick={() => viewProfile(false)}
                    >
                      View Profile
                    </Button>
                  </Grid>
                  <div style={{ width: 30 }} className={classes.hideDateView} />
                  <Grid xs={12} sm={5} md={3.5}>
                    <Button
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onClick={() => setViewOfferModal(true)}
                    >
                      View Offer
                    </Button>
                  </Grid>
                  <div style={{ width: 30 }} className={classes.hideDateView} />
                  {sm && (
                    <Grid xs={12} sm={5} md={3.5} style={{ marginBottom: 15 }}>
                      <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        onClick={() => setReportModal(true)}
                      >
                        Report
                      </Button>
                    </Grid>
                  )}
                  <Grid xs={12} sm={5} md={3.5}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => setVisible(true)}
                    >
                      Message
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SendMessageModal
        visible={visible}
        handleModal={() => setVisible(false)}
        receiverId={data?.employer_id || null}
      />
      <MRate
        visible={reportModal}
        postId={data?.job_post_id}
        type="report"
        handleModal={() => {
          setReportModal(false);
        }}
      />
      {viewOfferModal && (
        <ViewOfferModal
          visible={viewOfferModal}
          data={data}
          handleModal={() => setViewOfferModal(false)}
        />
      )}
    </>
  );
}

export default OfferDetail;
