import React, { useEffect, useState } from "react";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Modal,
  Button,
  useTheme,
  useMediaQuery,
  InputAdornment,
  Grid,
  TextField,
  CircularProgress,
} from "@mui/material";
import { toast } from "react-toastify";
import _, { isArray } from "lodash";
import { useSelector } from "react-redux";
import { Close } from "@mui/icons-material";
import Images from "../../../../Config/Images";
import { Setting } from "../../../../Utils/Setting";
import { getAPIProgressData } from "../../../../Utils/APIHelper";
import useStyles from "../../styles";

function MBenefit(props) {
  const { visible = false, handleModal = () => null, benefits = [] } = props;
  const { token } = useSelector((state) => state.auth);
  const [benefit, setBenefit] = useState("");
  const [benefitArr, setBenefitArr] = useState([]);
  const [btnLoad, setBtnLoad] = useState(false);

  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 300 : 330,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
    maxHeight: 550,
  };

  useEffect(() => {
    if (visible) {
      setBenefitArr(benefits);
    }
  }, [visible]);

  function resetFormData(type = "", data = []) {
    setBenefit("");
    setBenefitArr([]);
    if (type === "close" && handleModal) {
      handleModal();
    } else if (type === "update") {
      handleModal(data, type);
    }
  }

  function addBenefit() {
    const dummy_arr = [...benefitArr];

    if (_.isEmpty(benefit)) {
      return toast.error("Please Enter Benefit");
    }

    dummy_arr.push(benefit);
    setBenefitArr(dummy_arr);
    setBenefit("");
  }

  function removeBenefit(index) {
    const arr = [...benefitArr];
    arr.map((i, ii) => {
      if (ii === index) {
        arr.splice(ii, 1);
      }
      setBenefitArr(arr);
    });
  }

  // this function for submit data to server
  async function submitData() {
    if (isArray(benefitArr) && benefitArr.length > 0) {
      setBtnLoad(true);

      const obj = {};
      benefitArr.map((item, index) => {
        obj[`EmployerCompany[benefits][${index}]`] = item;
      });

      try {
        const response = await getAPIProgressData(
          Setting.endpoints.employerAddBenefits,
          "POST",
          obj,
          {
            Authorization: `Bearer ${token}`,
          }
        );

        if (response.status) {
          resetFormData("update", response?.data?.benefits);
        } else {
          toast.error(response.message);
        }
        setBtnLoad(false);
      } catch (error) {
        console.log("error ===>>>", error);
        setBtnLoad(false);
      }
    } else {
      toast.error("Please add atleast one benefit");
    }
  }

  return (
    <Modal
      open={visible}
      onClose={() => resetFormData("close")}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <div className={classes.splitViewStyle} style={{ marginBottom: 20 }}>
            <Typography variant="subtitle1" component="h2">
              Add Benefits
            </Typography>
            <img
              alt="close"
              src={Images.close}
              className={classes.closeBtnStyle}
              onClick={() => resetFormData("close")}
            />
          </div>
          <TextField
            fullWidth
            style={{ marginBottom: 20 }}
            variant="outlined"
            placeholder="Benefits"
            value={benefit}
            onChange={(e) => setBenefit(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <div
                    className="inputside"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      addBenefit();
                    }}
                  >
                    Add
                  </div>
                </InputAdornment>
              ),
            }}
          />

          <div className="sliderscroll" style={{ maxHeight: 350 }}>
            {_.isEmpty(benefitArr) ? (
              <Typography
                className="primarycolor mediumsemiboldfont"
                style={{ marginBottom: 20 }}
              >
                Add benefits of your company
              </Typography>
            ) : (
              <div
                style={{ display: "flex", flexWrap: "wrap", marginBottom: 20 }}
              >
                {benefitArr.map((ii, index) => {
                  return (
                    <div key={index} className="selextedlocationmain">
                      <Typography
                        className="regulerlargefont lightsilvercolor"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          maxWidth: 300,
                        }}
                      >
                        {ii}
                      </Typography>
                      <Button
                        variant="contained"
                        className="closebtn"
                        onClick={() => {
                          removeBenefit(index);
                        }}
                      >
                        <Close style={{ fontSize: 16 }} />
                      </Button>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div
            style={{
              marginTop: 10,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid xs={5.8} style={{ marginRight: 10 }}>
              <Button
                variant="outlined"
                style={{ width: "100%" }}
                onClick={() => {
                  resetFormData();
                }}
                disabled={btnLoad}
              >
                RESET
              </Button>
            </Grid>
            <Grid xs={5.8}>
              <Button
                variant={btnLoad ? "outlined" : "contained"}
                style={{ width: "100%" }}
                disabled={btnLoad}
                onClick={submitData}
              >
                {btnLoad ? <CircularProgress size={28} /> : "SAVE"}
              </Button>
            </Grid>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export default MBenefit;
